// firebase
import { firestore } from '../../firebase';
import { collection, onSnapshot, getDocs } from 'firebase/firestore';
// firebase doc classes
import Company from '../../documents/Company/Company';

/**
 * Companies
 */
export default class Companies {

    static buildListener (callback, options) {
        options = {
            populateOwner: (options?.populateOwner === true)
        };

        // noinspection JSCheckFunctionSignatures
        let companiesQuery = collection(firestore, 'companies').withConverter(Company.firestoreConverter);

        return onSnapshot(companiesQuery, async (snapshot) => {
            let companies = snapshot.docs.map(doc => doc.data());

            // // init each company with options
            // await Promise.all(companies.map(company => company.init(options)));

            callback(new Companies(companies));
        });
    }


    static async build () {

        // get company docs from firestore
        const snapshot =
            await getDocs(collection(firestore, 'companies').withConverter(Company.firestoreConverter));

        if (snapshot.empty) {

            throw new Error('no companies found');
        }

        // construct instances of Company with convertor
        const companies = snapshot.docs.map(doc => doc.data());

        // return instance of Companies
        return new Companies(companies);
    }


    constructor(companies) {
        this._companies = companies;
    }

    get companies () {
        return this._companies;
    }
}
