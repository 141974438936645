import React from 'react';
// session
import { withAuthUser } from '../../../Session/context';
// firebase
import Companies from '../../../firebase/collections/Companies/Companies';
// constants
import * as ROUTES from '../../../constants/routes';
// templates
import AdminUsersScreenTemplate from "../../templates/screens/admin/AdminUsersScreenTemplate";

/**
 * Admin Users Screen.
 */
class AdminUsersScreenBase extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            companies: null,

            viewMode: 0,
            createModalIsShown: false
        };

        this._unsubscribeFocusListener = null;
        this._unsubscribeBlurListener = null;
        this._unsubscribeCompaniesListener = null;
    }

    componentDidMount() {
        const { navigation } = this.props;


        // init users listener on screen focus
        this._unsubscribeFocusListener =
            navigation.addListener('focus', () => {
                // this._initUsersListener();
                this._initCompaniesListener();
            });

        // unsubscribe users listener on screen blur
        this._unsubscribeBlurListener =
            navigation.addListener('blur', () => {
                // this._unsubscribeUsersListener && this._unsubscribeUsersListener();
                this._unsubscribeCompaniesListener && this._unsubscribeCompaniesListener();
            })
    }

    componentWillUnmount() {
        // unsubscribe screen focus and blur listeners
        this._unsubscribeFocusListener && this._unsubscribeFocusListener();
        this._unsubscribeBlurListener && this._unsubscribeBlurListener();

        // unsubscribe companies listener
        this._unsubscribeCompaniesListener && this._unsubscribeCompaniesListener();
    }



    _initCompaniesListener = () => {
        try {
            this._unsubscribeCompaniesListener =
                Companies.buildListener(this._onCompaniesSnapshot);

        } catch (err) {
            console.log('failed to get companies...', err);

            this.setState({ companies: null });
        }
    };

    _onCompaniesSnapshot = (companies) => {
        this.setState({ companies });
    };

    // *** UI Handlers ***
    handleSwitchViewMode = (newViewMode) =>
        this.setState(({ viewMode }) => (
            newViewMode !== viewMode ? { viewMode: newViewMode } : null
        ));


    handlePressInvoice = () => {
        const { navigation } = this.props;

        navigation.navigate(ROUTES.ADMIN.USERS.INVOICE);
    };

    showCreateModal = () =>
        new Promise(resolve => this.setState({ createModalIsShown: true }, resolve));

    hideCreateModal = () =>
        new Promise(resolve => this.setState({ createModalIsShown: false }, resolve));


    handlePressNewCompany = () => {
        const { navigation } = this.props;

        navigation.navigate(ROUTES.ADMIN.COMPANY.NEW);
    };



    render() {
        const { companies, viewMode, createModalIsShown } = this.state;

        return (
            <AdminUsersScreenTemplate
                // users={users}
                companies={companies}

                viewMode={viewMode}
                handleSwitchViewMode={this.handleSwitchViewMode}

                handlePressInvoice={this.handlePressInvoice}

                // create modal
                handleShowCreateModal={this.showCreateModal}
                handleHideCreateModal={this.hideCreateModal}
                createModalIsShown={createModalIsShown}

                handlePressNewCompany={this.handlePressNewCompany}
            />
        );
    }
}

const AdminUsersScreen = withAuthUser(AdminUsersScreenBase);

// exports
export default AdminUsersScreen;
