import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { useTheme } from "styled-components";
// constants
import * as ROUTES from "../../../../../../../../../constants/routes";
// screens
import NewBookingScreen from "../../../../../../../../../components/screens/NewBookingScreen";
import getWebTabTitle from '../../../../../../../../../utilities/get-web-tab-title';

const Stack = createStackNavigator();

/**
 * New Booking Stack.  Child of MainStack, contains new booking screen
 */
const NewBookingStack = (): JSX.Element => {
    const { colors } = useTheme();

    // render
    return (
        <Stack.Navigator
            screenOptions={{
                headerShown: false,
                cardStyle: {
                    backgroundColor: colors.background
                },
                title: getWebTabTitle('New Booking'),
            }}
        >
            <Stack.Screen name={ROUTES.NEW_BOOKING.HOME} component={NewBookingScreen} />
        </Stack.Navigator>
    );
}

export default NewBookingStack;
