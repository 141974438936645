
// config
import CONFIG from '../config';


/**
 * Get start and end times of given array of times on given date
 * @param {Date} date - date
 * @param {{hours: number, mins: number}[]} times - array of time objects
 * @return {{endAt: {hours: number, mins: number}, startAt: {hours: number, mins: number}}}
 */
const getStartEndTimes = (date, times) => {
    let firstTime = times[0],
        lastTime = times[0];
    times.forEach(time => {
        if (time.hours < firstTime.hours) {
            firstTime = time;
        } else if (time.hours === firstTime.hours && time.mins < firstTime.mins) {
            firstTime = time;
        }

        if (time.hours > lastTime.hours) {
            lastTime = time;
        } else if (time.hours === lastTime.hours && time.mins > lastTime.mins) {
            lastTime = time;
        }
    });


    // add slot length to last chosen time (to get end time)
    const lastTimeEndObj = new Date(
        date.getFullYear(), date.getMonth(), date.getDate(),
        lastTime.hours, (lastTime.mins+CONFIG.BOOKING_LENGTH_MINS),
        0, 0
    );
    const lastTimeEnd = {
        hours: lastTimeEndObj.getHours(),
        mins: lastTimeEndObj.getMinutes()
    };


    return {
        startAt: firstTime,
        endAt: lastTimeEnd
    }
};


export default getStartEndTimes;