import { StyleSheet } from 'react-native';


const stylesheet = ({ margin, shadow, padding, colors, borderRadius }) => StyleSheet.create({
    modal: {
        justifyContent: 'flex-end',

        marginBottom: 0,
        marginHorizontal: margin.small
    },

    contentContainer: {
        position: 'relative'
    },

    innerContentContainer: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: (49+30)
    },

    titleWrapper: {
        height: 49,

        alignItems: 'center',
        justifyContent: 'center',

        backgroundColor: colors.primary,
        borderTopLeftRadius: borderRadius,
        borderTopRightRadius: borderRadius,
        borderBottomColor: colors.text.light,
        borderBottomWidth: StyleSheet.hairlineWidth
    },

    title: {
        color: colors.light
    },

    menuOption: {
        alignItems: 'center',
        justifyContent: 'center',

        height: 49,
        // padding: padding.medium,
        // marginBottom: padding.xSmall,

        backgroundColor: colors.light,
        borderColor: colors.text.light
    },

    optionTxt: {
        color: colors.primary
    }


});


export default stylesheet;