import { StyleSheet } from 'react-native';


const stylesheet = ({  }) => StyleSheet.create({
    wrapper: {
        flexDirection: 'row'
    }
});


export default stylesheet;