// types
import { TUserAlert, TUserConfirm } from './index';

/**
 * Small utility to extract the logic for combining title and message, in case we wish to expand on it in the future.
 */
const titleAndMessage = (title: string, message: string) => {

    return `${title} - ${message}`;
}

/**
 * Triggers an alert popup to user on web, using the built-in `window.alert` method
 */
export const userAlert: TUserAlert = (title, message, onPressOk): void => {

    window.alert(titleAndMessage(title, message));
    onPressOk?.();
}

/**
 * Triggers an alert popup to user on web, using the built-in `window.confirm` method.
 */
export const userConfirm: TUserConfirm = (
    title: string,
    message,
    onConfirm
): void => {

    if (window.confirm(titleAndMessage(title, message))) {

        onConfirm?.();
    }
}
