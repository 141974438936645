import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { withTheme } from "styled-components";
// constants
import * as ROUTES from "../../../../../../../../../constants/routes";
// utils
import getWebTabTitle from "../../../../../../../../../utilities/get-web-tab-title";
// screens
import AdminUsersScreen from "../../../../../../../../../components/screens/admin/AdminUsersScreen";
import AdminEditUserScreen from "../../../../../../../../../components/screens/admin/AdminEditUserScreen";
import AdminAddUserScreen from "../../../../../../../../../components/screens/admin/AdminAddUserScreen";
import AdminInvoiceScreen from "../../../../../../../../../components/screens/admin/AdminInvoiceScreen";
import AdminNewCompanyScreen from "../../../../../../../../../components/screens/admin/AdminNewCompanyScreen";
import AdminEditCompanyScreen from "../../../../../../../../../components/screens/admin/AdminEditCompanyScreen";
// import AdminInvoiceUserScreen from "../../../../../../../../../components/screens/admin/AdminInvoiceUserScreen";

/**
 * Child of MainStack, contains admin users screen
 */
const Stack = createStackNavigator();

const AdminUsersStackBase = ({ theme: { colors } }) => (
    <Stack.Navigator
        screenOptions={{
            headerShown: false,
            title: getWebTabTitle('Manage Tenants'),

            cardStyle: {
                backgroundColor: colors.background
            }
        }}
    >
        <Stack.Screen name={ROUTES.ADMIN.USERS.HOME} component={AdminUsersScreen} />
        <Stack.Screen name={ROUTES.ADMIN.USERS.EDIT} component={AdminEditUserScreen} options={{
            title: getWebTabTitle('Edit User')
        }}/>
        <Stack.Screen name={ROUTES.ADMIN.COMPANY.ADD_USER} component={AdminAddUserScreen} options={{
            title: getWebTabTitle('Add User')
        }}/>
        <Stack.Screen name={ROUTES.ADMIN.USERS.INVOICE} component={AdminInvoiceScreen} options={{
            title: getWebTabTitle('Invoice Tenants')
        }}/>
        <Stack.Screen name={ROUTES.ADMIN.COMPANY.NEW} component={AdminNewCompanyScreen} options={{
            title: getWebTabTitle('New Tenant')
        }}/>
        <Stack.Screen name={ROUTES.ADMIN.COMPANY.EDIT} component={AdminEditCompanyScreen} options={{
            title: getWebTabTitle('Edit Tenant')
        }}/>
        {/*<Stack.Screen name={ROUTES.ADMIN.USERS.INVOICE_USER} component={AdminInvoiceUserScreen} />*/}
    </Stack.Navigator>
);

const AdminUsersStack = withTheme(AdminUsersStackBase);

export default AdminUsersStack;
