import React from 'react';
// templates
import NewBookingScreenTemplate from '../templates/screens/NewBookingScreenTemplate';

/**
 * New Booking Screen
 */
const NewBookingScreen = (): JSX.Element => {

    // render
    return (
        <NewBookingScreenTemplate/>
    );
}

export default NewBookingScreen;
