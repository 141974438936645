// atoms
import LoadingSpinner from '../atoms/LoadingSpinner';
// organisms
import OverlayModal from '../organisms/OverlayModal';

/**
 * Loading spinner displayed in a modal, so it overlays screen content.
 */
const OverlayLoadingSpinner = (): JSX.Element => {

    // render
    return (
        <OverlayModal contentContainerCard={false}>
            <LoadingSpinner/>
        </OverlayModal>
    );
}

export default OverlayLoadingSpinner;
