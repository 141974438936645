import React, { forwardRef } from 'react';
import { useTheme } from 'styled-components';
// atoms
import StyledTextInput from './StyledTextInput';

/**
 * Auth text input
 */
const AuthTextInput = forwardRef(({
    style,
    ...props
}, ref) => {
    const theme = useTheme();

    // render
    return (
        <StyledTextInput
            style={[{
                marginBottom: theme.margin.medium,

                borderRadius: theme.borderRadius,
                ...theme.shadow.small,
            }, style, {
                padding: theme.padding.small
            }]}

            autoCapitalize='none'
            autoCorrect={false}
            clearButtonMode='while-editing'
            enablesReturnKeyAutomatically={true}

            ref={ref}
            {...props}
        />
    )
});

export default AuthTextInput;
