import * as React from "react"
import Svg, {Path} from "react-native-svg"
import { useTheme } from 'styled-components';
import PropTypes from 'prop-types';


const BuildingIcon = ({ width, height, size, ...props }) => {

    /* hooks */
    // styled components
    const theme = useTheme();


    /* render */

    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"

            width={width || theme.iconSizes[size]}
            height={height || theme.iconSizes[size]}

            {...props}
        >
            <Path
                d="M436 480h-20V24c0-13.255-10.745-24-24-24H56C42.745 0 32 10.745 32 24v456H12c-6.627 0-12 5.373-12 12v20h448v-20c0-6.627-5.373-12-12-12zM128 76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76zm0 96c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40zm52 148h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12zm76 160h-64v-84c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v84zm64-172c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40z"/>
        </Svg>
    )
}

BuildingIcon.propTypes = {
    size: PropTypes.string
};

BuildingIcon.defaultProps = {
    size: 'medium'
};

export default BuildingIcon;