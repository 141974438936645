import React, {useState, useRef, useCallback} from "react";
import { View, SafeAreaView, Text, Platform } from "react-native";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useTheme } from 'styled-components';
import {useFocusEffect, useNavigation} from "@react-navigation/native";
// constants
import * as ROUTES from '../../../../../constants/routes';
// firebase
import Company from '../../../../../firebase/documents/Company/Company';
// styles
import stylesheet from './stylesheet';
// atoms
import Card from '../../../../atoms/Card';
import FormTextInput from '../../../../atoms/FormTextInput';
import StyledButton from '../../../../atoms/StyledButton';
// atoms - text
import H2Text from '../../../../atoms/text/H2Text';
import H3Text from '../../../../atoms/text/H3Text';
import H4Text from '../../../../atoms/text/H4Text';
// atoms - icons
import UserCheckIcon from '../../../../atoms/icons/duotone/UserCheckIcon';
// molecules
import ScreenHeader from '../../../../molecules/ScreenHeader';
import OverlayLoadingSpinner from "../../../../molecules/OverlayLoadingSpinner";
// organisms
import OverlayModal from '../../../../organisms/OverlayModal';
// templates
import MasterTemplate from "../../../MasterTemplate";
// utils
import { userAlert } from "../../../../../utilities/alert";
// hooks
import { useMountedState } from "../../../../../hooks/react-use";

const TEST_MODE = false;

const INITIAL_STATE = TEST_MODE ? ({
    name: 'Seymour Ltd.',
    room: '007',
    quotaHours: '10',
    userName: 'Holly',
    userEmail: 'lucas@lb.digital',

    response: {
        company: { name: 'Seymour Ltd.' },
        user: { name: 'Holly', email: 'lb0689s@gre.ac.uk' }
    }
}) : ({
    name: '',
    room: '',
    quotaHours: '',
    userName: '',
    userEmail: '',

    isSubmitting: false,
    response: null,
    error: null,
});

/**
 * Admin New Company Screen Template
 */
const AdminNewCompanyScreenTemplate = ({ }) => {
    const theme = useTheme();
    const navigation = useNavigation();
    const styles = stylesheet(theme);
    const isMounted = useMountedState();

    // form values state - company
    const [name, setName] = useState(INITIAL_STATE.name);
    const [room, setRoom] = useState(INITIAL_STATE.room);
    const [quotaHours, setQuotaHours] = useState(INITIAL_STATE.quotaHours);
    // form values state - owner user
    const [userName, setUserName] = useState(INITIAL_STATE.userName);
    const [userEmail, setUserEmail] = useState(INITIAL_STATE.userEmail);
    // form error state
    const [error, setError] = useState(INITIAL_STATE.error);
    // form submitting state
    const [isSubmitting, setIsSubmitting] = useState(INITIAL_STATE.isSubmitting);
    // form submit response state
    const [response, setResponse] = useState(INITIAL_STATE.response);

    // form input refs - company
    const roomInputRef = useRef();
    const quotaHoursInputRef = useRef();
    // form input refs - owner user
    const userNameInputRef = useRef();
    const userEmailInputRef = useRef();

    useFocusEffect(useCallback(() => {

        return () => {
            setName(INITIAL_STATE.name);
            setRoom(INITIAL_STATE.room);
            setQuotaHours(INITIAL_STATE.quotaHours);
            setUserName(INITIAL_STATE.userName);
            setUserEmail(INITIAL_STATE.userEmail);

            setError(INITIAL_STATE.error);
            setIsSubmitting(INITIAL_STATE.isSubmitting);
            setResponse(INITIAL_STATE.response);
        }
    }, []));

    // on submit handler
    const handleSubmit = async () => {
        setIsSubmitting(true);

        let response;
        try {
            response =
                await Company.create({ name, room, quotaHours, userName, userEmail });

        } catch (err) {

            if (isMounted()) {

                let errMsg = err.message;
                if (err.code === 'functions/permission-denied') {
                    errMsg = 'You must connect to QuickBooks in the settings screen before you can create a new company.';
                }

                if (Platform.OS === 'web') {
                    // display error message as text below form on web
                    setError(errMsg);
                } else {
                    // display error in alert prompt to user on native devices
                    userAlert('Failed to Add Tenant', errMsg);
                }
            }
        }

        setIsSubmitting(false);

        if (response) {
            setResponse(response.data);
        }
    };

    // on press go to manage tenants
    const handlePressGoToManageTenants = () => {
        navigation.navigate(ROUTES.ADMIN.USERS.HOME);
    }

    // render
    const showUserForm = !!(name && quotaHours);
    const showSubmitBtn = !!(showUserForm && userName && userEmail);

    return (
        <MasterTemplate>
            <SafeAreaView style={{ flex: 1 }}>
                <ScreenHeader
                    title='New Company'
                    backButton={true}
                    style={{ marginBottom: 0 }}
                />


                <KeyboardAwareScrollView>
                    <View style={{ marginBottom: theme.margin.large }}>

                        <H3Text style={styles.formCardHeader}>Company Details</H3Text>
                        <Card style={styles.formCard}>

                            <H4Text>Company Name*</H4Text>
                            <FormTextInput
                                style={styles.textInput}
                                value={name}
                                onChangeText={val => setName(val)}
                                blurOnSubmit={false}
                                returnKeyType='next'
                                onSubmitEditing={() => roomInputRef.current.focus()}
                            />

                            <H4Text>Room</H4Text>
                            <FormTextInput
                                ref={roomInputRef}
                                style={styles.textInput}
                                value={room}
                                onChangeText={val => setRoom(val)}
                                placeholder='Room in CREATE (A-H, 001-036)'
                                blurOnSubmit={false}
                                returnKeyType='next'
                                onSubmitEditing={() => quotaHoursInputRef.current.focus()}
                            />
                            {/*<Text style={{ marginBottom: theme.padding.medium }}>*/}
                            {/*    Alphabetic rooms (e.g. A, E) have access to ground floor*/}
                            {/*    meeting rooms.  Numeric rooms (e.g. 002, 027) have access*/}
                            {/*    to first floor meeting rooms.*/}
                            {/*</Text>*/}

                            <H4Text>Monthly Bookings Quota*</H4Text>
                            <FormTextInput
                                ref={quotaHoursInputRef}
                                style={[styles.textInput, { marginBottom: 0 }]}
                                value={quotaHours}
                                onChangeText={val => setQuotaHours(val)}
                                placeholder='Free monthly booking hours'
                                keyboardType='number-pad'
                                blurOnSubmit={false}
                                returnKeyType='next'
                                onSubmitEditing={() => userNameInputRef.current.focus()}
                            />

                        </Card>

                        {showUserForm && (
                            <>
                                <H3Text style={styles.formCardHeader}>Company Admin</H3Text>
                                <Card style={styles.formCard}>
                                    <H4Text>Name*</H4Text>
                                    <FormTextInput
                                        ref={userNameInputRef}
                                        style={styles.textInput}
                                        value={userName}
                                        onChangeText={val => setUserName(val)}
                                        blurOnSubmit={false}
                                        returnKeyType='next'
                                        onSubmitEditing={() => userEmailInputRef.current.focus()}
                                    />

                                    <H4Text>Email*</H4Text>
                                    <FormTextInput
                                        ref={userEmailInputRef}
                                        style={[styles.textInput, { marginBottom: 0 }]}
                                        value={userEmail}
                                        onChangeText={val => setUserEmail(val)}
                                        autoCapitalize='none'
                                        keyboardType='email-address'
                                        blurOnSubmit={true}
                                        // returnKeyType='go'
                                        // onSubmitEditing={handleSubmit}
                                    />

                                    <Text style={{ marginTop: theme.padding.xSmall }}>
                                        This must be the email address used for {name} on
                                        QuickBooks to enable automated invoicing, and will create a user
                                        account for CREATE Bookings.
                                    </Text>

                                </Card>
                            </>
                        )}


                        {showSubmitBtn && (
                            <View style={styles.submitBtnWrapper}>
                                <StyledButton
                                    title='Create New Company'
                                    onPress={handleSubmit}
                                />

                                {error && (
                                    <Text style={styles.errorTxt}>{error}</Text>
                                )}
                            </View>
                        )}

                    </View>


                </KeyboardAwareScrollView>


                {isSubmitting && (
                    <OverlayLoadingSpinner/>
                )}

                {response && (
                    <OverlayModal contentContainerStyle={styles.responseModalContentContainer}>
                        <View style={styles.responseModalIconWrapper}>
                            <UserCheckIcon
                                fill={theme.colors.primary}
                                size='large'
                            />
                        </View>

                        <H2Text style={{ marginBottom: theme.margin.small }} >
                            Company Created!
                        </H2Text>

                        <H3Text style={styles.responseModalText}>
                            Company '{response.company.name}' was successfully created and a
                            user account for '{response.user.name}' added.  This user should
                            shortly receive a confirmation email to '{response.user.email}',
                            which contains their temporary password.
                        </H3Text>

                        <StyledButton
                            title='GO TO MANAGE TENANTS'
                            onPress={handlePressGoToManageTenants}
                        />
                    </OverlayModal>
                )}
            </SafeAreaView>
        </MasterTemplate>
    );
};

export default AdminNewCompanyScreenTemplate;
