import React, { useMemo } from 'react';
import { SafeAreaView, StyleSheet } from 'react-native';
import { useTheme } from 'styled-components';
// firebase
import Companies from '../../../../firebase/collections/Companies/Companies';
// atoms
import PlusIcon from '../../../atoms/icons/PlusIcon';
import FileInvoiceIcon from '../../../atoms/icons/FileInvoiceIcon';
// molecules
import ScreenHeader, { HeaderAction } from '../../../molecules/ScreenHeader';
// organisms
import CompanyList from '../../../organisms/CompanyList/CompanyList';
// templates
import MasterTemplate from '../../MasterTemplate';

interface Props {
    companies: Companies;

    handlePressInvoice: () => void;
    handlePressNewCompany: () => void;
}

/**
 * Admin users screen template
 */
const AdminUsersScreenTemplate = ({ companies, handlePressNewCompany, handlePressInvoice }: Props): JSX.Element => {
    const { colors } = useTheme();
    const styles = useStyles();

    // memoize header actions
    const headerActions = useMemo<HeaderAction[]>(() => [
        { icon: FileInvoiceIcon, label: 'Send Invoices', onPress: handlePressInvoice },
        { icon: PlusIcon, label: 'New Company', onPress: handlePressNewCompany }
    ], []);

    // render
    return (
        <MasterTemplate>
            <SafeAreaView style={styles.container}>
                <ScreenHeader title='Manage Tenants' actions={headerActions}/>

                <CompanyList companies={companies}/>
            </SafeAreaView>
        </MasterTemplate>
    )
};

const useStyles = () => {

    return StyleSheet.create({

        container: {

            flex: 1,
        },
    });
}

export default AdminUsersScreenTemplate;
