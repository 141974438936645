import { transparentize } from 'polished';
import { DefaultTheme } from 'styled-components';
import { TextStyle } from 'react-native';

// type defs
export interface Colors {
    primary: string;
    primaryOpaque: string;
    secondary: string;
    secondaryMuted: string;

    light: string;
    lightMuted: string;
    dark: string;
    darkMuted: string;

    background: string;

    text: {
        dark: string;
        light: string;
    },

    error: string;
}

interface Spacing {
    xSmall: number;
    small: number;
    medium: number;
    large: number;
}

interface FontSizes {
    h1: number;
    h2: number;
    h3: number;
    h4: number;
    p: number
}

type FontWeights = Record<'bold', TextStyle['fontWeight']>;

interface Fonts {
    // medium: 'SFProDisplay-Medium',
    medium: null,
    // bold: 'SFProDisplay-Bold',
    bold: null,

    compact: {
        // regular: 'SFCompactDisplay-Regular',

        // medium: 'SFCompactDisplay-Medium'
        medium: null
    }
}

interface Shadow {
    shadowOffset: {
        width: number;
        height: number;
    };
    shadowRadius: number;
    shadowOpacity: number;
    shadowColor: string;
    elevation: number;
}

interface Shadows {
    small: Shadow;
    medium: Shadow;
}

export interface IconSizes {
    small: number;
    medium: number;
    large: number;
}

declare module 'styled-components' {
    export interface DefaultTheme {
        colors: Colors;
        margin: Spacing;
        padding: Spacing;
        spacing: Spacing;
        fontSizes: FontSizes;
        fontWeights: FontWeights;
        fonts: Fonts;
        shadow: Shadows;
        iconSizes: IconSizes;

        borderRadius: number;
        webTabBarHeight: number;
    }
}

// default theme
const colors: Colors = {
    primary: '#8AB91D',
    primaryOpaque: transparentize(0.6, '#8AB91D'),
    secondary: '#4a4c53',
    secondaryMuted: transparentize(0.6, '#4a4c53'),

    light: '#fff',
    lightMuted: transparentize(0.3, '#fff'),
    dark: '#111',
    darkMuted: transparentize(0.5, '#111'),

    background: '#FCFFFF',

    text: {
        dark: '#191836',
        // light: '#9fa8ba',
        light: '#A2B3C2',
    },

    error: 'tomato'
};

const spacing: Spacing = {
    xSmall: 5,
    small: 10,
    medium: 20,
    large: 40
}

const fontSizes: FontSizes = {
    h1: 28,
    h2: 22,
    h3: 16,
    h4: 16,
    p: 16
}

const fontWeights: FontWeights = {
    bold: '600'
}

const fonts: Fonts = {
    // medium: 'SFProDisplay-Medium',
    medium: null,
    // bold: 'SFProDisplay-Bold',
    bold: null,

    compact: {
        // regular: 'SFCompactDisplay-Regular',

        // medium: 'SFCompactDisplay-Medium'
        medium: null
    }
}

const shadow: Shadows = {
    small: {
        shadowOffset: { width: 2, height: 2 },
        shadowRadius: 8,
        shadowOpacity: 0.1,
        shadowColor: colors.dark,
        elevation: 3
    },
    medium: {
        shadowOffset: { width: 2, height: 2 },
        shadowRadius: 10,
        shadowOpacity: 0.3,
        shadowColor: colors.secondary,
        elevation: 4
    }
}

const iconSizes: IconSizes = {
    small: 18,
    medium: 24,
    large: 34
}

const defaultTheme: DefaultTheme = {
    colors,
    fontSizes,
    fontWeights,
    fonts,
    shadow,
    iconSizes,
    spacing,

    padding: spacing, // legacy, should be replaced with spacing in new code
    margin: spacing, // legacy, should be replaced with spacing in new code

    borderRadius: 5,
    webTabBarHeight: 70,
};

// dark theme
const darkTheme: DefaultTheme = {
    ...defaultTheme,

    colors: {
        ...defaultTheme.colors,

        light: defaultTheme.colors.dark,
        dark: defaultTheme.colors.light,

        text: {
            dark: defaultTheme.colors.text.light,
            light: defaultTheme.colors.text.dark
        }
    },

    shadow: {
        ...defaultTheme.shadow,

        small: {
            ...defaultTheme.shadow.small,
            shadowColor: colors.light
        }
    }
};

const theme = {
    default: defaultTheme,
    dark: darkTheme
};

export default theme;
