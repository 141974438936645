import React, { useState, useCallback } from 'react';
import { View } from 'react-native';
import { useFocusEffect } from "@react-navigation/native";
// utils
import { userAlert } from "../../../utilities/alert";
// firebase
import User from '../../../firebase/documents/User/User';
// atoms
import LoadingSpinner from '../../atoms/LoadingSpinner';
// templates
import AdminEditUserScreenTemplate from "../../templates/screens/admin/AdminEditUserScreenTemplate";
// hooks
import { useMountedState } from "../../../hooks/react-use";

const INITIAL_STATE = {
    user: null,
}

/**
 * Edit user screen
 *
 * @returns {JSX.Element}
 */
const AdminEditUserScreen = ({ route: { params: { uid } } }) => {
    const isMounted = useMountedState();

    // user state
    const [ user, setUser ] = useState(INITIAL_STATE.user);

    // get user on focus
    useFocusEffect(useCallback(() => {

        (async () => {
            let u;
            try {
                u = await User.build(uid, { populateCompany: true });

            } catch (err) {
                userAlert('Failed to load user', 'Please try again');
                return;
            }

            if (isMounted()) {

                // console.log(u.isCompanyOwner);
                setUser(u);
            }
        })();

        return () => {
            // reset state on screen un-focus
            setUser(INITIAL_STATE.user);
        }
    }, [ uid ]));

    // render
    return (
        user ? (
            <AdminEditUserScreenTemplate user={user} />
        ) : (
            <View style={{ flex: 1 }}>
                <LoadingSpinner/>
            </View>
        )
    )
};


/* exports */
export default AdminEditUserScreen;
