import React, { useMemo, useEffect } from 'react';
// new, non-deprecated version of picker does not work in managed expo workflows, so have to continue using this
// deprecated one direct from 'react-native'
// see: https://github.com/react-native-picker/picker/issues/45#issuecomment-633163973
import { View, Picker } from 'react-native';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
// styles
import stylesheet from './stylesheet';
// constants
import { MONTHS } from '../../../constants/dates';

/**
 * Month year picker
 */
const MonthYearPicker = ({ minDate, maxDate, value, onChange }) => {
    const theme = useTheme();
    const styles = stylesheet(theme);

    /**
     * @param monthIndex {string}
     */
    const handleChangeMonth = (monthIndex) => {

        onChange && onChange(parseInt(monthIndex, 10), value.year);
    };

    /**
     * @param year {string}
     */
    const handleChangeYear = (year) => {

        onChange && onChange(value.monthIndex, parseInt(year, 10));
    };

    const currentYear = new Date().getFullYear();
    const maxMonthNum = useMemo(() => (
        (currentYear === value.year) ?
            new Date().getMonth() :
            12
    ), [currentYear, value]);

    // render
    return (
        <View style={styles.wrapper}>
            <Picker
                style={{ flex: 1 }}
                selectedValue={value.monthIndex.toString(10)}
                onValueChange={handleChangeMonth}
            >
                {MONTHS.slice(0, maxMonthNum).map((month, monthIndex) => (
                    <Picker.Item
                        key={month}
                        label={month}
                        value={monthIndex.toString(10)}
                    />
                ))}
            </Picker>

            <Picker style={{ flex: 1 }} selectedValue={value.year.toString(10)} onValueChange={handleChangeYear}>
                {[...new Array(currentYear - (minDate.getFullYear()-1))].map((_, i) => {
                    const year = (minDate.getFullYear()+i);

                    return (
                        <Picker.Item
                            key={year}
                            label={year.toString(10)}
                            value={year.toString(10)}
                        />
                    )
                })}
            </Picker>
        </View>
    );
}

MonthYearPicker.propTypes = {
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    value: PropTypes.exact({
        monthIndex: PropTypes.number,
        year: PropTypes.number
    }).isRequired,
    onChange: PropTypes.func
};

MonthYearPicker.defaultProps = {
    minDate: new Date(1970, 0),
    maxDate: new Date()
};

export default MonthYearPicker;
