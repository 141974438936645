import * as React from 'react';


/* components */
// templates
import ForgotPasswordScreenTemplate from '../templates/screens/auth/ForgotPasswordScreen/ForgotPasswordScreenTemplate';


/* screens/ForgotPasswordScreen */
const ForgotPasswordScreen = () => {


    /* render */
    return (
        <ForgotPasswordScreenTemplate/>
    )
}

export default ForgotPasswordScreen;
