import React, { useMemo } from 'react';
import { View, TouchableOpacity, StyleSheet, Platform, ViewProps } from 'react-native';
import { useTheme } from 'styled-components';
import { useNavigation } from "@react-navigation/native";
// atoms
import H1Text from '../atoms/text/H1Text';
import H3Text from '../atoms/text/H3Text';
import ArrowLeftIcon from '../atoms/icons/ArrowLeftIcon';
import StrongText from '../atoms/text/StrongText';
import {IconSizes} from '../../theme/theme';

interface Props extends ViewProps {
    title: string;
    subtitle?: string;
    backButton?: boolean;
    actions?: HeaderAction[];
}

export interface HeaderAction {
    label: string;
    icon: React.ComponentType<{ size: keyof IconSizes; fill: string; }>;
    onPress: () => void;
}

/**
 * Screen Header.
 *
 * NOTE: back button is only displayed on native, it is always hidden on web.
 */
const ScreenHeader = ({ title, subtitle, backButton = false, style, actions, ...props }: Props): JSX.Element => {
    const navigation = useNavigation();
    // platform flags
    const isNative = useMemo(() => Platform.OS !== 'web', []);
    const showBackBtn = useMemo(() => Platform.OS !== 'web', []);
    const showActionLabel = useMemo(() => Platform.OS === 'web', []);
    // styles
    const [styles, backBtnFill, actionIconFill] = useStyles(showActionLabel);

    // render
    return (
        <View style={[
            styles.container,
            isNative && styles.containerNative,
            style
        ]} {...props}>
            <View style={styles.innerContainer}>
                {backButton && showBackBtn && (
                    <TouchableOpacity style={styles.backBtn} onPress={() => navigation.goBack()}>
                        <ArrowLeftIcon fill={backBtnFill} size='small' />
                    </TouchableOpacity>
                )}

                <View>
                    <H1Text>{ title }</H1Text>
                    { subtitle ? <H3Text>{ subtitle }</H3Text> : null }
                </View>
            </View>

            {actions && (
                <View style={styles.actions}>
                    {actions.map(({
                        label,
                        icon: Icon,
                        onPress
                    }, index) => (
                        <TouchableOpacity
                            activeOpacity={0.5}
                            key={`action-${index}`}
                            style={[styles.action]}
                            onPress={onPress}
                        >
                            <Icon size='small' fill={actionIconFill}/>
                            {showActionLabel && (
                                <StrongText style={styles.actionLabel}>{ label }</StrongText>
                            )}
                        </TouchableOpacity>
                    ))}
                </View>
            )}
        </View>
    );
}

const useStyles = (showActionLabel: boolean) => {
    const { spacing, colors } = useTheme();

    return [StyleSheet.create({

        container: {

            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: spacing.medium
        },

        containerNative: {

            marginTop: 0,
        },

        innerContainer: {

            flexDirection: 'row',
        },

        backBtn: {

            padding: spacing.xSmall,
            marginRight: spacing.small,
            alignSelf: 'center',

            backgroundColor: colors.dark,
            borderRadius: 100
        },

        actions: {

            flexDirection: 'row',
        },

        action: {

            flexDirection: 'row',
            alignSelf: 'center',
            marginLeft: spacing.small,
            padding: showActionLabel ? spacing.small : spacing.xSmall,

            backgroundColor: colors.primary,
            borderRadius: showActionLabel ? 5 : 100
        },

        actionLabel: {

            marginLeft: spacing.small,
            color: colors.light,
        }
    }), colors.light, colors.light] as const;
}

export default ScreenHeader;
