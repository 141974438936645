import React from 'react';
import { ActivityIndicator, View, StyleSheet } from 'react-native';
import { useTheme } from "styled-components";

/**
 * Loading Spinner
 *
 * @returns {JSX.Element}
 */
const LoadingSpinner = (props) => {
    const styles = useStyles();
    const { colors } = useTheme();

    // render
    return (
        <View style={styles.wrapper} {...props} >
            <ActivityIndicator color={colors.primary} size='large' />
        </View>
    );
};

const useStyles = () => {

    return StyleSheet.create({
        wrapper: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center'
        }
    });
}

export default LoadingSpinner;
