import React, { useEffect } from 'react';
import { View } from 'react-native';
import * as WebBrowser from "expo-web-browser";
// atoms
import H3Text from '../atoms/text/H3Text';

/**
 * Screen displayed by the authentication web browser window once the auth callback returns.  Simply completes the auth
 * session and closes the auth window (in which this screen will load).
 */
const IntuitOauthRedirectScreen = (): JSX.Element => {

    // on load, finish auth
    useEffect(() => {
        WebBrowser.maybeCompleteAuthSession();
    }, []);

    // render
    return (
        <View>
            <H3Text>Authentication finished, it is now safe to close this window.  You should be automatically redirected shortly.</H3Text>
        </View>
    );
}

export default IntuitOauthRedirectScreen;
