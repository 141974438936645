import React, { useMemo, useEffect, useCallback } from 'react';
import { SectionList } from 'react-native';
import PropTypes from 'prop-types';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
// firebase
import Companies from '../../../firebase/collections/Companies/Companies';
import Users from '../../../firebase/collections/Users/Users';
// molecules
import ExpandedUser from '../../molecules/ExpandedUser/ExpandedUser';
// organisms
import SectionHeader from './SectionHeader';
import UserItem from './UserItem';
import LoadingTenants from './LoadingTenants';

const INITIAL_STATE = {
    expanded: {},
    expandedUser: null,
}

/**
 * Company List component
 *
 * @param companies
 * @return {JSX.Element}
 */
const CompanyList = ({ companies }) => {
    const navigation = useNavigation();

    // expanded companies state (key: company id; value: null while loading users, users array once loaded)
    const [ expanded, setExpanded ] = React.useState(INITIAL_STATE.expanded);
    // expanded user
    const [ expandedUser, setExpandedUser ] = React.useState(INITIAL_STATE.expandedUser);

    // reset state on blur
    useFocusEffect(useCallback(() => {

        return () => {
            setExpanded(INITIAL_STATE.expanded);
            setExpandedUser(INITIAL_STATE.expandedUser);
        }
    }, []));

    // on press section header
    const handlePressSectionHeader = async (companyId) => {
        if (Object.keys(expanded).includes(companyId)) {
            // shrink company
            const { [companyId]: omit, ...newExpanded } = expanded;
            setExpanded(newExpanded);

        } else {
            // expand company
            setExpanded({
                ...expanded,
                [companyId]: null
            });

            // get pressed company instance
            const company = companies.companies.find(c => (c.id === companyId));

            // populate companies tenants
            await company.populateUsers();

            // save users to state
            setExpanded({
                ...expanded,
                [company.id]: company.users
            });
        }
    };

    // on press user
    const handlePressUser = (user, company) => {
        // set users company
        user.company = company;

        setExpandedUser(user);
    };

    // on close user
    const handleCloseExpandedUser = () => {

        setExpandedUser(INITIAL_STATE.expandedUser);
    }

    // memoize list sections
    const sections = useMemo(() => companies instanceof Companies ?
        companies.companies.map(company => {
            const isExpanded = Object.keys(expanded).includes(company.id);
            const companyUsers = expanded[company.id];

            const data = companyUsers instanceof Users ? [
                // company owner
                companyUsers.users.find(u => (u.id === company.ownerRef.id)),
                // rest of users (enabled)
                ...companyUsers.activeUsers.filter(u => (u.id !== company.ownerRef.id)),
                // rest of users (disabled)
                ...companyUsers.disabledUsers.filter(u => (u.id !== company.ownerRef.id))
            ] : [];

            return ({
                title: company.name,
                key: company.id,
                data,

                company,
                isExpanded,
                firstUserInList: data.length ? data[0].id : null,
                lastUserInList: data.length ? data[data.length-1].id : null
            })
        }).sort(({ title: companyNameA }, { title: companyNameB }) =>
            companyNameA.toLowerCase().localeCompare(companyNameB.toLowerCase())) :
        [], [companies, expanded]);

    // render
    return (
        <>
            <SectionList
                sections={sections}
                keyExtractor={company => company.id}

                renderSectionHeader={
                    ({ section: { title, key, isExpanded, company } }) => (
                        <SectionHeader
                            title={title}
                            onPress={() => handlePressSectionHeader(key)}
                            isExpanded={isExpanded}
                            company={company}
                        />
                    )
                }

                renderSectionFooter={({ section: { data: users, isExpanded } }) => (
                    (isExpanded && users.length === 0) && (
                        // loading tenants
                        <LoadingTenants/>
                    )
                )}

                renderItem={({ item, section: { company, firstUserInList, lastUserInList } }) => (
                    <UserItem
                        user={item}
                        company={company}
                        onPress={() => handlePressUser(item, company)}
                        isFirstUserInList={(item.id === firstUserInList)}
                        isLastUserInList={(item.id === lastUserInList)}
                    />
                )}
            />

            {expandedUser && (
                <ExpandedUser
                    user={expandedUser}
                    handleClose={handleCloseExpandedUser}
                />
            )}
        </>
    );
};

CompanyList.propTypes = {
    companies: PropTypes.instanceOf(Companies)
};

CompanyList.defaultProps = {
    companies: null
};

export default CompanyList;
