import React from 'react';
import Svg, { Path } from "react-native-svg";
import { DefaultTheme, useTheme } from 'styled-components';

interface Props {
    size?: keyof DefaultTheme['iconSizes'];
    width?: number;
    height?: number;
    fill?: string;
}

/**
 * User Plus icon
 */
const UserEditIcon = ({ width, height, size = 'medium', ...props }: Props): JSX.Element => {
    const { iconSizes } = useTheme();

    // render
    return (
        <Svg viewBox="0 0 640 512"
             width={width || iconSizes[size]}
             height={height || iconSizes[size]}
             {...props}>
            <Path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h274.9c-2.4-6.8-3.4-14-2.6-21.3l6.8-60.9 1.2-11.1 7.9-7.9 77.3-77.3c-24.5-27.7-60-45.5-99.9-45.5zm45.3 145.3-6.8 61c-1.1 10.2 7.5 18.8 17.6 17.6l60.9-6.8 137.9-137.9-71.7-71.7-137.9 137.8zM633 268.9 595.1 231c-9.3-9.3-24.5-9.3-33.8 0l-37.8 37.8-4.1 4.1 71.8 71.7 41.8-41.8c9.3-9.4 9.3-24.5 0-33.9z" />
        </Svg>
    );
}

export default UserEditIcon;
