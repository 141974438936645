import React, {useCallback, useState} from "react";
import { useFocusEffect } from "@react-navigation/native";
// utils
import { userAlert } from "../../../utilities/alert";
// firebase
import Invoice from '../../../firebase/documents/Invoice/Invoice';
// templates
import AdminInvoiceScreenTemplate from '../../templates/screens/admin/AdminInvoiceScreen/AdminInvoiceScreenTemplate';

const INITIAL_STATE = {
    invoice: null,
    isSubmitting: false,
    invoices: null,
    invoicesErr: null,
}

/**
 * Admin Invoice Screen
 */
const AdminInvoiceScreen = ({ navigation }) => {

    // form fields state
    const [monthIndex, setMonthIndex] = useState(new Date().getMonth() - 1);
    const [year, setYear] = useState(new Date().getFullYear());
    // form state
    const [isSubmitting, setIsSubmitting] = useState(INITIAL_STATE.isSubmitting);
    // created invoice state
    const [invoice, setInvoice] = useState(INITIAL_STATE.invoice);
    // all time sent invoices state
    const [invoices, setInvoices] = useState(INITIAL_STATE.invoices);
    const [invoicesErr, setInvoicesErr] = useState(INITIAL_STATE.invoicesErr);

    useFocusEffect(useCallback(() => {
        const unsubListener = Invoice.startCollListener({
            populateCompaniesNoQbo: true,
            populateCreatedBy: true,
            populateSentInvoicesCompanies: true,
        }, (invoices) => {
            setInvoices(invoices);
            setInvoicesErr(INITIAL_STATE.invoicesErr);
        }, (err) => {
            setInvoicesErr(err.message);
            setInvoices(INITIAL_STATE.invoices);
        });

        return () => {
            unsubListener();
        }
    }, []));

    const handleChangeMonthYear = (monthIndex, year) => {

        setMonthIndex(monthIndex);
        setYear(year);
    }

    const handlePressSendInvoices = async () => {
        setIsSubmitting(true);

        // check if invoice previously sent for this month-year
        let prevInvoice;
        try {
            prevInvoice = await Invoice.build(monthIndex, year, { populateCreatedBy: true });

        } catch (err) {
            if (err.message !== 'invoice not found') {
                setIsSubmitting(INITIAL_STATE.isSubmitting);

                return userAlert(
                    'Failed to send Invoices',
                    'An error occurred, please try again.'
                );
            }
        }


        if (prevInvoice) {
            // this month-year has already been invoiced
            setIsSubmitting(INITIAL_STATE.isSubmitting);

            return userAlert(
                'Failed to send Invoices',
                `Meeting room invoices for ${prevInvoice.dateStr} have already been created and sent by ${prevInvoice.createdBy.name} on ${prevInvoice.createdAtStr}`
            );
        }


        let invoice;
        try {
            invoice = await Invoice.create(monthIndex, year);

        } catch (err) {
            setIsSubmitting(INITIAL_STATE.isSubmitting);

            console.log(err);
            return userAlert('Failed to Send Invoices', err.message);
        }

        setIsSubmitting(INITIAL_STATE.isSubmitting);
        setInvoice(invoice);
    };

    // const handlePressGoToManageTenants = () => {
    //
    //     navigation.navigate(ROUTES.ADMIN.USERS.HOME);
    // };

    const handlePressDone = () => {

        setInvoice(INITIAL_STATE.invoice);
    }

    // render
    return (
        <AdminInvoiceScreenTemplate
            monthIndex={monthIndex}
            year={year}
            handleChangeMonthYear={handleChangeMonthYear}
            handlePressSendInvoices={handlePressSendInvoices}
            isSubmitting={isSubmitting}
            invoice={invoice}
            // handlePressGoToManageTenants={handlePressGoToManageTenants}
            handlePressDone={handlePressDone}
            invoices={invoices}
            invoicesErr={invoicesErr}
        />
    );
}

export default AdminInvoiceScreen;
