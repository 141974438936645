import React, { useContext, useState, useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';
// session
import AuthUserContext from '../../Session/context';
// templates
import BookingsScreenTemplate from '../templates/screens/BookingsScreenTemplate';
import Bookings from '../../firebase/collections/Bookings/Bookings';

const INITIAL_STATE = {
    bookings: null,
    viewMode: 0,
} as const;

/**
 * Bookings Screen
 */
const BookingsScreen = () => {
    const authUser = useContext(AuthUserContext);

    // bookings to display state
    const [ bookings, setBookings ] = useState<Bookings | null>(INITIAL_STATE.bookings);
    // view mode state (0:upcoming, 1:past)
    const [ viewMode, setViewMode ] = useState<0 | 1>(INITIAL_STATE.viewMode);

    // start bookings listener on focus
    useFocusEffect(useCallback(() => {
        const onBookingsSnapshot = (bookings: Bookings) => {
            setBookings(bookings);
        }

        const initBookingsListener = () => {
            try {
                return authUser.company.initBookingsListener(onBookingsSnapshot, {
                    populateCompany: true,
                    populateRooms: true,
                    populateUser: true
                });

            } catch (err) {
                setBookings(INITIAL_STATE.bookings);
            }
        }

        const unsubBookingsListener = initBookingsListener();

        return () => {
            unsubBookingsListener();
        }
    }, []))

    // render
    return (
        <BookingsScreenTemplate
            bookings={bookings}
            viewMode={viewMode}
            handleSwitchViewMode={setViewMode}
            titlePrefix='My'
            showUser={true}
        />
    );
}

export default BookingsScreen;
