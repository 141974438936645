import React from 'react';
import {StyleSheet, View} from 'react-native';
import {DefaultTheme, useTheme} from 'styled-components';
// utils
import {userAlert, userConfirm} from '../../utilities/alert';
// session
import {withAuthUser} from '../../Session/context';
// firebase
import Booking from '../../firebase/documents/Booking/Booking';
import AuthUser from '../../firebase/documents/User/AuthUser';
// atoms
import H3Text from '../atoms/text/H3Text';
import H2Text from '../atoms/text/H2Text';
// atoms - icons
import CalendarIcon from '../atoms/icons/CalendarIcon';
import ClockIcon from '../atoms/icons/ClockIcon';
import StickyNoteIcon from '../atoms/icons/StickyNoteIcon';
import ChalkboardIcon from '../atoms/icons/ChalkboardIcon';
import StyledButton from '../atoms/StyledButton';
import UserIcon from '../atoms/icons/UserIcon';
import BuildingIcon from '../atoms/icons/BuildingIcon';
import RepeatIcon from '../atoms/icons/RepeatIcon';
// molecules
import ExpandedBookingInfoItem from '../molecules/ExpandedBookingInfoItem';
// organisms
import OverlayModal from './OverlayModal';
import bookingFreqLabel from '../../utilities/booking-freq-label';

interface Props {
    booking: Booking;
    handleClose: () => void;
    showUser?: boolean;
    showCompany?: boolean;
    authUser: AuthUser;
    ignoreRecurring?: boolean;
}

const ExpandedBooking = ({
    booking,
    handleClose,
    showUser = false,
    showCompany = false,
    authUser,
    ignoreRecurring = false,
}: Props) => {
    const theme = useTheme();
    const styles = stylesheet(theme);

    const cancelBooking = async (booking: Booking): Promise<void> => {

        try {

            await booking.cancel(authUser.isAdmin);
        } catch (err) {

            userAlert('Failed to Cancel Booking', err.message);
        }

        handleClose();
    };

    const cancelNextBookingReoccurrence = async (booking: Booking): Promise<void> => {

        try {

            await booking.cancelNextReoccurrence(authUser.isAdmin);
        } catch (err) {

            userAlert('Failed to Cancel Next Booking Reoccurrence', err.message);
        }

        handleClose();
    };

    const handlePressCancel = (): void => {

        const alertMsg = `Are you sure you would like to cancel ${booking.isRecurring ? 'all future bookings' : 'booking'} of ${booking.bookingStr}?`;

        userConfirm(
            'Cancel Booking',
            alertMsg,
            () => cancelBooking(booking),
            'Cancel Booking'
        );
    };

    const handlePressCancelNext = (): void => {

        const alertMsg = `Are you sure you would like to cancel the next reoccurrence of booking for ${booking.nextReoccurrenceBookingStr}?`;

        userConfirm(
            'Cancel Next Booking Reoccurrence',
            alertMsg,
            () => cancelNextBookingReoccurrence(booking),
            'Cancel Next Booking'
        );
    }

    // render
    return (
        <OverlayModal
            blurIntensity={90}
            contentContainerStyle={styles.modalContentContainer}
            handlePressClose={() => handleClose()}
            closeBtnFill={theme.colors.light}
        >
            {(!booking.isInPast || booking.isRecurring) && (
                <H3Text style={{ color: theme.colors.lightMuted }}>
                    {
                        (() => {
                            if (booking.isRecurring && !ignoreRecurring) {
                                return `Next: ${booking.nextReoccurrenceIsToday ? 'Today' : booking.nextReoccurrenceDateStr}`;
                            } else if (booking.isOngoing) {
                                return 'Ongoing';
                            } else if (booking.isToday) {
                                return 'Today';
                            } else if (booking.isTomorrow) {
                                return 'Tomorrow';
                            } else {
                                return `In ${booking.daysUntil} days`;
                            }
                        })()
                    }
                </H3Text>
            )}

            <H2Text style={styles.title}>
                { booking.rooms.shortRoomsStr }
            </H2Text>

            {/* TODO remove 'as any' (not urgent) */}
            {/*booking date*/}
            <ExpandedBookingInfoItem icon={CalendarIcon as any} value={booking.dateStr} />
            {/*booking time*/}
            <ExpandedBookingInfoItem icon={ClockIcon as any} value={booking.timeStr} />
            {/*booking company*/}
            { showCompany && <ExpandedBookingInfoItem icon={BuildingIcon as any} value={booking?.company?.name} /> }
            {/*booking user*/}
            { showUser && <ExpandedBookingInfoItem icon={UserIcon as any} value={booking?.user?.name} /> }
            {/*booking notes*/}
            { booking.notes && <ExpandedBookingInfoItem icon={StickyNoteIcon as any} value={booking.notes} /> }
            {/*booking needs smart board*/}
            { booking.needSmartBoard && <ExpandedBookingInfoItem icon={ChalkboardIcon as any} value='Need smart board' /> }
            {/*recurring booking*/}
            { booking.isRecurring && (
                <ExpandedBookingInfoItem icon={RepeatIcon as any} value={bookingFreqLabel(booking.recurringFreq, booking.startAt)}/>
            )}

            <View style={styles.footer}>
                <StyledButton
                    outline={true}
                    title='CANCEL'
                    style={styles.footerBtn}
                    titleStyle={{ color: theme.colors.light }}
                    onPress={handlePressCancel}
                />

                {booking.isRecurring && (
                    <StyledButton
                        outline={true}
                        title='CANCEL NEXT'
                        style={styles.footerBtn}
                        titleStyle={{ color: theme.colors.light }}
                        onPress={handlePressCancelNext}
                    />
                )}
            </View>
        </OverlayModal>
    );
}

const stylesheet = ({ colors, spacing }: DefaultTheme) => StyleSheet.create({

    modalContentContainer: {

        backgroundColor: colors.primary
    },

    title: {

        color: colors.light,
        marginBottom: spacing.medium
    },

    footer: {

        marginTop: spacing.medium,
        flexDirection: 'row',
        justifyContent: 'flex-start'
    },

    footerBtn: {
        borderColor: colors.light,
        width: null,
        marginRight: spacing.medium,
    }
});

export default withAuthUser(ExpandedBooking);
