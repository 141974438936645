// enums
import BookingFreqEnum from '../enums/BookingFreqEnum';
// constants
import { DAYS } from '../constants/dates';

export default function bookingFreqLabel(freq: BookingFreqEnum, date: Date): string {

    const dayStr = DAYS[(date.getDay() + 6) % 7];

    if (freq === BookingFreqEnum.WEEKLY) {
        return `Every ${dayStr}`;
    }

    const monthlyRecurringLabels = {
        [BookingFreqEnum.MONTHLY_FIRST]: 'First',
        [BookingFreqEnum.MONTHLY_SECOND]: 'Second',
        [BookingFreqEnum.MONTHLY_THIRD]: 'Third',
        [BookingFreqEnum.MONTHLY_FOURTH]: 'Fourth',
        [BookingFreqEnum.MONTHLY_LAST]: 'Last',
    }

    return `${monthlyRecurringLabels[freq]} ${dayStr} of Every Month`;
}
