import React, { useState } from "react";
import {
    View, TouchableOpacity, Image,
    StyleSheet,
    useWindowDimensions
} from 'react-native';
import { transparentize } from 'polished';
import { CommonActions } from '@react-navigation/native';
// atoms
import StrongText from '../atoms/text/StrongText';
// atoms - icons
import BarsIcon from '../atoms/icons/duotone/BarsIcon';
// style
import { useTheme } from "styled-components";
import { BottomTabBarProps } from '@react-navigation/bottom-tabs';
// assets
import logoPNG from "../../assets/logo.png";
// constants
import * as ROUTES from "../../constants/routes";

const TABLET_BREAKPOINT = 910; // brand hidden
const MOBILE_BREAKPOINT = 760; // brand unhidden, collapsed menu

interface Props extends BottomTabBarProps {
    isAdminUser: boolean;
}

const TabBar = ({ state, descriptors, navigation, isAdminUser }: Props): JSX.Element => {
    const theme = useTheme();
    const styles = useStyles();
    const windowDimensions = useWindowDimensions();

    const isTabletBreakpoint = windowDimensions.width < TABLET_BREAKPOINT;
    const isMobileBreakpoint = windowDimensions.width < MOBILE_BREAKPOINT;

    const [mobileExpanded, setMobileExpanded] = useState(false);

    const handlePressBrand = () => {

        setMobileExpanded(false);
        navigation.navigate(isAdminUser ? ROUTES.HOME.ROOT : ROUTES.BOOKINGS.ROOT)
    }

    // render
    return (
        <View style={styles.container}>
            {(!isTabletBreakpoint || isMobileBreakpoint) && (
                <View style={styles.contentLeft}>
                    <TouchableOpacity
                        style={styles.brandContainer}
                        onPress={handlePressBrand}
                    >
                        <Image source={{
                            width: 339,
                            height: 99,
                            uri: logoPNG,
                        }} style={styles.brand}/>
                    </TouchableOpacity>
                </View>
            )}

            {isMobileBreakpoint && (
                <TouchableOpacity onPress={() => setMobileExpanded((prevMobileExpanded => !prevMobileExpanded))}>
                    <BarsIcon size={'large'} fill={theme.colors.light}/>
                </TouchableOpacity>
            )}

            {(!isMobileBreakpoint || mobileExpanded) && (
                <View style={[
                    styles.linksContainer,
                    isTabletBreakpoint && !isMobileBreakpoint && styles.linksContainerTablet,
                    isMobileBreakpoint && styles.linksContainerMobile
                ]}>
                    {state.routes.map((route, index) => {

                        const { options } = descriptors[route.key];
                        const isFocused = state.index === index;

                        const label = (typeof options.tabBarLabel === 'string') ? options.tabBarLabel :
                            (typeof options.title === 'string') ? options.title :
                                route.name;

                        const Icon = options.tabBarIcon;
                        // const iconColor = isFocused ? options.tabBarActiveTintColor : options.tabBarInactiveTintColor;

                        const handlePress = () => {
                            setMobileExpanded(false);

                            const event = navigation.emit({
                                type: 'tabPress',
                                target: route.key,
                                canPreventDefault: true,
                            });

                            /**
                             * Logic to ensure the tab bar correctly navigates user, even if they directly visit a nested
                             * screen from a URL, rather than navigating to it via the app navigation stack.
                             *
                             * @see https://github.com/react-navigation/react-navigation/issues/8935
                             */
                            if (!event.defaultPrevented) {
                                if (isFocused) {
                                    navigation.dispatch((navState) => {

                                        return CommonActions.reset({
                                            index,
                                            routes: navState.routes.map((route) => ({
                                                name: route.name
                                            }))
                                        })
                                    })
                                } else {
                                    navigation.dispatch({
                                        ...CommonActions.navigate(route.name),
                                        target: state.key
                                    });
                                }
                            }
                        };

                        const handleLongPress = () => {
                            navigation.emit({
                                type: 'tabLongPress',
                                target: route.key,
                            });
                        };

                        return (
                            <TouchableOpacity
                                key={`tabBarOption-${index}`}
                                accessibilityRole='button'
                                accessibilityState={isFocused ? { selected: true } : {}}
                                accessibilityLabel={options.tabBarAccessibilityLabel}
                                testID={options.tabBarTestID}
                                onPress={handlePress}
                                onLongPress={handleLongPress}
                                style={[styles.linkContainer, isFocused && styles.linkContainerFocused]}
                            >
                                {Icon && (
                                    <View style={styles.linkIconContainer}>
                                        {/* @ts-ignore - temp until have time to find proper fix */}
                                        <Icon
                                            focused={isFocused}
                                            color={theme.colors.light}
                                            size={theme.iconSizes.small}
                                        />
                                    </View>
                                )}

                                <StrongText style={styles.linkText}>{ label }</StrongText>
                            </TouchableOpacity>
                        );
                    })}
                </View>
            )}
        </View>
    );
}

const useStyles = () => {
    const { colors, spacing, webTabBarHeight, borderRadius, shadow } = useTheme();

    const brandHeight = webTabBarHeight * 0.6;
    const brandWidth = (339 / 99) * brandHeight;

    return StyleSheet.create({
        container: {
            position: 'absolute',

            width: '100%',
            height: webTabBarHeight,

            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',

            paddingHorizontal: spacing.medium,
            paddingVertical: spacing.medium,

            backgroundColor: colors.primary
        },

        contentLeft: {
            flex: 1,
        },

        brandContainer: {
            width: brandWidth,
            height: brandHeight,
        },

        brand: {
            width: '100%',
            height: '100%',
        },

        linksContainer: {

            flexDirection: 'row',
        },

        linksContainerTablet: {

            flex: 1,
            justifyContent: 'space-between'
        },

        linksContainerMobile: {

            flexDirection: 'column',
            position: 'absolute',
            top: webTabBarHeight,
            right: 0,

            borderBottomLeftRadius: borderRadius,
            backgroundColor: colors.primary,
            ...shadow.medium
        },

        linkContainer: {

            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',

            padding: spacing.small,
            borderRadius,
        },

        linkContainerFocused: {
            backgroundColor: transparentize(0.85, colors.light),
        },

        linkIconContainer: {

            marginBottom: spacing.xSmall,
        },

        linkText: {
            color: colors.light,
        },
    })
}

export default TabBar;
