import React, { useMemo } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useTheme } from 'styled-components';
// atoms
import H3Text from '../atoms/text/H3Text';

interface Props {
    activeViewMode: number;
    viewMode: number;
    handler: (viewMode: number) => void;
    title: string;
}

/**
 * View Switch Button (use solely by the View Switcher component)
 */
const ViewSwitchBtn = ({ activeViewMode, handler, title, viewMode }: Props): JSX.Element => {
    const styles = useStyles();

    // memoize active state
    const isActive = useMemo(() => (activeViewMode === viewMode), [activeViewMode, viewMode]);

    // render
    return (
        <TouchableOpacity
            onPress={() => handler(viewMode)}
            style={[styles.btn, isActive && styles.btnActive]}
            disabled={isActive}
        >
            <H3Text style={[styles.text, isActive && styles.activeText]}>
                { title }
            </H3Text>
        </TouchableOpacity>
    );
};

const useStyles = () => {
    const { spacing, borderRadius, colors, shadow, fontWeights } = useTheme();

    return StyleSheet.create({

        btn: {

            flex: 1,
            alignItems: 'center',
            padding: spacing.small,
            borderRadius
        },
        btnActive: {

            backgroundColor: colors.light,
            ...shadow.small
        },

        text: {

            color: colors.text.light
        },
        activeText: {

            color: colors.primary,
            fontWeight: fontWeights.bold
        }
    });
}

export default ViewSwitchBtn;
