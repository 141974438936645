import isEmail from 'validator/es/lib/isEmail';
// superclass
import FirestoreDoc from '../FirestoreDoc';
// firebase
import { firestore } from '../../firebase';
import { collection, doc, getDoc, where, query, updateDoc } from "firebase/firestore";
import Users from '../../collections/Users/Users';
import Bookings from '../../collections/Bookings/Bookings';
import callableFunctions from "../../functions/callable-functions";
import User from "../User/User";


export default class Company extends FirestoreDoc {

    static _validateCompanyData = ({ name, room, quotaHours }) => {
        if (!name)
            throw new Error('Company Name is required');

        if (room && typeof room !== 'string')
            throw new Error('Room invalid');

        if (!quotaHours)
            throw new Error('Monthly Bookings Quota is required');

        if (isNaN(quotaHours) || +quotaHours < 0)
            throw new Error('Monthly Bookings Quota must be a positive number or 0');
        quotaHours = +quotaHours;

        return { name, room, quotaHours };
    }

    static create = async ({ name, room, quotaHours, userName, userEmail }) => {
        // validate data
        const validatedData = Company._validateCompanyData({
            name, room, quotaHours
        });

        // validate admin user data
        if (!userName) {

            throw new Error('Company Admin Name is required');
        }

        if (!userEmail) {

            throw new Error('Company Admin Email is required');
        }

        if (!isEmail(userEmail)) {

            throw new Error('Company Admin Email invalid');
        }

        return callableFunctions.company.createCompany({
            ...validatedData,
            userName,
            userEmail
        });
    };


    static build = async (id, options) => {
        options = {
            populateOwner: (options?.populateOwner === true),
            populateUsers: (options?.populateUsers === true)
        };

        const snapshot = await getDoc(doc(collection(firestore, 'companies'), id)
            .withConverter(Company.firestoreConverter));

        if (!snapshot.exists())
            throw new Error('company not found');

        // construct Company instance with converter
        const company = snapshot.data();

        // init company instance
        await company.init(options);

        // return instance of Company
        return company;
    };


    static firestoreConverter = {
        fromFirestore: (snapshot, options) => {
            const { createdAt, updatedAt, ...data } = snapshot.data(options);

            return new Company(
                snapshot.ref,
                snapshot.id,

                createdAt,
                updatedAt,

                data
            );
        }
    };

    constructor(ref, id, createdAt, updatedAt, { name, owner, quotaHours, room }) {
        super(ref, id, createdAt, updatedAt);

        this._name = name;
        this._ownerRef = owner;
        this._quotaHours = quotaHours;
        this._room = room;

        this._owner = null;
        this._users = null;
    }

    init = async ({ populateUsers, populateOwner }) => {
        if (populateUsers) {
            await this.populateUsers();
        }

        if (populateOwner) {
            await this.populateOwner();
        }
    };


    populateOwner = async () => {
        if (this._ownerRef) {
            // get owner user doc instance
            const ownerUser = await User.build(this._ownerRef.id);

            if (ownerUser)
                this._owner = ownerUser;
        }

        return this._owner;
    };

    populateUsers = async () => {
        // custom user query
        const userQuery = query(
            collection(firestore, 'users'),
            where('company', '==', this.ref)
        );

        // build and store custom Users instance
        this._users = await Users.buildCustom(userQuery);

        // return users
        return this._users;
    };



    initBookingsListener = (callback, options) => {

        return Bookings.buildCustomListener(query(
            collection(firestore, 'bookings'),
            where('company', '==', this.ref)
        ), callback, options);
    }



    // *** Accessor Methods ***
    get name() { return this._name; }
    get ownerRef() { return this._ownerRef; }
    get owner() { return this._owner; }
    get quotaHours() { return this._quotaHours; }
    get room() { return this._room; }

    get users() { return this._users; }

    get roomFloor() {
        if (this._room) {
            // company has a room
            // integer rooms first floor, alphabetic rooms ground floor
            return (!isNaN(this._room) ? 1 : 0);
        } else {
            return null;
        }
    }


    // mutators

    /**
     *
     * @param name
     * @param room
     * @param quotaHours
     * @param {{ id: string; email: string }} adminUser
     * @return {Promise<void>}
     */
    update = async ({ name, room, quotaHours, adminUser }) => {
        // validate data
        const validatedData = Company._validateCompanyData({
            name, room, quotaHours
        });

        // validate admin user
        if (!adminUser) {

            throw new Error('You must choose a Company Admin');
        }

        const cUsersIds = this._users.users.map(u => u.id);
        if (!cUsersIds.includes(adminUser.id)) {

            throw new Error('Chosen user not in this company!');
        }

        // ensure chosen user's email can be matched to a QBO customer
        let qboCustomerFound;
        try {
            const response = await callableFunctions.company.validateOwnerEmail({ email: adminUser.email });
            qboCustomerFound = response.data;
        } catch (err) {

            if (err.code === 'functions/permission-denied') {
                throw new Error('You must connect to QuickBooks in the settings screen before you can edit a company admin user.');
            }

            throw err;
        }

        if (!qboCustomerFound) {

            throw new Error('A matching QuickBooks customer could not be found for the email address of the newly selected company admin.  Please update the tenants email in QuickBooks before updating the admin here.');
        }

        // update company in firestore
        const ownerRef = doc(firestore, 'users', adminUser.id);

        try {
            await updateDoc(this.ref, {
                ...validatedData,
                owner: ownerRef
            });

        } catch (err) {
            throw err;
        }
    }


    addUser = async ({ name, email }) => {
        // clean data
        name = name.trim();

        // validate data
        if (!name) throw new Error("Users' name is required");
        if (!email) throw new Error("Users' email is required");
        if (!isEmail(email)) throw new Error("Users' email is invalid");

        // call firebase function
        return callableFunctions.company.addUser({
            name,
            email,
            company: this.id
        });
    }

}
