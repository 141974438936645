import { httpsCallable } from 'firebase/functions';
// firebase
import { functions } from '../firebase';

const callableFunctions = {
    company: {
        createCompany: httpsCallable<{
            name: string;
            room: string;
            quotaHours: number;
            userName: string;
            userEmail: string;
        }, {
            company: {
                id: string;
                name: string;
            };
            user: {
                id: string;
                name: string;
                email: string;
            }
        }>(functions, 'company-createCompany'),
        addUser: httpsCallable<{
            name: string;
            email: string;
            company: string; // companyId
        }, {
            user: {
                id: string;
                email: string;
                name: string;
                activationCode: string;
            }
            company: {
                name: string;
            }
        }>(functions, 'company-addUser'),
        validateOwnerEmail: httpsCallable<{
            email: string;
        }, boolean>(functions, 'company-validateOwnerEmail')
    },
    user: {
        newUser: httpsCallable<{
            email: string;
            extraData: {
                name: string;
                company: string;
                room: string;
                quotaHours: number;
                roles: Record<string, string>;
            }
        }, {
            uid: string;
            password: string;
            room?: string;
            roles?: Record<string, string>;
            name?: string;
            email: string;
            quotaHours: number;
            company: string;
        }>(functions, 'user-newUser'),
        activateUser: httpsCallable<{
            activationCode: string;
            password: string;
        }, {
            user: {
                email: string;
            }
        }>(functions, 'user-activateUser'),
        checkEmailInUse: httpsCallable<{
            email: string;
        }, {
            userIdWithEmail?: string;
        }>(functions, 'user-checkEmailInUse')
    },
    intuit: {
        getAuthUri: httpsCallable<{
            successUri: string;
        }, {
            authUri: string;
        }>(functions, 'intuit-getAuthUri')
    },
    invoice: {
        sendInvoices: httpsCallable<{
            month: number; // in range 0 - 11 (0: Jan, ..., 11: Dec)
            year: number; // in range 2000 - 3000
        }, {}>(functions, 'invoice-sendInvoices')
    }
}

export default callableFunctions;
