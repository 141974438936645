import { StyleSheet } from 'react-native';


const stylesheet = ({ margin, padding, colors }) => StyleSheet.create({

    formCard: {
        marginHorizontal: margin.medium
    },

    input: {
        marginTop: padding.xSmall,
        marginBottom: margin.small
    },

    submitBtnWrapper: {
        marginHorizontal: margin.medium,
        marginTop: margin.medium
    },

    errorTxt: {
        marginTop: padding.xSmall,

        color: colors.error
    }

});

export default stylesheet;