// firebase
import { firestore } from '../../firebase';
import { collection, documentId, query, where, getDocs } from 'firebase/firestore';
// firebase doc classes
import Room from '../../documents/Room/Room';

/**
 * Rooms entity
 */
export default class Rooms {

    static async build(options) {
        options = {
            populate: (options?.populate !== false),
            floor: Number.isInteger(options?.floor) ? options.floor : undefined
        };

        const roomsCollRef = collection(firestore, 'rooms');
        let roomsQuery = roomsCollRef.withConverter(Room.firestoreConverter);

        if (options.floor !== undefined) {

            roomsQuery = query(roomsQuery, where('floor', '==', options.floor));
        }

        // get room docs from firestore
        // noinspection JSCheckFunctionSignatures
        const snapshot = await getDocs(roomsQuery);

        if (snapshot.empty)
            throw new Error('no rooms found');

        // construct instances of RoomDoc with converter
        const roomDocs = snapshot.docs.map(doc => doc.data());

        // initialize RoomDoc instances
        await Promise.all(roomDocs.map(doc => doc.init(options.populate)));

        // return instance of RoomsColl
        return new Rooms(roomDocs);
    }

    static async buildRefs(roomRefs, options) {
        options = {
            populate: (options?.populate !== false)
        };

        // get room docs from db
        let rooms = [];
        try {
            // noinspection JSCheckFunctionSignatures
            const snapshot = await getDocs(
                query(collection(firestore, 'rooms'),
                    where(documentId(), 'in', roomRefs.map(roomRef => roomRef.id))
                ).withConverter(Room.firestoreConverter)
            );

            if (!snapshot.empty)
                rooms = snapshot.docs.map(doc => doc.data());

        } catch (err) {
            throw err;
        }

        // initialize room docs
        try {
            await Promise.all(rooms.map(room => room.init(options.populate)))

        } catch (err) {
            throw new Error('some room docs failed to initialize.');
        }

        return new Rooms(rooms);

    }


    constructor(rooms) {
        this._rooms = rooms;

    }


    // accessor methods (getters)
    get rooms() { return this._rooms; }

    getRoom(id) {
        return this._rooms.find(room => (room.id === id));
    }

    get shortRoomsStr() {
        let roomShortNames = this._rooms.map(room => room.shortName);

        return (roomShortNames.length === 1) ? (
            `Meeting Room ${roomShortNames}`
        ) : (
            `Meeting Room ${roomShortNames.slice(0, roomShortNames.length - 1).join(', ')} & ${roomShortNames[roomShortNames.length - 1]}`
        );
    }

}
