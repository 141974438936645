import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
// constants
import * as ROUTES from "../../../../../constants/routes";
// screens
import SignInScreen from "../../../../../components/screens/SignInScreen";
import RegisterScreen from "../../../../../components/screens/RegisterScreen";
import ActivationScreen from "../../../../../components/screens/ActivationScreen";
import ForgotPasswordScreen from "../../../../../components/screens/ForgotPasswordScreen";
import getWebTabTitle from "../../../../../utilities/get-web-tab-title";

/**
 * Child of RootStack, contains auth screens (e.g: sign in)
 */
const Stack = createStackNavigator();

const AuthStack = () => (
    <Stack.Navigator
        screenOptions={{ headerShown: false, unmountOnBlur: true }}
    >
        <Stack.Screen
            name={ROUTES.SIGN_IN}
            component={SignInScreen}
            options={{
                title: getWebTabTitle('Sign In')
            }}
        />
        <Stack.Screen
            name={ROUTES.REGISTER}
            component={RegisterScreen}
            options={{
                title: getWebTabTitle('Register')
            }}
        />
        <Stack.Screen
            name={ROUTES.ACTIVATION}
            component={ActivationScreen}
            options={{
                title: getWebTabTitle('Activate')
            }}
        />
        <Stack.Screen
            name={ROUTES.FORGOT_PASSWORD}
            component={ForgotPasswordScreen}
            options={{
                title: getWebTabTitle('Forgot Password')
            }}
        />
    </Stack.Navigator>
);

export default AuthStack;
