import { StyleSheet } from 'react-native';
import { transparentize } from 'polished';


const stylesheet = ({ padding, margin, colors, fonts, borderRadius, shadow, fontWeights, fontSizes }) => StyleSheet.create({
    overviewCardWrapper: {
        marginHorizontal: margin.medium,
        marginBottom: margin.medium
    },

    quote: {
        marginBottom: margin.medium,

        color: transparentize(0.5, colors.light),
        // fontFamily: fonts.bold,
        fontSize: fontSizes.h2,
        fontWeight: fontWeights.bold
    },

    sectionTitle: {
        marginBottom: padding.xSmall
    }
});


export default stylesheet;