import React, { useMemo } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { useTheme, DefaultTheme } from 'styled-components';
// firestore
import Booking from '../../firebase/documents/Booking/Booking';
import Room from '../../firebase/documents/Room/Room';
// atoms
import Card from '../atoms/Card';
import PText from '../atoms/text/PText';
import StrongText from '../atoms/text/StrongText';
import CaretRightIcon from '../atoms/icons/CaretRightIcon';

interface Props {
    booking: Booking;
    expandBooking: (booking: Booking) => void;
    showUser: boolean;
    showCompany: boolean;
    recurringTitle?: boolean;
}

/**
 * BookingsListItem
 */
const BookingsListItem = ({
    booking,
    expandBooking,
    showUser = false,
    showCompany = false,
    recurringTitle = false
}: Props): JSX.Element => {
    const theme = useTheme();
    const styles = stylesheet(theme);

    // memoize list item subtext
    const subText = useMemo<string>(() => {

        let subTextParts = [];

        if (showCompany && booking.company) {

            subTextParts.push(booking.company.name);
        }

        if (showUser && booking.user) {

            subTextParts.push(booking.user.name);
        }

        subTextParts.push(Room.getRoomsListStr(booking.rooms.rooms));

        return subTextParts.join(' • ');
    }, [booking, showCompany, showUser]);

    // render
    return (
        <TouchableOpacity onPress={() => expandBooking(booking)} >
            <Card style={styles.item} padding={false}>
                <View>
                    <PText>{recurringTitle ? booking.recurringLabel : booking.dayTimeStr}</PText>

                    <PText style={styles.bookingRoom}>{subText}</PText>
                </View>

                <CaretRightIcon fill={theme.colors.primary} />
            </Card>
        </TouchableOpacity>
    );
};

const stylesheet = ({ spacing, colors }: DefaultTheme) => StyleSheet.create({
    item: {

        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',

        marginBottom: spacing.small,
        marginHorizontal: spacing.medium,
        padding: spacing.small
    },

    bookingRoom: {

        color: colors.text.light
    }
});

export default BookingsListItem;
