import * as React from "react"
import Svg, { Path } from "react-native-svg"
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';


// components
function StickyNoteIcon({ theme: { iconSizes }, size, ...props }) {
    return (
        <Svg
            viewBox="0 0 448 512"
            width={iconSizes[size]}
            height={iconSizes[size]}

            {...props}
        >
            <Path d="M312 320h136V56c0-13.3-10.7-24-24-24H24C10.7 32 0 42.7 0 56v400c0 13.3 10.7 24 24 24h264V344c0-13.2 10.8-24 24-24zm129 55l-98 98c-4.5 4.5-10.6 7-17 7h-6V352h128v6.1c0 6.3-2.5 12.4-7 16.9z" />
        </Svg>
    )
}

StickyNoteIcon.propTypes = {
    size: PropTypes.string
};

StickyNoteIcon.defaultProps = {
    size: 'medium'
};


// exports
export default withTheme(StickyNoteIcon);
