import React from 'react';
import RNPickerSelect from 'react-native-picker-select';
import { withTheme } from 'styled-components';
import {
    StyleSheet
} from 'react-native';


// styles
const stylesheet = ({ padding, colors, fontSizes, borderRadius, fonts }) => StyleSheet.create({
    viewContainer: {
        // flex: 1

        borderColor: colors.text.light,
        borderRadius,
        borderWidth: StyleSheet.hairlineWidth
    },

    placeholder: {
        color: colors.text.dark,
        fontFamily: fonts.compact.medium
    },

    inputIOS: {
        paddingHorizontal: padding.xSmall,
        paddingVertical: padding.small,

        fontSize: fontSizes.p
    },
    inputAndroid: {
        paddingHorizontal: padding.xSmall,
        paddingVertical: padding.small,

        fontSize: fontSizes.p
    }
});


// components
const SelectInput = ({ theme, ...props }) => {
    const styles = stylesheet(theme);

    return (
        <RNPickerSelect
            style={styles}

            {...props}
        />
    );
}


// exports
export default withTheme(SelectInput);
