import { DateTime } from "luxon";
// firebase
import { firestore } from '../../firebase';
import { collection, query, where, onSnapshot, getDocs, doc } from "firebase/firestore";
import Booking from '../../documents/Booking/Booking';
// enums
import BookingFreqEnum from "../../../enums/BookingFreqEnum";
// utils
import dateMatchesRecurringBooking from "../../../utilities/date-matches-recurring-booking";
import dtSecsSinceDayStart, { secsSinceDayStart } from "../../../utilities/dt-secs-since-day-start";

/**
 * @typedef BookingOptions
 * @type {Object}
 // * @property {boolean} [populatePast=false] - populate past bookings (e.g: user data)
 * @property {boolean} [populateCompany=false] - populate booking company
 * @property {boolean} [populateUser=false] - populate booking user
 * @property {boolean} [populateRooms=false] - populate booking rooms
 * @property {boolean} [includeCancelled=false] - include cancelled bookings
 */

/**
 * Bookings
 */
export default class Bookings {
    /**
     * Bookings collection listener builder
     * @param {function} callback - on snapshot callback
     * @param {BookingOptions} options - booking options
     * @return {function} - detach listener function
     */
    static buildListener (callback, options) {
        options = {
            populateCompany: (options?.populateCompany === true),
            populateUser: (options?.populateUser === true),
            populateRooms: (options?.populateRooms === true),
            includeCancelled: (options?.includeCancelled === true),
        };

        // noinspection JSCheckFunctionSignatures
        let bookingsQuery = collection(firestore, 'bookings').withConverter(Booking.firestoreConverter);

        // includeCancelled option
        if (!options.includeCancelled) {
            bookingsQuery = query(bookingsQuery, where('cancelled', '==', false));
        }

        return onSnapshot(bookingsQuery, async (snapshot) => {
            const bookings = snapshot.docs.map(doc => doc.data());

            // initialise Booking instances
            try {
                // let initBookingCalls = bookings.map(booking => {
                //     // decide whether booking doc should be populated on init
                //     let populate = true;
                //     // if past bookings shouldn't be populated
                //     if (options.populatePast === false) {
                //         populate = !bookingDoc.isInPast;
                //     }
                //
                //     return bookingDoc.init(populate);
                // });
                //
                // await Promise.all(initBookingDocCalls);
                await Promise.all(bookings.map(booking => booking.init(options)));

            } catch (err) {
                // console.error(err);
                throw new Error('some booking docs failed to initialize');
            }

            callback(new Bookings(bookings));
        })
    }

    /**
     * Bookings collection listener builder (custom query)
     * @param bookingsQuery
     * @param {function} callback - callback on snapshot
     * @param {BookingOptions} options - booking options
     * @returns - listener unsubscribe function
     */
    static buildCustomListener (bookingsQuery, callback, options) {

        options = {
            populateCompany: (options?.populateCompany === true),
            populateUser: (options?.populateUser === true),
            populateRooms: (options?.populateRooms === true),
            includeCancelled: (options?.includeCancelled === true)
        };

        if (!options.includeCancelled) {

            bookingsQuery = query(bookingsQuery, where('cancelled', '==', false));
        }

        return onSnapshot(bookingsQuery.withConverter(Booking.firestoreConverter), async (snapshot) => {

            const bookings = snapshot.docs.map(doc => doc.data());

            // initialize Booking instances
            try {
                await Promise.all(bookings.map(booking => booking.init(options)));

            } catch (err) {
                console.error(err);

                throw new Error('some booking docs failed to initialize');
            }

            callback(new Bookings(bookings));
        });

    }

    static bookingsOnDateListeners = (date, bookingsCallback, recurringBookingsCallback) => {

        // const dayStart = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
        // const dayEnd = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
        //
        // return query(collection(firestore, 'bookings'),
        //     where('startAt', '>=', dayStart),
        //     where('startAt', '<=', dayEnd)
        // );


        const dtDayStart = DateTime.fromJSDate(date).startOf('day');
        const dtDayEnd = DateTime.fromJSDate(date).endOf('day');

        const bookingsCollRef = collection(firestore, 'bookings');

        const unsubBookingsListener = Bookings.buildCustomListener(
            query(bookingsCollRef,
                where('startAt', '>=', dtDayStart.toJSDate()),
                where('startAt', '<=', dtDayEnd.toJSDate()),
                where('cancelled', '==', false)
            ).withConverter(Booking.firestoreConverter),
            bookingsCallback,
            {
                populateCompany: true,
                populateRooms: true,
                populateUser: true
            }
        );

        const unsubRecurringBookingsListener = Bookings.buildCustomListener(
            query(bookingsCollRef,
                where('recurring.active', '==', true),
                where('startAt', '<', dtDayStart.toJSDate()),
            ).withConverter(Booking.firestoreConverter),
            (bookings) => {

                recurringBookingsCallback(new Bookings(bookings.bookings.filter((booking) => (
                    // must match same weekday
                    dtDayStart.weekday === booking.dtStartAt.weekday &&
                    (
                        // weekly recurring, so we know it'll clash
                        (booking.recurringFreq === BookingFreqEnum.WEEKLY) ||
                        // check if given date collides with a recurring booking occurrence date
                        dateMatchesRecurringBooking(dtDayStart, booking)
                    ) &&
                    !booking.dtCancelledReoccurrences.some(dt => (dt.startOf('day').equals(dtDayStart)))
                ))));
            },
            {
                populateCompany: true,
                populateRooms: true,
                populateUser: true
            }
        );

        return () => {
            unsubBookingsListener();
            unsubRecurringBookingsListener();
        }
    };

    /**
     * @param {Date} date
     * @param {string[]} [rooms]
     * @return {Promise<Bookings>}
     */
    static getBookingsOnDate = async (date, rooms) => {

        // get room references
        let roomRefs;
        if (rooms && rooms.length) {
            roomRefs = rooms.map(room => doc(firestore, 'rooms', room));
        }

        const dtDayStart = DateTime.fromJSDate(date).startOf('day');
        const dtDayEnd = DateTime.fromJSDate(date).endOf('day');

        const bookingsCollRef = collection(firestore, 'bookings');

        // get booking docs from db
        let bookingDocs = [];
        try {
            const constraints = [
                where('startAt', '>=', dtDayStart.toJSDate()),
                where('startAt', '<=', dtDayEnd.toJSDate()),
                where('cancelled', '==', false)
            ];

            if (roomRefs) {
                constraints.push(where('rooms', 'array-contains-any', roomRefs));
            }

            const snapshot = await getDocs(query(bookingsCollRef, ...constraints).withConverter(Booking.firestoreConverter));

            bookingDocs = snapshot.docs.map(doc => doc.data());

        } catch (err) {
            console.error('failed to build BookingDocs from query...', err);

            throw err;
        }

        // get all recurring bookings
        let recurringBookings = [];
        try {
            const constraints = [
                where('recurring.active', '==', true),
                where('startAt', '<', dtDayStart.toJSDate()),
            ];

            if (roomRefs) {
                constraints.push(where('rooms', 'array-contains-any', roomRefs));
            }

            const snapshot = await getDocs(query(bookingsCollRef, ...constraints).withConverter(Booking.firestoreConverter));

            if (!snapshot.empty) {

                recurringBookings = snapshot.docs.map(doc => doc.data());
            }
        } catch (err) {

            throw new Error('failed to get recurring bookings');
        }

        recurringBookings.forEach((booking) => {

            const dtBookingDayStartAt = booking.dtStartAt.startOf('day');

            // no matter the recurring freq, weekday should always match
            if (dtDayStart.weekday === dtBookingDayStartAt.weekday) {

                if (
                    (booking.recurringFreq === BookingFreqEnum.WEEKLY) ||
                    dateMatchesRecurringBooking(dtDayStart, booking)
                ) {

                    bookingDocs.push(booking);
                }
            }
        });


        return new Bookings(bookingDocs);
    };


    constructor(bookings) {
        this._bookings = bookings;

    }


    // accessor methods (getters)
    // get bookings () { return this._bookings; }

    get pastBookings () {
        return this._bookings
            // filter only past bookings
            .filter(booking => (booking.isInPast))
            // sort past bookings in reverse order (most recent first)
            .sort((bookingA, bookingB) => (bookingB.startAt - bookingA.startAt));
    }

    get ongoingBookings () {
        return this._bookings
            // filter only ongoing bookings
            .filter(booking => (booking.isOngoing))
            // sort ongoing bookings in time order (earliest first)
            .sort((bookingA, bookingB) => (bookingA.startAt - bookingB.startAt));
    }

    get upcomingBookings () {
        return this._bookings
            // filter only upcoming bookings
            .filter(booking => (booking.isUpcoming))
            // sort upcoming bookings in time order (earliest first)
            .sort((bookingA, bookingB) => (bookingA.startAt - bookingB.startAt));
    }

    get recurringBookings () {
        return this._bookings
            .filter(booking => (booking.isRecurring));
    }

    get bookingsByYearMonth () {
        return this._bookings.reduce((bookings, booking) => {
            const year = `year-${booking.startAt.getFullYear()}`;
            const month = `month-${booking.startAt.getMonth()}`;

            if (bookings.hasOwnProperty(year)) {
                // append to list of bookings for this year
                if (bookings[year].hasOwnProperty(month)) {
                    // append to list of bookings for this month
                    bookings[year][month].push(booking);
                } else {
                    // first booking for this month
                    bookings[year][month] = [booking];
                }
            } else {
                // first booking for this year
                bookings[year] = { [month]: [booking] };
            }

            return bookings;
        }, {});
    }

    get bookings () {
        return this._bookings;
    }

    /**
     * get bookings at given time (date ignored)
     *
     * @param {number} hours
     * @param {number} mins
     */
    getBookingsAt = (hours, mins) => {

        const givenSecsSinceDayStart = secsSinceDayStart(hours, mins);

        return this._bookings.filter(({ dtStartAt, dtEndAt }) => {

            const startAtSecsSinceDayStart = dtSecsSinceDayStart(dtStartAt);
            const endAtSecsSinceDayStart = dtSecsSinceDayStart(dtEndAt);

            return (
                startAtSecsSinceDayStart <= givenSecsSinceDayStart &&
                endAtSecsSinceDayStart > givenSecsSinceDayStart
            );
        });
    }

}
