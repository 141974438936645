import React, { useState, useRef, useCallback } from 'react';
import { View, Text, SafeAreaView, StyleSheet, Platform } from "react-native";
import PropTypes from 'prop-types';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useTheme } from 'styled-components';
import { transparentize } from "polished";
import { useNavigation, useFocusEffect } from "@react-navigation/native";
// hooks
import { useMountedState } from "../../../../hooks/react-use";
// constants
import * as ROUTES from '../../../../constants/routes';
// firebase
import Company from '../../../../firebase/documents/Company/Company';
// atoms
import Card from '../../../atoms/Card';
import StyledButton from '../../../atoms/StyledButton';
import FormTextInput from '../../../atoms/FormTextInput';
// atoms - icons
import UserCheckIcon from '../../../atoms/icons/duotone/UserCheckIcon';
// atoms - text
import H2Text from '../../../atoms/text/H2Text';
import H3Text from '../../../atoms/text/H3Text';
import H4Text from '../../../atoms/text/H4Text';
// molecules
import ScreenHeader from '../../../molecules/ScreenHeader';
import OverlayLoadingSpinner from "../../../molecules/OverlayLoadingSpinner";
// organisms
import OverlayModal from '../../../organisms/OverlayModal';
// templates
import MasterTemplate from "../../MasterTemplate";
// utils
import { userAlert } from "../../../../utilities/alert";

const INITIAL_STATE = {
    name: '',
    email: '',

    isSubmitting: false,
    response: null,
    error: null,
};

/**
 * Admin add user screen template
 */
const AdminAddUserScreenTemplate = ({ company }) => {
    const isMounted = useMountedState();
    const styles = useStyles();
    const { colors } = useTheme();
    const navigation = useNavigation();

    // form values state
    const [ name, setName ] = useState(INITIAL_STATE.name);
    const [ email, setEmail ] = useState(INITIAL_STATE.email);
    // form error state
    const [ error, setError ] = useState(INITIAL_STATE.error);
    // form submitting state
    const [ isSubmitting, setIsSubmitting ] = useState(INITIAL_STATE.isSubmitting);
    // form submit response state
    const [ response, setResponse ] = useState(INITIAL_STATE.response);

    // form email input ref
    const emailInputRef = useRef();

    // on form submit
    const handleSubmit = async () => {
        setIsSubmitting(true);

        let responseData;
        try {
            const response = await company.addUser({ name, email });

            responseData = response.data;

        } catch (err) {
            if (isMounted()) {

                console.log(err);

                let errMsg = err.message;
                if (err.code === 'functions/permission-denied') {
                    errMsg = 'You must first connect to QuickBooks from the settings screen before you can create a new company admin user.'
                }

                if (Platform.OS === 'web') {
                    setError(errMsg);
                } else {
                    userAlert('Failed to Add User', errMsg);
                }
            }
        }

        if (isMounted()) {
            if (responseData) {

                setResponse(responseData);
            }

            setIsSubmitting(false);
        }
    };

    // reset state on un-focus
    useFocusEffect(useCallback(() => {

        return () => {
            setName(INITIAL_STATE.name);
            setEmail(INITIAL_STATE.email);
            setIsSubmitting(INITIAL_STATE.isSubmitting);
            setResponse(INITIAL_STATE.response);
            setError(INITIAL_STATE.error);
        }
    }, []));

    // on press go to manage tenants
    const handlePressGoToManageTenants = () => {

        navigation.navigate(ROUTES.ADMIN.USERS.HOME);
    }

    const submitBtnIsDisabled = (!name || !email);

    // render
    return (
        <MasterTemplate>
            <SafeAreaView style={styles.container}>
                <ScreenHeader
                    title='Add User'
                    subtitle={`for company: ${company.name}`}
                    style={styles.screenHeader}
                    backButton={true}
                />

                <KeyboardAwareScrollView>
                    <View style={styles.formContainer}>

                        <Card style={styles.card}>
                            <H4Text>Name*</H4Text>
                            <FormTextInput
                                style={styles.input}
                                value={name}
                                onChangeText={setName}
                                placeholder="User's Name"
                                onSubmitEditing={() => emailInputRef.current.focus()}
                                blurOnSubmit={false}
                                returnKeyType='next'
                            />

                            <H4Text>Email*</H4Text>
                            <FormTextInput
                                ref={emailInputRef}
                                style={[styles.input, styles.inputNoMarginBottom]}
                                value={email}
                                onChangeText={setEmail}
                                autoCapitalize='none'
                                placeholder="User's Email"
                                keyboardType='email-address'
                                textContentType='emailAddress'
                            />
                        </Card>


                        <View style={styles.submitBtnWrapper}>
                            <StyledButton
                                title='Add User'
                                onPress={handleSubmit}
                                disabled={submitBtnIsDisabled}
                            />

                            {error && (
                                <Text style={styles.errorTxt}>{ error }</Text>
                            )}
                        </View>

                    </View>
                </KeyboardAwareScrollView>


                {isSubmitting && (
                    <OverlayLoadingSpinner/>
                )}


                {response && (
                    <OverlayModal contentContainerStyle={styles.modalContentContainer}>
                        <View style={styles.modalIconWrapper}>
                            <UserCheckIcon
                                fill={colors.primary}
                                size='large'
                            />
                        </View>

                        <H2Text style={styles.modalTitle}>
                            Tenant Added!
                        </H2Text>

                        <H3Text style={styles.modalText}>
                            {response.user.name} was successfully added as a user for{' '}
                            {response.company.name} on CREATE Bookings.  A confirmation email should have been sent to
                            them at '{response.user.email}' which contains their activation code (as seen below).
                        </H3Text>

                        <H3Text style={[styles.modalText, styles.modalActivationCode]}>
                            Activation Code: {response.user.activationCode}
                        </H3Text>

                        <StyledButton
                            title='GO TO MANAGE TENANTS'
                            onPress={handlePressGoToManageTenants}
                        />
                    </OverlayModal>
                )}
            </SafeAreaView>
        </MasterTemplate>
    )
};

const useStyles = () => {
    const { spacing, colors } = useTheme();

    return StyleSheet.create({

        container: {

            flex: 1,
        },

        screenHeader: {

            marginBottom: 0,
        },

        formContainer: {

            marginTop: spacing.medium,
            marginBottom: spacing.large
        },

        card: {

            marginHorizontal: spacing.medium,
        },

        input: {
            marginTop: spacing.xSmall,
            marginBottom: spacing.small
        },

        inputNoMarginBottom: {

            marginBottom: 0,
        },

        submitBtnWrapper: {
            marginHorizontal: spacing.medium,
            marginTop: spacing.medium
        },

        errorTxt: {
            marginTop: spacing.xSmall,

            color: colors.error
        },

        modalContentContainer: {
            alignItems: 'center',

            marginHorizontal: spacing.medium
        },

        modalIconWrapper: {
            padding: spacing.medium,
            marginBottom: spacing.large,

            backgroundColor: transparentize(0.8, colors.primary),
            borderRadius: 100
        },

        modalTitle: {

            marginBottom: spacing.small,
        },

        modalText: {
            marginBottom: spacing.large,

            textAlign: 'center',
            color: colors.text.light
        },

        modalActivationCode: {

            color: colors.text.dark,
        },
    });
}

AdminAddUserScreenTemplate.propTypes = {
    company: PropTypes.instanceOf(Company).isRequired
};

export default AdminAddUserScreenTemplate;
