import React, { lazy, Suspense, useMemo } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { useTheme } from 'styled-components';
// types
import { OverlayModalProps } from './types';
// atoms
import Card from '../../atoms/Card';
// molecules
import ModalCloseBtn from '../../molecules/ModalCloseBtn';

const LazyOverlayModal = lazy(() =>
    Platform.OS === 'web' ?
        import('./OverlayModal.web') :
        import('./OverlayModal.native')
);

const OverlayModal = ({
    handlePressClose,
    closeBtnFill,
    contentContainerCard = true,
    contentContainerStyle,
    children,
    blurIntensity = 100,
    style,
    ...props
}: OverlayModalProps): JSX.Element => {
    const styles = useStyles();

    // memoize whether modal has a close button
    const hasCloseBtn = useMemo(() => !!handlePressClose, [handlePressClose]);

    // memoize content (so that it can be re-used by the different types of modal (with/without content container card))
    const content = useMemo(() => (
        <>
            {hasCloseBtn && (
                <ModalCloseBtn
                    fill={closeBtnFill}
                    handlePressClose={handlePressClose}
                />
            )}

            {children}
        </>
    ), [closeBtnFill, handlePressClose, children]);

    // render
    return (
        <Suspense fallback={<View/>}>
            <LazyOverlayModal blurIntensity={blurIntensity} style={[
                styles.backdrop,
                Platform.OS === 'web' && styles.backdropWeb,
                style
            ]} {...props}>
                {contentContainerCard ? (
                    <Card style={[
                        styles.card,
                        Platform.OS === 'web' && styles.cardWeb,
                        contentContainerStyle
                    ]}>
                        { content }
                    </Card>
                ) : (
                    <View style={contentContainerStyle}>
                        { content }
                    </View>
                )}
            </LazyOverlayModal>
        </Suspense>
    );
}

const useStyles = () => {
    const { spacing } = useTheme();

    return StyleSheet.create({

        backdrop: {

            justifyContent: 'center'
        },

        backdropWeb: {

            alignItems: 'center'
        },

        card: {

            marginHorizontal: spacing.medium
        },

        cardWeb: {

            // this is slightly too wide on small/medium mobile screens, but hopefully user is more likely to use the
            // app on mobile anyway.  Responsiveness improvements can be made in future if deemed necessary.
            width: 400
        }
    });
}

export default OverlayModal;
