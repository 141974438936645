import { DarkTheme, DefaultTheme, Theme } from '@react-navigation/native';
import theme from './theme';


// app theme (provided to NavigationContainer)
// https://reactnavigation.org/docs/themes/
const AppThemes = {
    /**
     * @type {Theme}
     */
    Default: {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            primary: theme.primary,
            // background: '',
            // card: '',
            // text: '',
            // border: '',
            // notification: ''
        }
    },

    /**
     * @type {Theme}
     */
    Dark: {
        ...DarkTheme,
        colors: {
            ...DarkTheme.colors,
            // primary: '',
            // background: '',
            // card: '',
            // text: '',
            // border: '',
            // notification: ''
        }
    }
};


// exports
export default AppThemes;