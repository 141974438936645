import React from "react"
import Svg, { Path } from "react-native-svg"
import { DefaultTheme, useTheme } from 'styled-components';

interface Props {
    size?: keyof DefaultTheme['iconSizes'];
    width?: number;
    height?: number;
    fill?: string;
}

/**
 * Plus Icon
 */
const PlusIcon = ({ width, height, size = 'medium', ...props }: Props): JSX.Element => {
    const { iconSizes } = useTheme();

    // render
    return (
        <Svg
            viewBox="0 0 448 512"
            width={width || iconSizes[size]}
            height={height || iconSizes[size]}

            {...props}
        >
            <Path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
        </Svg>
    );
}

export default PlusIcon;
