import React from 'react';
import {
    TouchableOpacity, Text,
    StyleSheet,
    StyleProp, TextStyle, ViewStyle, TouchableOpacityProps
} from 'react-native';
import { useTheme } from 'styled-components';
import { transparentize } from 'polished';

interface Props extends TouchableOpacityProps {
    title: string;
    outline?: boolean;
    disabled?: boolean;
    style?: StyleProp<ViewStyle>;
    titleStyle?: StyleProp<TextStyle>;
}

// constants
const DISABLED_OPACITY = 0.7;

/**
 * Styled Button
 */
const StyledButton = ({
    title,
    outline = false,
    disabled = false,
    titleStyle,
    style,
    ...props
}: Props): JSX.Element => {
    const styles = useStyles();

    // render
    return (
        <TouchableOpacity
            style={[
                styles.btn,
                outline && styles.outlineBtn,
                disabled && styles.disabledBtn,
                disabled && outline && styles.disabledOutlineBtn,
                style
            ]}

            disabled={disabled}
            {...props}
        >
            <Text
                style={[
                    styles.btnText,
                    outline && styles.outlineBtnText,
                    disabled && outline && styles.disabledOutlineBtnText,

                    titleStyle
                ]}
            >
                { title }
            </Text>
        </TouchableOpacity>
    );
};

const useStyles = () => {
    const { borderRadius, colors, spacing, fonts } = useTheme();

    return StyleSheet.create({

        btn: {

            width: '100%',

            padding: spacing.small,

            borderRadius,
            backgroundColor: colors.primary
        },

        disabledBtn: {

            backgroundColor: transparentize(DISABLED_OPACITY, colors.primary),
        },

        outlineBtn: {

            backgroundColor: 'transparent',
            borderColor: colors.primary,
            borderWidth: 2
        },

        disabledOutlineBtn: {

            backgroundColor: 'transparent',
            borderColor: transparentize(DISABLED_OPACITY, colors.primary)
        },

        btnText: {

            color: colors.light,
            textAlign: 'center',
            fontFamily: fonts.compact.medium
        },

        outlineBtnText: {

            color: colors.primary
        },

        disabledOutlineBtnText: {

            color: transparentize(DISABLED_OPACITY, colors.primary)
        }
    });
}

export default StyledButton;
