import { StyleSheet } from 'react-native';
import { transparentize } from 'polished';


const stylesheet = ({ padding, margin, colors, fonts }) => StyleSheet.create({
    contentWrapper: {
        // paddingTop: padding.medium
    },

    formCardHeader: {
        marginTop: margin.medium,
        marginHorizontal: margin.medium,
        paddingBottom: padding.xSmall
    },

    formCardSubheader: {
        marginTop: margin.small,
        marginHorizontal: margin.medium,
        // paddingBottom: padding.small,

        color: colors.text.light,
        fontFamily: fonts.compact.medium
        // textAlign: 'justify'
    },

    formCard: {
        marginHorizontal: margin.medium,
        // marginBottom: margin.medium
    },

    textInput: {
        marginTop: padding.xSmall,
        marginBottom: margin.small
    },

    submitBtnWrapper: {
        marginHorizontal: margin.medium,
        marginTop: margin.large
    },

    errorTxt: {
        marginTop: padding.xSmall,

        color: colors.error
    },

    responseModalContentContainer: {
        alignItems: 'center',

        marginHorizontal: margin.medium
    },

    responseModalIconWrapper: {
        padding: padding.medium,
        marginBottom: margin.large,

        backgroundColor: transparentize(0.8, colors.primary),
        borderRadius: 100
    },

    responseModalText: {
        marginBottom: margin.large,

        textAlign: 'center',
        color: colors.text.light
    }
});

export default stylesheet;