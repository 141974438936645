import React from 'react';
import { View, StyleSheet, TouchableOpacity, Platform } from 'react-native';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { useNavigation } from '@react-navigation/native';
// firebase
import Company from '../../../firebase/documents/Company/Company';
// constants
import * as ROUTES from '../../../constants/routes';
// atoms
import H2Text from '../../atoms/text/H2Text';
import PText from '../../atoms/text/PText';
// atoms - icons
import EditIcon from '../../atoms/icons/EditIcon';
import CaretRightIcon from '../../atoms/icons/CaretRightIcon';
import UserPlusIcon from "../../atoms/icons/UserPlusIcon";
// molecules
import AdminNavModal from '../../molecules/AdminNavModal/AdminNavModal';
import StrongText from "../../atoms/text/StrongText";

/**
 * SectionHeader for each company in the CompanyList component
 *
 * @param title
 * @param onPress
 * @param isExpanded
 * @param company
 * @return {JSX.Element}
 * @constructor
 */
const SectionHeader = ({ title, onPress, isExpanded, company }) => {
    const styles = useStyles();
    const { colors } = useTheme();
    const navigation = useNavigation();

    // edit modal visibility state
    const [ editModalIsShown, setEditModalIsShown ] = React.useState(false);

    // on press edit button
    const handlePressEditBtn = () => {

        if (Platform.OS === 'web') {
            // on web, we treat this action as edit company

            navigation.navigate(ROUTES.ADMIN.COMPANY.EDIT, {
                companyId: company.id
            });
        } else {
            // on native, we show a modal to choose additional options (add user/edit company)

            setEditModalIsShown(true)
        }
    }

    // on hide edit modal
    const handleHideEditModal = () =>
        setEditModalIsShown(false);

    // on press add user btn (web only)
    const handlePressAddUserBtn = () => {

        navigation.navigate(ROUTES.ADMIN.COMPANY.ADD_USER, {
            companyId: company.id
        });
    }

    // render
    return (
        <>
            <TouchableOpacity onPress={onPress} style={[
                styles.wrapper,
                isExpanded && styles.wrapperExpanded
            ]}>
                <View style={[
                    styles.contentWrapper,
                    isExpanded && styles.contentWrapperExpanded
                ]}>
                    <View style={[
                        styles.content,
                        isExpanded && styles.contentExpanded,
                    ]}>
                        <View>
                            <H2Text style={[isExpanded && styles.titleExpanded]}>{ title }</H2Text>

                            <PText style={[
                                styles.subTitle,
                                isExpanded && styles.subTitleExpanded,
                            ]}>
                                {company.room && `Room ${company.room} • `}{ company.quotaHours } Quota Hours
                            </PText>
                        </View>

                        <View style={styles.headerRight}>
                            {isExpanded && (
                                <>
                                    {Platform.OS === 'web' && (
                                        <TouchableOpacity onPress={handlePressAddUserBtn} style={[
                                            styles.actionBtn,
                                            styles.actionBtnWeb
                                        ]}>
                                            <UserPlusIcon fill={colors.light}/>
                                            <StrongText style={styles.actionLabel}>Add User</StrongText>
                                        </TouchableOpacity>
                                    )}

                                    <TouchableOpacity onPress={handlePressEditBtn} style={[
                                        styles.actionBtn,
                                        Platform.OS === 'web' && styles.actionBtnWeb
                                    ]}>
                                        <EditIcon fill={colors.light}/>
                                        {Platform.OS === 'web' && (
                                            <StrongText style={styles.actionLabel}>Edit Company</StrongText>
                                        )}
                                    </TouchableOpacity>
                                </>
                            )}

                            <CaretRightIcon fill={isExpanded ? colors.light : colors.primary}
                                            style={[
                                                isExpanded && styles.caretRightExpanded
                                            ]}/>
                        </View>
                    </View>
                </View>
            </TouchableOpacity>

            {Platform.OS !== 'web' && (
                <AdminNavModal
                    isVisible={editModalIsShown}
                    handleHide={handleHideEditModal}
                    title={company.name}
                    options={[
                        {
                            label: 'Edit Company',
                            path: ROUTES.ADMIN.COMPANY.EDIT,
                            params: { companyId: company.id }
                        },
                        {
                            label: 'Add User',
                            path: ROUTES.ADMIN.COMPANY.ADD_USER,
                            params: { companyId: company.id }
                        },
                    ]}
                />
            )}
        </>
    )
};

const useStyles = () => {
    const { spacing, colors, borderRadius } = useTheme();

    return StyleSheet.create({

        wrapper: {

            borderTopColor: colors.background
        },

        wrapperExpanded: {

            borderTopWidth: spacing.small,
        },

        contentWrapper: {

            marginHorizontal: spacing.small,

            borderColor: colors.primary,

            borderTopLeftRadius: borderRadius,
            borderTopRightRadius: borderRadius
        },

        contentWrapperExpanded: {

            borderTopWidth: 2,
            borderLeftWidth: 2,
            borderRightWidth: 2,
        },

        content: {

            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',

            padding: spacing.small,
            backgroundColor: colors.background,
        },

        contentExpanded: {

            backgroundColor: colors.primary,
        },

        titleExpanded: {

            color: colors.light,
        },

        subTitle: {

            color: colors.secondary,
        },

        subTitleExpanded: {

            color: colors.light,
        },

        headerRight: {

            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        },

        actionBtn: {

            marginRight: Platform.OS === 'web' ? spacing.medium : spacing.small,
        },

        actionBtnWeb: {

            flexDirection: 'row',
            alignItems: 'center',

            padding: spacing.xSmall,
        },

        actionLabel: {

            marginLeft: spacing.xSmall,
            color: colors.light,
        },

        caretRightExpanded: {

            transform: [{ rotate: '90deg' }],
        }
    });
}

SectionHeader.propTypes = {
    title: PropTypes.string.isRequired,
    onPress: PropTypes.func.isRequired,
    isExpanded: PropTypes.bool,
    company: PropTypes.instanceOf(Company)
};

export default SectionHeader;
