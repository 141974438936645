import { DateTime } from 'luxon';
// firebase
import Booking from '../firebase/documents/Booking/Booking';
// enums
import BookingFreqEnum from '../enums/BookingFreqEnum';

/**
 * Check if given Luxon date collides with a recurring booking's occurrence
 */
export default function dateMatchesRecurringBooking(dtStartAt: DateTime, booking: Booking): boolean {

    const dtMonthStart = dtStartAt.startOf('month');
    let dt = dtMonthStart.set({
        day: 1 + ((7 + booking.dtStartAt.weekday) - dtMonthStart.weekday) % 7
    });
    const month = dt.month;
    let days = [];
    while (dt.month === month) {
        days.push(dt.day);
        dt = dt.plus({ weeks: 1 });
    }

    return (
        (booking.recurringFreq === BookingFreqEnum.MONTHLY_FIRST && dtStartAt.day === days[0]) ||
        (booking.recurringFreq === BookingFreqEnum.MONTHLY_SECOND && dtStartAt.day === days[1]) ||
        (booking.recurringFreq === BookingFreqEnum.MONTHLY_THIRD && dtStartAt.day === days[2]) ||
        (booking.recurringFreq === BookingFreqEnum.MONTHLY_FOURTH && dtStartAt.day === days[3]) ||
        (booking.recurringFreq === BookingFreqEnum.MONTHLY_LAST && dtStartAt.day === days[days.length-1])
    );
}
