import React from 'react';
// firebase
import { auth, firestore } from '../firebase/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';
// firebase doc classes
import AuthUser from '../firebase/documents/User/AuthUser';
// session
import AuthUserContext from './context';

/**
 * withAuthentication HOC
 */
// TODO convert to hook?
const withAuthentication = Component => {
    class WithAuthentication extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                user: undefined
            };

            this._unsubAuthStateChangedListener = null;
            this._unsubUserDocListener = null;
        }

        componentDidMount() {
            // this._unsubAuthStateChangedListener =
            //     AuthUser.onAuthStateChanged(
            //         (user) => { this.setState({ user }) },
            //         () => { this.setState({ user: null }) }
            //         );

            this._unsubAuthStateChangedListener =
                onAuthStateChanged(auth, async (authUser) => {
                    if (authUser) {
                        // *** user is authed ***

                        // unsub existing user doc listener (if set)
                        this._unsubUserDocListener && this._unsubUserDocListener();

                        // subscribe a user doc listener
                        const userDocRef =
                            doc(firestore, `users/${authUser.uid}`)
                                .withConverter(AuthUser.firestoreConverter);

                        this._unsubUserDocListener =
                            onSnapshot(userDocRef, async (snapshot) => {
                                if (snapshot.exists()) {
                                    const user = snapshot.data();
                                    await user.init({
                                        populateCompany: true
                                    });

                                    this.setState({ user });
                                } else {
                                    this.setState({ user: null });
                                }
                            });

                    } else {
                        // *** user not authed ***

                        // unsub existing user doc listener (if set)
                        this._unsubUserDocListener && this._unsubUserDocListener();


                        this.setState({ user: null });
                    }
                });
        }

        componentWillUnmount() {
            this._unsubAuthStateChangedListener && this._unsubAuthStateChangedListener();
            this._unsubUserDocListener && this._unsubUserDocListener();
        }

        render() {
            const { user } = this.state;

            return (
                <AuthUserContext.Provider value={user}>
                    <Component {...this.props} />
                </AuthUserContext.Provider>
            );
        }
    }

    return WithAuthentication;
};

export default withAuthentication;
