import * as React from 'react';
import {
    Keyboard
} from 'react-native';


// firebase documents
import User from '../../firebase/documents/User/User';


// templates
import RegisterScreenTemplate from '../templates/screens/auth/RegisterScreen/RegisterScreenTemplate';


// components
class RegisterScreen extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            formData: {
                email: '',
                name: '',
                company: ''
            },

            error: null,
            user: null
        }
    }

    handleChangeTextInput = (field, value) =>
        this.setState(({ formData }) => ({
            formData: {
                ...formData,
                [field]: value
            }
        }));


    handleSubmit = async () => {
        Keyboard.dismiss();
        this.setState({ error: null });

        const { formData: { email, name, company } } = this.state;

        // if (!email)
        //     return this.setState({ error: new Error('Email is required') });


        let user;
        try {
            const response = await User.create({ email, name, company, quotaHours: 0 });
            user = response.data;

        } catch (error) {
            return this.setState({ error });
        }

        this.setState({ user });
    };


    render() {
        const { formData, error, user } = this.state;

        return (
            <RegisterScreenTemplate
                handleSubmit={this.handleSubmit}
                handleChangeTextInput={this.handleChangeTextInput}
                formData={formData}
                error={error}
                user={user}
            />
        );
    }
}


// exports
export default RegisterScreen;
