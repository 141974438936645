import React from 'react';
import { View, StyleSheet } from 'react-native';
import { useTheme } from 'styled-components';
// atoms
import LoadingSpinner from '../../atoms/LoadingSpinner';

/**
 * Loading Tenants in the company list
 *
 * @return {JSX.Element}
 * @constructor
 */
const LoadingTenants = () => {
    const styles = useStyles();

    // render
    return (
        <View style={styles.wrapper}>
            <LoadingSpinner/>
        </View>
    )
};

const useStyles = () => {
    const { spacing, colors, borderRadius } = useTheme();

    return StyleSheet.create({

        wrapper: {

            marginHorizontal: spacing.small,
            paddingBottom: spacing.small,

            borderColor: colors.primary,
            borderWidth: 2,
            borderTopWidth: 0,
            borderBottomLeftRadius: borderRadius,
            borderBottomRightRadius: borderRadius
        }
    });
}

export default LoadingTenants;
