import React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { NavigatorScreenParams } from '@react-navigation/native';
import { useTheme } from "styled-components";
// constants
import * as ROUTES from "../../../../../../../constants/routes";
// auth
import { useAuthUser } from '../../../../../../../Session/context';
// atoms - icons
import HomeIcon from "../../../../../../../components/atoms/icons/HomeIcon";
import CogIcon from "../../../../../../../components/atoms/icons/CogIcon";
import ListIcon from "../../../../../../../components/atoms/icons/ListIcon";
import CalendarPlusIcon from "../../../../../../../components/atoms/icons/CalendarPlusIcon";
import UsersIcon from "../../../../../../../components/atoms/icons/UsersIcon";
import CalendarEditIcon from '../../../../../../../components/atoms/icons/CalendarEditIcon';
// organisms
import TabBar from '../../../../../../../components/organisms/TabBar.web';
// navigation
import HomeStack from "./stacks/home/HomeStack";
import BookingsStack from "./stacks/bookings/BookingsStack";
import AdminBookingsStack from "./stacks/admin-bookings/AdminBookingsStack";
import NewBookingStack from "./stacks/new-booking/NewBookingStack";
import AdminUsersStack from "./stacks/admin-users/AdminUsersStack";
import SettingsStack, {SettingsStackParamList} from './stacks/settings/SettingsStack';

export type MainStackParamList = {
    [ROUTES.HOME.ROOT]: undefined;
    [ROUTES.BOOKINGS.ROOT]: undefined;
    [ROUTES.NEW_BOOKING.ROOT]: undefined;
    [ROUTES.ADMIN.BOOKINGS.ROOT]: undefined;
    [ROUTES.ADMIN.USERS.ROOT]: undefined;
    [ROUTES.SETTINGS.ROOT]: NavigatorScreenParams<SettingsStackParamList>; // this was added experimentally. Pretty sure it has no useful effect on use cases in code.  Nothing special about settings screen compared to the ones above.
    [ROUTES.INTUIT_OAUTH.REDIRECT]: undefined;
}

/**
 * Child of AppStack, contains app screens (e.g: home).  Also configures the web navbar.
 */
const Stack = createBottomTabNavigator<MainStackParamList>();

const MainStack = (): JSX.Element => {
    const theme = useTheme();
    const authUser = useAuthUser();

    // render
    const isAdmin = authUser?.isAdmin || false;

    return (
        <Stack.Navigator
            tabBar={(props) => <TabBar {...props} isAdminUser={isAdmin}/>}
            sceneContainerStyle={{
                marginTop: theme.webTabBarHeight
            }}
            screenOptions={{
                headerShown: false,
                // tabBarActiveTintColor: theme.colors.dark,
                // tabBarInactiveTintColor: theme.colors.darkMuted,
            }}
        >
            {/*HomeStack*/}
            {isAdmin && (
                <Stack.Screen
                    name={ROUTES.HOME.ROOT}
                    component={HomeStack}
                    options={{
                        tabBarIcon: ({ color, size }) => {
                            return <HomeIcon width={size} height={size} fill={color} />
                        },
                        tabBarLabel: 'Home'
                    }}
                />
            )}

            {/*BookingsStack*/}
            <Stack.Screen
                name={ROUTES.BOOKINGS.ROOT}
                component={BookingsStack}
                options={{
                    tabBarIcon: ({ size, color }) => (
                        <ListIcon width={size} height={size} fill={color}/>
                    ),
                    tabBarLabel: 'My Bookings',
                }}
            />

            {/*NewBookingStack*/}
            <Stack.Screen
                name={ROUTES.NEW_BOOKING.ROOT}
                component={NewBookingStack}
                options={{
                    tabBarIcon: ({ size, color }) => (
                        <CalendarPlusIcon width={size} height={size} fill={color}/>
                    ),
                    tabBarLabel: 'New Booking',
                    unmountOnBlur: true,
                }}
            />

            {/*AdminBookingsStack*/}
            {isAdmin && (
                <Stack.Screen
                    name={ROUTES.ADMIN.BOOKINGS.ROOT}
                    component={AdminBookingsStack}
                    options={{
                        tabBarIcon: ({ size, color }) => (
                            <CalendarEditIcon width={size} height={size} fill={color}/>
                        ),
                        tabBarLabel: 'Manage Bookings'
                    }}
                />
            )}

            {/*AdminUsersStack*/}
            {isAdmin && (
                <Stack.Screen
                    name={ROUTES.ADMIN.USERS.ROOT}
                    component={AdminUsersStack}
                    options={{
                        tabBarIcon: ({ size, color }) => (
                            <UsersIcon width={size} height={size} fill={color}/>
                        ),
                        tabBarLabel: 'Manage Tenants',
                        unmountOnBlur: true,
                    }}
                />
            )}

            {/*SettingsStack*/}
            <Stack.Screen
                name={ROUTES.SETTINGS.ROOT}
                component={SettingsStack}
                options={{
                    tabBarIcon: ({ color, size }) => {
                        return <CogIcon width={size} height={size} fill={color} />
                    },
                    unmountOnBlur: true,
                }}
            />
        </Stack.Navigator>
    );
}

export default MainStack;
