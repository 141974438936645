import React from "react"
import Svg, { Path } from "react-native-svg"
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

/**
 * Edit Icon
 * @param width
 * @param height
 * @param size
 * @param props
 * @return {JSX.Element}
 */
const EditIcon = ({width, height, size, ...props}) => {
    const { iconSizes } = useTheme();

    // render
    return (
        <Svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 576 512"
             width={width || iconSizes[size]}
             height={height || iconSizes[size]}

             {...props}
        >
            <Path
                d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"
            />
        </Svg>
    )
};

EditIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    size: PropTypes.string
};

EditIcon.defaultProps = {
    size: 'medium'
};


export default EditIcon;
