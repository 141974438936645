import React, { useContext } from 'react';

const AuthUserContext = React.createContext(null);

export default AuthUserContext;

export const withAuthUser = Component => props => (
    <AuthUserContext.Consumer>
        { authUser => <Component {...props} authUser={authUser} /> }
    </AuthUserContext.Consumer>
);

/**
 * Hook to consume session context, giving components access to the currently authorised user.
 *
 * @return {AuthUser}
 */
export function useAuthUser() {
    return useContext(AuthUserContext);
}
