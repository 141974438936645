import React from 'react';
import {
    TouchableOpacity,
    Text, StyleSheet
} from "react-native";
import { useTheme } from 'styled-components';
import PropTypes from 'prop-types';


// components
const AuthSubmitBtn = ({ theme, title, onPress, outline, style, ...props }) => {
    const styles = useStyles();

    // render
    return (
        <TouchableOpacity onPress={onPress} style={[
            styles.touchable,
            !outline && styles.touchableNoOutline,
            outline && styles.touchableOutline,
            style,
        ]} {...props}>
            <Text style={[
                styles.text,
                outline && styles.outlineText
            ]}>
                { title }
            </Text>
        </TouchableOpacity>
    );
}

const useStyles = () => {
    const { spacing, borderRadius, colors, shadow, fontSizes, fontWeights } = useTheme();

    return StyleSheet.create({

        touchable: {

            padding: spacing.small,

            borderRadius: borderRadius,
            backgroundColor: colors.dark,
        },

        touchableNoOutline: {

            ...shadow.small,
        },

        touchableOutline: {

            backgroundColor: 'transparent',
            borderColor: colors.dark,
            borderWidth: 2,
        },

        text: {

            fontSize: fontSizes.h3,
            fontWeight: fontWeights.bold,
            textAlign: 'center',
            color: colors.light
        },

        outlineText: {

            color: colors.dark
        }
    });
}

AuthSubmitBtn.propTypes = {
    title: PropTypes.string.isRequired,
    onPress: PropTypes.func.isRequired,
    outline: PropTypes.bool
};

AuthSubmitBtn.defaultProps = {
    outline: false
};

export default AuthSubmitBtn;
