import { StyleSheet } from 'react-native';
import { transparentize } from 'polished';


const stylesheet = ({ colors, margin }) => StyleSheet.create({
    modalContentContainer: {
        backgroundColor: colors.primary
    },

    title: {
        color: colors.light,
        marginBottom: margin.medium
    },


    activationInfo: {
        marginBottom: margin.small
    },


    footer: {
        flexDirection: 'row',

        marginTop: margin.medium
    },

    footerBtn: {
        width: 100,
        marginRight: margin.small,

        borderColor: colors.light,
    },

    footerBtnText: {
        color: colors.light
    }
});

const infoItemStylesheet = ({ margin, colors, padding, iconSizes }) => StyleSheet.create({
    infoItem: {
        flexDirection: 'row',
        marginBottom: margin.small
    },

    iconWrapper: {
        width: iconSizes.medium
    },

    value: {
        color: colors.light,
        // marginLeft: padding.small
    },

    infoValueHidden: {
        // paddingHorizontal: padding.xSmall,

        // backgroundColor: transparentize(0.6, colors.light),
        // borderRadius,
        color: transparentize(0.5, colors.light)
    },

    valueSelectable: {
        paddingHorizontal: padding.xSmall,

        backgroundColor: transparentize(0.8, colors.light)
    }
});


export default stylesheet;
export { infoItemStylesheet };