import { initializeApp } from 'firebase/app';
import { initializeFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import firebaseJson from '../firebase.json';

// firebase config
const firebaseConfig = {
    apiKey: "AIzaSyAAjTqq_WAhbTkRshMpVy8Ykzvp30U-394",
    authDomain: "create-business-hub-bms.firebaseapp.com",
    databaseURL: "https://create-business-hub-bms.firebaseio.com",
    projectId: "create-business-hub-bms",
    storageBucket: "create-business-hub-bms.appspot.com",
    messagingSenderId: "111435034895",
    appId: "1:111435034895:web:d39129eb3426cb72c3ce7a",
    measurementId: "G-N1HD81YPHZ"
};

// initialize the firebase app
const firebaseApp = initializeApp(firebaseConfig); // TODO no longer checking apps.length, is this okay??

// initialize firebase services
const firestore = initializeFirestore(firebaseApp, {});
const storage = getStorage(firebaseApp);
const auth = getAuth(firebaseApp);
// HTTPS functions must be deployed in us-central1 to be accessible by Firebase Hosting
const functions = getFunctions(firebaseApp, "us-central1");

if (__DEV__) {
    // connect firebase emulators

    connectFunctionsEmulator(functions, 'localhost', firebaseJson.emulators.functions.port);
    connectFirestoreEmulator(firestore, 'localhost', firebaseJson.emulators.firestore.port);
    connectAuthEmulator(auth, `http://127.0.0.1:${firebaseJson.emulators.auth.port}`, {
        disableWarnings: true,
    });
    connectStorageEmulator(storage, 'localhost', firebaseJson.emulators.storage.port);
}
// firestore.settings({ host: 'localhost:8081', ssl: false });

// export initialized firebase instance
export { firestore, storage, auth, functions };
