import React, {useState, useMemo, useCallback, useRef} from 'react';
import { useFocusEffect } from '@react-navigation/native';
// hooks
import { useMountedState } from '../../hooks/react-use';
// data
import Quotes from "../../data/quotes.json";
// firestore collections
import Bookings from '../../firebase/collections/Bookings/Bookings';
// templates
import HomeScreenTemplate from '../templates/screens/HomeScreen/HomeScreenTemplate';

const INITIAL_STATE = {
    allBookingsToday: null,
    quote: null
}

/**
 * Admin Home Screen
 */
const HomeScreen = (): JSX.Element => {
    const isMounted = useMountedState();

    // bookings today refs
    const bookingsToday = useRef<null | Bookings>(null);
    const recurringBookingsToday = useRef<null | Bookings>(null);

    // all bookings today state (normal bookings + recurring)
    const [allBookingsToday, setAllBookingsToday] = useState<null | Bookings>(INITIAL_STATE.allBookingsToday);

    // fetch bookings for today
    useFocusEffect(useCallback(() => {

        const updateAllBookingsToday = () => {

            let bookings = [];

            if (bookingsToday.current) {
                bookings.push(...bookingsToday.current.bookings);
            }
            if (recurringBookingsToday.current) {
                bookings.push(...recurringBookingsToday.current.bookings);
            }

            setAllBookingsToday(new Bookings(bookings));
        }

        let unsubListeners: () => void;
        try {

            unsubListeners = Bookings.bookingsOnDateListeners(new Date(),
                (bookings) => {

                    if (isMounted()) {
                        bookingsToday.current = bookings;
                        updateAllBookingsToday();
                    }
                },
                (recurringBookings) => {

                    if (isMounted()) {
                        recurringBookingsToday.current = recurringBookings;
                        updateAllBookingsToday();
                    }
                }
            );

        } catch (err) {

            if (isMounted()) {

                bookingsToday.current = null;
                recurringBookingsToday.current = null;
            }
        }

        return () => {
            unsubListeners();
        }
    }, []));

    // memoize quote
    const quote = useMemo(() => {

        const startFromTime = +new Date(2022, 3, 1);
        const nowTime = +new Date();
        const dayNum = Math.floor((nowTime - startFromTime) / 1000 / 60 / 60 / 24);
        const quoteNum = dayNum % [...Quotes].length;
        return Quotes[quoteNum];
    }, []);

    // render
    return (
        <HomeScreenTemplate bookingsToday={allBookingsToday} quote={quote}/>
    );
}

export default HomeScreen;
