// constants
import * as DATES from '../../../constants/dates';
// utils
import getStartEndTimes from '../../../utilities/get-start-end-times';
// firebase
import Room from '../../../firebase/documents/Room/Room';
// types
import type { Time } from '../../../hooks/use-new-booking-flow';

// constant exports
export const DEFAULT_SUBHEADER = 'Choose Room(s)...';

interface SubheaderData {
    chosenRooms: Room[];
    date: Date;
    times: Time[];
}

/**
 * Get new booking flow subheader util
 *
 * @param slideIndex - current slide index
 * @param chosenRooms
 * @param date
 * @param times
 * @param ignoreSlideIndex
 */
const getSubheader = (
    slideIndex: number,
    { chosenRooms, date, times }: SubheaderData,
    ignoreSlideIndex = false
): string => {

    let subHeader: string;

    if (chosenRooms.length === 0) {

        // room slide
        subHeader = DEFAULT_SUBHEADER;
    } else {

        // a room has been chosen
        subHeader = Room.getRoomsListStr(chosenRooms);

        // room chosen, but still on room slide
        if (!ignoreSlideIndex && slideIndex === 0) {
            subHeader += ' • Choose Date...';
        }


        if ((slideIndex >= 1 || ignoreSlideIndex) && !!date) {
            // a date has been chosen

            const month = DATES.SHORT_MONTHS[date.getMonth()];
            const day = date.getDate();

            subHeader += ` • ${month} ${day}`;

            if (!times.length) {
                // time not yet chosen

                subHeader += ' • Choose Time...';
            } else {

                // duplicate times without reference
                const timesCopy = JSON.parse(JSON.stringify(times));

                // get start/end times of chosen times
                const { startAt, endAt } = getStartEndTimes(date, timesCopy);

                // prepend 0 if < 10
                const startAtMins = ('0' + startAt.mins).slice(-2);
                const endAtMins = ('0' + endAt.mins).slice(-2);

                subHeader += ` • ${startAt.hours}:${startAtMins} - ${endAt.hours}:${endAtMins}`;
            }
        }
    }

    return subHeader;
};

export default getSubheader;
