import React from 'react';
import Svg, { Path } from "react-native-svg";
import { DefaultTheme, useTheme } from 'styled-components';

interface Props {
    size?: keyof DefaultTheme['iconSizes'];
    width?: number;
    height?: number;
    fill?: string;
}

/**
 * Bars Duotone Icon (Hamburger menu)
 */
const BarsIcon = ({ width, height, size = 'medium', ...props }: Props): JSX.Element => {
    const { iconSizes } = useTheme();

    // render
    return (
        <Svg viewBox="0 0 448 512"
             width={width || iconSizes[size]}
             height={height || iconSizes[size]}
             {...props}
        >
            <Path
                d="M16 288h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16z"
                opacity={0.6}
            />
            <Path
                d="M432 384H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H16A16 16 0 0 0 0 80v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16z"
            />
        </Svg>
    );
}

export default BarsIcon;
