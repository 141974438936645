import React, { useMemo, useState } from 'react';
import { SectionList, View, StyleProp, ViewStyle, StyleSheet } from 'react-native';
import { DefaultTheme, useTheme } from 'styled-components';
// constants
import { MONTHS } from '../../constants/dates';
// firebase
import Bookings from '../../firebase/collections/Bookings/Bookings';
import Booking from '../../firebase/documents/Booking/Booking';
// atoms
import H2Text from '../atoms/text/H2Text';
import H3Text from '../atoms/text/H3Text';
// molecules
import BookingsListItem from '../molecules/BookingsListItem';
import ExpandedBooked from './ExpandedBooking';

interface Props {
    bookings: Bookings;
    showUser?: boolean;
    showCompany?: boolean;
    showSectionHeaders?: boolean;
    expandedBookingProps?: {
        showCompany?: boolean;
        showUser?: boolean;
    };
    style?: StyleProp<ViewStyle>;
    overrideSectionsTitle?: string;

}

const INITIAL_STATE = {
    expandedBooking: null
}

/**
 * BookingsList
 *
 * @param bookings
 * @param showUser
 * @param showCompany
 * @param showSectionHeaders
 * @param expandedBookingProps
 * @param style
 * @param overrideSectionsTitle - if provided, bookings will all be placed into a single section with this prop value
 *  as its title.
 * @param props
 */
const BookingsList = ({
    bookings,
    showUser = false,
    showCompany = false,
    showSectionHeaders = true,
    expandedBookingProps,
    style,
    overrideSectionsTitle,
    ...props
}: Props): JSX.Element => {
    const theme = useTheme();
    const styles = stylesheet(theme);

    // booking to show in overlay modal, hidden if null
    const [expandedBooking, setExpandedBooking] = useState<null | Booking>(INITIAL_STATE.expandedBooking);

    const expandBooking = (booking: Booking): void => setExpandedBooking(booking);

    const closeExpandedBooking = () => setExpandedBooking(INITIAL_STATE.expandedBooking);

    // memoize sections to display (separating bookings by month)
    const sections = useMemo(() => {

        const bookingsByYearMonth = bookings?.bookingsByYearMonth;

        if (!bookingsByYearMonth) {

            return [];
        }

        if (overrideSectionsTitle) {
            return [{
                title: overrideSectionsTitle,
                key: 'recurring',
                data: bookings.bookings
            }];
        }

        return Object.keys(bookingsByYearMonth).reduce((sections, year) => {
            const rawYear = year.split('-')[1];

            Object.keys(bookingsByYearMonth[year]).forEach(month => {
                const rawMonth = month.split('-')[1];

                sections.push({
                    title: `${MONTHS[rawMonth]} ${rawYear}`,
                    key: `${month}-${year}`,
                    data: bookingsByYearMonth[year][month]
                });
            });

            return sections;
        }, []);
    }, [bookings]);

    // render
    return (
        <>
            <SectionList
                style={style}
                sections={sections}
                keyExtractor={booking => booking.id}

                renderSectionHeader={({ section: { title } }) => (
                    showSectionHeaders && (
                        <H2Text style={styles.sectionHeaderText}>
                            { title }
                        </H2Text>
                    )
                )}
                renderItem={({ item: booking }) => (
                    <BookingsListItem
                        booking={booking}
                        expandBooking={expandBooking}
                        showUser={showUser}
                        showCompany={showCompany}
                        recurringTitle={!!overrideSectionsTitle && booking.isRecurring}
                    />
                )}

                SectionSeparatorComponent={({ leadingItem, trailingItem }) => (
                    showSectionHeaders && (
                        <View>
                            { trailingItem && <View style={styles.sectionSeparator} /> }
                            { leadingItem && <View style={styles.sectionSeparator} /> }
                        </View>
                    )
                )}

                ListEmptyComponent={() => (
                    <H3Text style={styles.listEmptyText}>
                        No bookings found.
                    </H3Text>
                )}

                {...props}
            />

            {expandedBooking && (
                <ExpandedBooked
                    booking={expandedBooking}
                    handleClose={closeExpandedBooking}
                    ignoreRecurring={!overrideSectionsTitle}
                    {...expandedBookingProps}
                />
            )}
        </>
    );
}

const stylesheet = ({ spacing, colors }: DefaultTheme) => StyleSheet.create({

    sectionHeaderText: {

        paddingHorizontal: spacing.medium,
        backgroundColor: colors.background
    },

    sectionSeparator: {

        marginBottom: spacing.medium,
    },

    listEmptyText: {

        marginHorizontal: spacing.medium,
    }
});

export default BookingsList;
