import * as React from "react"
import Svg, { Path } from "react-native-svg"
import { withTheme } from 'styled-components';


// component
function HomeIcon({ theme, ...props }) {
    return (
        <Svg
            viewBox="0 0 576 512"
            width={theme.iconSizes.medium}
            height={theme.iconSizes.medium}
            // fill={theme.colors.dark}
            fill='#8E8E8F'
            {...props}
        >
            <Path d="M570.69 236.28L512 184.45V48a16 16 0 00-16-16h-64a16 16 0 00-16 16v51.69L314.75 10.31a39.85 39.85 0 00-53.45 0l-256 226a16 16 0 00-1.21 22.6L25.5 282.7a16 16 0 0022.6 1.21L277.42 81.63a16 16 0 0121.17 0L527.91 283.9a16 16 0 0022.6-1.21l21.4-23.82a16 16 0 00-1.22-22.59zM288 115L69.47 307.71c-1.62 1.46-3.69 2.14-5.47 3.35V496a16 16 0 0016 16h128a16 16 0 0016-16V368a16 16 0 0116-16h96a16 16 0 0116 16v128a16 16 0 0016 16h128a16 16 0 0016-16V311.1c-1.7-1.16-3.72-1.82-5.26-3.2z" />
        </Svg>
    )
}


// exports
export default withTheme(HomeIcon);
