import React, { lazy, Suspense } from 'react';
import { Platform, View } from 'react-native';
// types
import type { NewBookingFlowProps } from './types';

const LazyNewBookingFlow = lazy(() =>
    Platform.OS === 'web' ?
        import('./NewBookingFlow.web') :
        import('./NewBookingFlow.native')
);

const NewBookingFlow = (props: NewBookingFlowProps): JSX.Element => (
    <Suspense fallback={<View/>}>
        <LazyNewBookingFlow {...props}/>
    </Suspense>
);

export default NewBookingFlow;
