import React from 'react';
// templates
import EditProfileScreenTemplate from '../templates/screens/EditProfileScreenTemplate';
// auth
import { useAuthUser } from '../../Session/context';

/**
 * Edit Profile screen
 */
const EditProfileScreen = (): JSX.Element => {
    const authUser = useAuthUser();

    // render
    return (
        <EditProfileScreenTemplate authUser={authUser}/>
    )
}

export default EditProfileScreen;
