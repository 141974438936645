import React from "react";
import { SafeAreaView, ScrollView, StyleSheet } from 'react-native';
import { useTheme } from 'styled-components';
import { useNavigation } from '@react-navigation/native';
// firebase
import AuthUser from '../../../firebase/documents/User/AuthUser';
// atoms
// molecules
import ScreenHeader from '../../molecules/ScreenHeader';
import { userAlert } from "../../../utilities/alert";
import MasterTemplate from "../MasterTemplate";
import EditUserForm from '../../organisms/EditUserForm';
// constants
import * as ROUTES from "../../../constants/routes";

interface Props {
    authUser: AuthUser;
}

/**
 * Edit User Screen Template
 */
const EditProfileScreenTemplate = ({ authUser }: Props): JSX.Element => {
    const navigation = useNavigation();
    const styles = useStyles();

    const handleEditSuccess = () => {

        userAlert(
            'Profile Updated',
            'Your details have been successfully updated',
            // @ts-ignore
            () => navigation.navigate(ROUTES.SETTINGS.HOME)
        );
    }

    // render
    return (
        <MasterTemplate>
            <SafeAreaView style={{ flex: 1 }}>
                <ScreenHeader
                    title='Edit Profile'
                    style={{ marginBottom: 0 }}
                    backButton={true}
                />

                <ScrollView style={styles.contentWrapper}>
                    <EditUserForm user={authUser} onSuccess={handleEditSuccess}/>
                </ScrollView>
            </SafeAreaView>
        </MasterTemplate>
    )
};

const useStyles = () => {
    const { spacing } = useTheme();

    return StyleSheet.create({
        contentWrapper: {
            paddingVertical: spacing.medium
        },

        disableTenantBtnWrapper: {
            padding: spacing.medium
        }
    });
}

export default EditProfileScreenTemplate;
