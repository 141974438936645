import React from 'react';
import { useRoute } from "@react-navigation/native";
// templates
import ActivationScreenTemplate from '../templates/screens/auth/ActivationScreen/ActivationScreenTemplate';

/**
 * Activation Screen
 *
 * @returns {JSX.Element}
 */
const ActivationScreen = () => {
    const { params } = useRoute();

    // render
    return (
        <ActivationScreenTemplate code={params?.c}/>
    );
}


export default ActivationScreen;
