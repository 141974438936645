import React, { useRef } from 'react';
import PropTypes from 'prop-types';
// atoms
import AuthTextInput from '../../../../atoms/AuthTextInput';
// templates
import AuthScreenTemplate from '../AuthScreenTemplate';

/**
 * Sign in Screen Template
 *
 * @returns {JSX.Element}
 */
const SignInScreenTemplate = ({ handleSubmit, formData: { email, password }, handleChangeTextInput, isSubmitting, error }) => {
    // input refs
    const passwordInputRef = useRef();

    // render
    return (
        <AuthScreenTemplate
            mode='sign_in'
            handleSubmit={handleSubmit}
            error={error}
            isSubmitting={isSubmitting}
        >
            <AuthTextInput
                value={email}
                onChangeText={email => handleChangeTextInput('email', email)}
                onSubmitEditing={() => passwordInputRef.current.focus()}
                blurOnSubmit={false}
                autoCompleteType='email'
                keyboardType='email-address'
                returnKeyType='next'
                textContentType='emailAddress'
                placeholder='Email Address'
            />

            <AuthTextInput
                ref={passwordInputRef}
                value={password}
                onChangeText={password => handleChangeTextInput('password', password)}
                onSubmitEditing={handleSubmit}
                autoCompleteType='password'
                returnKeyType='go'
                secureTextEntry={true}
                textContentType='password'
                placeholder='Password'
            />
        </AuthScreenTemplate>
    );
};

SignInScreenTemplate.propTypes = {
    // form
    formData: PropTypes.shape({
        email: PropTypes.string,
        password: PropTypes.string
    }).isRequired,
    formError: PropTypes.string,
    isSubmitting: PropTypes.bool,

    // handlers
    handleChangeTextInput: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired
};


// exports
export default SignInScreenTemplate;
