import React from 'react';
import { StyleProp, StyleSheet, Text, TextStyle } from 'react-native';
import { DefaultTheme, useTheme } from 'styled-components';

interface Props {
    children: string;
    style?: StyleProp<TextStyle>;
}

// components
const H2Text = ({ style, children }: Props): JSX.Element => {
    const theme = useTheme();
    const styles = stylesheet(theme);

    // render
    return (
        <Text style={[styles.text, style]}>
            {children}
        </Text>
    );
}

const stylesheet = ({ fontSizes, colors }: DefaultTheme) => StyleSheet.create({

    text: {

        fontSize: fontSizes.h2,
        color: colors.text.dark,
    }
});

export default H2Text;
