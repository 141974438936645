import React, { Component } from 'react';
// firebase
import Bookings from '../../../firebase/collections/Bookings/Bookings';
// templates
import BookingsScreenTemplate from "../../templates/screens/BookingsScreenTemplate";

/**
 * Admin Bookings Screen
 */
class AdminBookingsScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bookings: null,

            viewMode: 0
        };

        this._unsubscribeFocusListener = null;
        this._unsubscribeBlurListener = null;

        this._unsubscribeBookingsListener = null;
    }

    componentDidMount() {
        const { navigation } = this.props;

        // init bookings listener on screen focus
        this._unsubscribeFocusListener =
            navigation.addListener('focus', () => {
                this._initBookingsListener();
            });

        // unsubscribe bookings listener on screen blur
        this._unsubscribeBlurListener =
            navigation.addListener('blur', () => {
                this._unsubscribeBookingsListener && this._unsubscribeBookingsListener();
            });
    }


    componentWillUnmount() {
        // unsubscribe screen focus and blur listeners
        this._unsubscribeFocusListener && this._unsubscribeFocusListener();
        this._unsubscribeBlurListener && this._unsubscribeBlurListener();

        // unsubscribe bookings listener
        this._unsubscribeBookingsListener && this._unsubscribeBookingsListener();
    }


    _initBookingsListener = () => {
        try {
            this._unsubscribeBookingsListener =
                Bookings.buildListener(this._onBookingsSnapshot, {
                    populateCompany: true,
                    populateRooms: true,
                    populateUser: true
                });

        } catch (err) {
            this.setState({ bookings: null });
        }
    };

    _onBookingsSnapshot = (bookings) =>
        this.setState({ bookings });


    // *** UI Handlers ***
    handleSwitchViewMode = (newViewMode) =>
        this.setState(({ viewMode }) => (
            newViewMode !== viewMode ? { viewMode: newViewMode } : null
        ));


    render() {
        const { bookings, viewMode } = this.state;

        return (
            <BookingsScreenTemplate
                bookings={bookings}
                viewMode={viewMode}
                handleSwitchViewMode={this.handleSwitchViewMode}
                titlePrefix='Manage'
                showCompany={true}
            />
        );
    }
}

export default AdminBookingsScreen;
