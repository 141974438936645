// firebase
import { storage } from '../../firebase';
import { refEqual } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
// superclass
import FirestoreDoc from '../FirestoreDoc';

const GET_COVER_TIMEOUT = 1000;

/**
 * Room entity
 */
export default class Room extends FirestoreDoc {

    /**
     * Check if array of rooms includes given room
     *
     * @param {Room[]} rooms
     * @param {Room} targetRoom
     */
    static arrayIncludes = (rooms, targetRoom) => {
        // return true if at least 1 room has a ref equal to target room
        return rooms.some((room) => refEqual(room.ref, targetRoom.ref));
    }


    /**
     * Get string list of room short names
     * @param {Room[]} rooms
     * @returns {string} - e.g. "Room 1, 2 & 3"
     */
    static getRoomsListStr = (rooms) => {
        const roomShortNames = rooms.map(room => room.shortName);
        const sortedShortNames = roomShortNames.sort();

        let roomsStr = 'Room';

        if (roomShortNames.length === 1) {
            roomsStr += ` ${sortedShortNames[0]}`;

        } else {
            roomsStr += `s ${sortedShortNames.slice(0, sortedShortNames.length - 1).join(', ')}`;
            roomsStr += ` & ${sortedShortNames[sortedShortNames.length - 1]}`;
        }

        return roomsStr;
    }




    static firestoreConverter = {
        fromFirestore: (snapshot, options) => {
            const { createdAt, updatedAt, ...data } = snapshot.data(options);

            return new Room(
                snapshot.ref,
                snapshot.id,

                createdAt,
                updatedAt,

                data
            )
        }
    };



    constructor(ref, id, createdAt, updatedAt, { name, shortName, capacity, smartBoard, features, cover, connectedTo }) {
        super(ref, id, createdAt, updatedAt);

        this._name = name;
        this._shortName = shortName;
        this._capacity = capacity;
        this._smartBoard = smartBoard;
        this._features = features;
        this._coverURI = cover;
        this._connectedToRefs = connectedTo || [];

        this._cover = null;

        this._hasConnectingRooms = (this._connectedToRefs.length > 0);
        this._isInitialized = false;
    }


    /**
     * Initialize room instance
     * @param {boolean} [populateCover = false] - Whether to populate rooms cover image
     */
    init = async (populateCover) => {
        if (!this._isInitialized) {
            if (populateCover) {
                if (this._coverURI) {
                    // get download url for cover
                    const coverRef = ref(storage, this._coverURI);

                    let cover;
                    try {
                        cover = await new Promise((resolve, reject) => {

                            // set a timeout as firebase getDownloadUrl sometimes hangs indefinitely
                            const timeout = setTimeout(() => {

                                // request was hanging, start a new timeout in case 2nd attempt also hangs
                                const timeout2 = setTimeout(() => {

                                    // hung for a 2nd time, error.
                                    reject(new Error('timed out'));
                                }, GET_COVER_TIMEOUT);

                                // try to get download url again, clearing 2nd timeout on success
                                getDownloadURL(coverRef).then(url => {
                                    clearTimeout(timeout2);
                                    resolve(url);
                                }).catch(reject);
                            }, GET_COVER_TIMEOUT);

                            // attempt to get download url, clearing timeout on success.
                            getDownloadURL(coverRef).then(url => {
                                clearTimeout(timeout);
                                resolve(url);
                            }).catch(reject);
                        });

                    } catch (err) {
                        console.error('failed to get cover image for room...', err);

                        // minor error, no action necessary
                    }

                    if (cover) {

                        this._cover = cover;
                    }
                }
            }

            this._isInitialized = true;
        }
    };



    /**
     * Check if this room is connected to given room
     * @param {Room} room
     */
    isConnectedTo = (room) => {
        if (!this._hasConnectingRooms || !room.hasConnectingRooms)
            // at least 1 of the rooms has no connecting rooms
            return false;

        // both rooms have connecting rooms

        // returns true if this room is connected to target room
        return this._connectedToRefs.some((connectingRoomRef) => refEqual(connectingRoomRef, room.ref));
    }


    // accessor methods (getters)
    get name() { return this._name; };
    get shortName() { return this._shortName; };
    get capacity() { return this._capacity; };
    get smartBoard() { return this._smartBoard; };

    /**
     * @return {string[]}
     */
    get features() { return this._features; };

    get cover() { return this._cover; };

    get hasConnectingRooms() { return this._hasConnectingRooms; };

}
