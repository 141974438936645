import  React from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
// session
import { useAuthUser } from "../../../Session/context";
// firebase
import User from '../../../firebase/documents/User/User';
import Company from '../../../firebase/documents/Company/Company';
// atoms
import Card from '../../atoms/Card';
import StrongText from '../../atoms/text/StrongText';
import PText from '../../atoms/text/PText';
import CaretRightIcon from '../../atoms/icons/CaretRightIcon';

/**
 * User row in the CompanyList
 *
 * @param {User} user
 * @param {Company} company
 * @param onPress
 * @param isFirstUserInList
 * @param isLastUserInList
 * @return {JSX.Element}
 * @constructor
 */
const UserItem = ({ user, company, onPress, isFirstUserInList, isLastUserInList }) => {
    const theme = useTheme();
    const styles = useStyles();
    const authUser = useAuthUser();

    // flags
    const isOwner = (company.ownerRef.id === user.id);
    const isDisabled = user.disabled;
    const isAuthUser = (user.id === authUser.id);

    const subText = [
        ...(isAuthUser ? ['You'] : []),
        ...(isOwner ? ['Company Admin'] : []),
        ...(isDisabled ? ['Disabled'] : [])
    ].join(' • ');

    // render
    return (
        <View style={[
            styles.wrapper,
            isFirstUserInList && styles.firstItemWrapper,
            isLastUserInList && styles.lastItemWrapper
        ]}>

            <TouchableOpacity onPress={onPress}>
                <Card padding={false} style={[
                    styles.item,
                    user.disabled && styles.itemDisabled
                ]}>
                    <View>
                        <PText>{ user.name }</PText>

                        {subText ? (
                            <PText style={styles.subText}>{ subText }</PText>
                        ) : null}
                    </View>

                    <CaretRightIcon fill={theme.colors.primary}/>
                </Card>
            </TouchableOpacity>
        </View>
    );
};

const useStyles = () => {
    const { spacing, colors, borderRadius } = useTheme();

    return StyleSheet.create({

        wrapper: {

            marginHorizontal: spacing.small,

            borderLeftWidth: 2,
            borderLeftColor: colors.primary,
            borderRightWidth: 2,
            borderRightColor: colors.primary
        },

        firstItemWrapper: {

            paddingTop: spacing.small
        },

        lastItemWrapper: {

            borderBottomWidth: 2,
            borderBottomColor: colors.primary,
            borderBottomLeftRadius: borderRadius,
            borderBottomRightRadius: borderRadius
        },

        item: {

            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',

            marginBottom: spacing.small,
            marginHorizontal: spacing.small,
            padding: spacing.small
        },

        itemDisabled: {

            opacity: .4,
        },

        subText: {

            color: colors.text.light
        }

    })
};

UserItem.propTypes = {
    user: PropTypes.instanceOf(User),
    company: PropTypes.instanceOf(Company),
    onPress: PropTypes.func.isRequired,
    isFirstUserInList: PropTypes.bool,
    isLastUserInList: PropTypes.bool
};

export default UserItem;
