import React from 'react';
import { StyleSheet, Text, TextProps } from 'react-native';
import { useTheme } from 'styled-components';

interface Props extends TextProps {
    children: JSX.Element | string | (string | JSX.Element)[];
}

/**
 * Header4 Text component
 */
const H4Text = ({ style, ...props }: Props): JSX.Element => {
    const styles = useStyles();

    // render
    return (
        <Text style={[styles.text, style]} {...props}/>
    );
}

const useStyles = () => {
    const { fontSizes, fonts, colors } = useTheme();

    return StyleSheet.create({

        text: {

            fontSize: fontSizes.h4,
            fontFamily: fonts.compact.medium,
            color: colors.text.dark,
        }
    });
}

export default H4Text;
