import React from 'react';
import {View, Image, ScrollView, StyleSheet, Platform} from "react-native";
import { useTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { useNavigation } from '@react-navigation/native';
// constants
import * as ROUTES from '../../../../constants/routes';
// assets
import logoPNG from '../../../../assets/logo.png';
// atoms
import AuthSubmitBtn from '../../../atoms/AuthSubmitBtn';
import PText from "../../../atoms/text/PText";
// templates
import MasterTemplate from "../../MasterTemplate";
import OverlayLoadingSpinner from "../../../molecules/OverlayLoadingSpinner";

const ActivateSubmitBtn = ({ handleSubmit }) => {

    /* render */
    return (
        <AuthSubmitBtn
            onPress={handleSubmit}
            title='Activate Account'
        />
    )
};

const SignInSubmitBtn = ({ handleSubmit, ...props }) => {

    /* render */
    return (
        <AuthSubmitBtn
            onPress={handleSubmit}
            title='Sign In'

            {...props}
        />
    )
}


const RegisterSubmitBtn = ({ handleSubmit }) => {

    /* render */
    return (
        <AuthSubmitBtn
            onPress={handleSubmit}
            title='Register'
        />
    )
};

const ForgotPasswordSubmitBtn = ({ handleSubmit }) => {

    /* render */
    return (
        <AuthSubmitBtn
            onPress={handleSubmit}
            title='Request Password Reset Email'
        />
    )
}


const GotoActivationBtn = () => {
    const theme = useTheme();
    const navigation = useNavigation();

    // render
    return (
        <AuthSubmitBtn
            onPress={() => navigation.navigate(ROUTES.ACTIVATION)}
            title='Enter Activation Code'
            outline={true}
            style={{ marginBottom: theme.margin.medium }}
        />
    )
}

const GotoSignInBtn = () => {
    const theme = useTheme();
    const navigation = useNavigation();

    // render
    return (
        <AuthSubmitBtn
            onPress={() => navigation.navigate(ROUTES.SIGN_IN)}
            title='Sign In'
            outline={true}
            style={{ marginBottom: theme.margin.medium }}
        />
    )
}

const GotoRegisterBtn = () => {
    const theme = useTheme();
    const navigation = useNavigation();

    // render
    return Platform.OS !== 'web' && (
        <AuthSubmitBtn
            onPress={() => navigation.navigate(ROUTES.REGISTER)}
            title='Register'
            outline={true}
            style={{ marginBottom: theme.margin.medium }}
        />
    );
}

/**
 * Auth Screen Template
 *
 * @returns {JSX.Element}
 */
const AuthScreenTemplate = ({ mode, handleSubmit, isSubmitting, error, showButtons, children }) => {
    const navigation = useNavigation();
    const styles = useStyles();

    // render
    return (
        <MasterTemplate contentContainerStyle={{ flex: 1 }}>
            <View style={ styles.pageWrapper }>
                <ScrollView contentContainerStyle={styles.contentContainer}>
                    {isSubmitting && (
                        <OverlayLoadingSpinner/>
                    )}

                    <View style={styles.logoWrapper}>
                        <Image
                            style={styles.logo}
                            source={logoPNG}
                        />
                    </View>

                    { children }

                    {showButtons && (
                        <>
                            {(mode === 'sign_in') ? (
                                <SignInSubmitBtn handleSubmit={handleSubmit} />
                            ) : (mode === 'register') ? (
                                <RegisterSubmitBtn handleSubmit={handleSubmit} />
                            ) : (mode === 'activation') ? (
                                <ActivateSubmitBtn handleSubmit={handleSubmit} />
                            ) : (mode === 'forgot_password') ? (
                                <ForgotPasswordSubmitBtn handleSubmit={handleSubmit} />
                            ) : null}

                            <PText style={styles.errorMsg}>{ error?.message || ' ' }</PText>

                            {(mode === 'sign_in') ? (
                                <View style={styles.gotoBtns}>
                                    <GotoRegisterBtn/>
                                    <GotoActivationBtn/>

                                    <AuthSubmitBtn
                                        onPress={() => navigation.navigate(ROUTES.FORGOT_PASSWORD)}
                                        title='Forgot Password?'
                                        outline={true}
                                        style={{ borderWidth: 0 }}
                                    />
                                </View>
                            ) : (mode === 'register') ? (
                                <View style={styles.gotoBtns}>
                                    <GotoSignInBtn/>
                                    <GotoActivationBtn/>
                                </View>
                            ) : (mode === 'activation') ? (
                                <View style={styles.gotoBtns}>
                                    <GotoSignInBtn/>
                                    <GotoRegisterBtn/>
                                </View>
                            ) : (mode === 'forgot_password') ? (
                                <View style={styles.gotoBtns}>
                                    <GotoSignInBtn/>
                                    <GotoRegisterBtn/>
                                    <GotoActivationBtn/>
                                </View>
                            ) : null}
                        </>
                    )}
                </ScrollView>
            </View>
        </MasterTemplate>
    );
};

const useStyles = () => {
    const { colors, spacing } = useTheme();

    return StyleSheet.create({
        pageWrapper: {
            flex: 1,
            alignItems: 'center',

            backgroundColor: colors.primary
        },

        contentContainer: {
            justifyContent: 'center',
            flexGrow: 1,
            margin: spacing.large,
        },

        logoWrapper: {
            height: 80,
            alignSelf: 'center',

            marginBottom: spacing.large
        },

        logo: {
            flex: 1,
            resizeMode: 'contain',
            width: 339,
            height: 99,
        },

        errorMsg: {
            marginTop: spacing.xSmall,

            color: colors.light
        },

        btnRow: {
            flexDirection: 'row'
        },

        gotoBtns: {
            marginTop: spacing.large
        }
    });
}

AuthScreenTemplate.propTypes = {
    mode: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.instanceOf(Error),
    showButtons: PropTypes.bool,
    isSubmitting: PropTypes.bool
};

AuthScreenTemplate.defaultProps = {
    mode: 'sign_in',
    showButtons: true,
    isSubmitting: false
};

export default AuthScreenTemplate;
