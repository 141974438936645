import React from 'react';
import {useTheme} from 'styled-components';
import {useNavigation} from '@react-navigation/native';
import {SafeAreaView, ScrollView, StyleSheet, View} from 'react-native';
// atoms
import StyledButton from '../../../atoms/StyledButton';
// molecules
import ScreenHeader from '../../../molecules/ScreenHeader';
// organisms
import EditUserForm from '../../../organisms/EditUserForm';
// templates
import MasterTemplate from '../../MasterTemplate';
// utils
import { userAlert, userConfirm } from '../../../../utilities/alert';
// firebase
import User from '../../../../firebase/documents/User/User';

interface Props {
    user: User;
}

/**
 * Admin Edit User Screen Template
 */
const AdminEditUserScreenTemplate = ({ user }: Props): JSX.Element => {
    const navigation = useNavigation();
    const styles = useStyles();

    const handleEditSuccess = () => {

        userAlert(
            'User Updated',
            'This users details have been successfully updated',
            () => navigation.goBack()
        );
    }

    // press disable/enable user handler
    const handlePressDisableEnable = async () => {
        if (user.disabled) {
            // enable user
            try {
                await user.enable();

            } catch (err) {
                userAlert('Failed to Enable User', err.message);
                return;
            }

            userAlert(
                'User Enabled',
                `User '${user.name}' has been enabled and will be able to login again within a few minutes.`
            );
        } else {
            // disable user

            const disableUser = async () => {
                try {
                    await user.disable();

                } catch (err) {
                    userAlert('Failed to Disable User', err.message);
                    return;
                }

                userAlert(
                    'User Disabled',
                    `User '${user.name}' has been disabled so can no longer login.`,
                    () => navigation.goBack()
                )
            };

            userConfirm(
                'Disable Tenant',
                `Are you sure you would like to disable '${user.name}'?  This will prevent them from logging in until an admin enables their account.`,
                () => disableUser(),
                'Disable User'
            );
        }
    };

    // render
    return (
        <MasterTemplate>
            <SafeAreaView style={{ flex: 1 }}>
                <ScreenHeader
                    title='Edit User'
                    subtitle={`${user.name} • ${user.company.name}`}
                    style={{ marginBottom: 0 }}
                    backButton={true}
                />

                <ScrollView style={styles.contentWrapper}>
                    <EditUserForm user={user} onSuccess={handleEditSuccess}/>

                    <View style={styles.disableTenantBtnWrapper}>
                        <StyledButton
                            title={user.disabled ? 'Enable User' : 'Disable User'}
                            outline={true}
                            onPress={handlePressDisableEnable}
                        />
                    </View>
                </ScrollView>
            </SafeAreaView>
        </MasterTemplate>
    )
}

const useStyles = () => {
    const { spacing } = useTheme();

    return StyleSheet.create({
        contentWrapper: {
            paddingTop: spacing.medium
        },

        disableTenantBtnWrapper: {
            padding: spacing.medium
        }
    });
}

export default AdminEditUserScreenTemplate;
