import React from 'react';
import { View,  TouchableOpacity } from 'react-native';
import { useTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { useNavigation } from '@react-navigation/native';
// constants
import * as ROUTES from '../../../constants/routes';
// firebase
import User from '../../../firebase/documents/User/User';
// styles
import stylesheet, { infoItemStylesheet } from './stylesheet';
// atoms
import OverlayModal from '../../organisms/OverlayModal';
import H3Text from '../../atoms/text/H3Text';
import H2Text from '../../atoms/text/H2Text';
import PText from '../../atoms/text/PText';
import StyledButton from '../../atoms/StyledButton';
// atoms - icons
import AtIcon from '../../atoms/icons/AtIcon';
import PowerOffIcon from '../../atoms/icons/PowerOffIcon';
import KeyIcon from '../../atoms/icons/KeyIcon';
import UserSlashIcon from '../../atoms/icons/UserSlashIcon';
import IDCardIcon from '../../atoms/icons/IDCardIcon';

/**
 * Expanded User - Info Item (only used internally by the ExpandedUser component in this same file)
 *
 * @param Icon
 * @param value
 * @param selectableValue
 * @param secure
 * @param label
 * @return {JSX.Element}
 * @constructor
 */
const InfoItem = ({ icon: Icon, value, selectableValue, secure, label }) => {
    const theme = useTheme();
    const styles = infoItemStylesheet(theme);

    // is item value hidden state
    const [ isHidden, setIsHidden ] = React.useState(secure === true);

    const ItemIcon = () => (
        <Icon
            fill={theme.colors.light}
            size='small'
        />
    );

    // render
    return (
        secure ? (
            <TouchableOpacity
                style={styles.infoItem}
                onPress={() => setIsHidden(!isHidden)}
            >
                <View style={styles.iconWrapper}>
                    {Icon && <ItemIcon/>}
                </View>

                <PText style={[
                    styles.value,
                    isHidden && styles.infoValueHidden
                ]}>
                    {isHidden ? (
                        `Tap to reveal ${label}`
                    ) : (
                        value
                    )}
                </PText>
            </TouchableOpacity>
        ) : (
            <View style={styles.infoItem}>
                <View style={styles.iconWrapper}>
                    {Icon && <ItemIcon/>}
                </View>

                {value && (
                    <PText style={styles.value}>
                        { value }
                    </PText>
                )}
                {selectableValue && (
                    <PText style={[styles.value, styles.valueSelectable]} selectable={true}>
                        { selectableValue }
                    </PText>
                )}
            </View>
        )
    );
};

InfoItem.propTypes = {
    icon: PropTypes.elementType,
    value: PropTypes.string.isRequired,
    secure: PropTypes.bool,
    label: PropTypes.string
};

InfoItem.defaultProps = {
    secure: false,
    label: ''
};

/**
 * Expanded user modal, displayed when a user is selected in the company list
 * @param user
 * @param handleClose
 * @return {JSX.Element}
 * @constructor
 */
const ExpandedUser = ({ user, handleClose }) => {
    const theme = useTheme();
    const navigation = useNavigation();
    const styles = stylesheet(theme);

    // on press edit
    const handlePressEdit = () =>
        navigation.navigate(ROUTES.ADMIN.USERS.EDIT, { uid: user.id });

    // render
    return (
        <OverlayModal blurIntensity={90}
                      contentContainerStyle={styles.modalContentContainer}
                      handlePressClose={() => handleClose()}
                      closeBtnFill={theme.colors.light}
        >
            <H3Text style={{ color: theme.colors.lightMuted }}>
                { user.company.name }
            </H3Text>

            <H2Text style={styles.title}>
                { user.name }
            </H2Text>

            {/*Activated*/}
            {!user.isActivated && (
                <View style={ styles.activationInfo }>
                    <InfoItem icon={PowerOffIcon} value='Not Activated' />
                    <InfoItem
                        icon={KeyIcon}
                        value='Activation Code: '
                        selectableValue={user.activationCode}
                    />
                </View>
            )}

            {/*Disabled*/}
            {user.disabled && (
                <InfoItem icon={UserSlashIcon} value='Disabled' />
            )}
            {/*Roles*/}
            {!!user.roleNamesStr && (
                <InfoItem icon={IDCardIcon} value={`Create Bookings ${user.roleNamesStr}`} />
            )}
            {/*Email*/}
            <InfoItem icon={AtIcon} value={user.email} secure={true} label='email' />

            <View style={styles.footer}>
                {/*<StyledButton*/}
                {/*    outline={true}*/}
                {/*    title='INVOICE'*/}
                {/*    style={this.styles.footerBtn}*/}
                {/*    titleStyle={this.styles.footerBtnText}*/}
                {/*    onPress={this.handlePressInvoice}*/}
                {/*/>*/}
                {/*<StyledButton*/}
                {/*    outline={true}*/}
                {/*    title={user.disabled ? 'ENABLE' : 'DISABLE'}*/}
                {/*    style={this.styles.footerBtn}*/}
                {/*    titleStyle={this.styles.footerBtnText}*/}
                {/*    onPress={user.disabled ? this.handlePressEnable : this.handlePressDisable}*/}
                {/*/>*/}
                <StyledButton
                    outline={true}
                    title='EDIT'
                    style={styles.footerBtn}
                    titleStyle={styles.footerBtnText}
                    onPress={handlePressEdit}
                />
            </View>
        </OverlayModal>
    );
};

ExpandedUser.propTypes = {
    user: PropTypes.instanceOf(User).isRequired,
    handleClose: PropTypes.func.isRequired
};

export default ExpandedUser;
