import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { withTheme } from "styled-components";
// constants
import * as ROUTES from "../../../../../../../../../constants/routes";
// screens
import HomeScreen from "../../../../../../../../../components/screens/HomeScreen";
import getWebTabTitle from "../../../../../../../../../utilities/get-web-tab-title";

/**
 * Child of MainStack, contains home screens
 */
const Stack = createStackNavigator();

const HomeStackBase = ({ theme: { colors } }) => (

    <Stack.Navigator
        screenOptions={{
            headerShown: false,
            cardStyle: {
                backgroundColor: colors.background
            },
            title: getWebTabTitle(),
        }}
    >

        <Stack.Screen name={ROUTES.HOME.HOME} component={HomeScreen}/>
    </Stack.Navigator>
);

const HomeStack = withTheme(HomeStackBase);

export default HomeStack;
