import React, { useMemo } from 'react';
import { View, SafeAreaView, StyleSheet } from 'react-native';
// firebase
import Bookings from '../../../firebase/collections/Bookings/Bookings';
// molecules
import ViewSwitcher from '../../molecules/ViewSwitcher';
import ScreenHeader from '../../molecules/ScreenHeader';
// organisms
import BookingsList from '../../organisms/BookingsList';
// templates
import MasterTemplate from '../MasterTemplate';
// hooks
import useListBookings from '../../../hooks/use-list-bookings';

interface Props {
    bookings: Bookings;
    viewMode: number;
    handleSwitchViewMode: (viewMode: number) => void;
    titlePrefix?: string,
    showUser?: boolean;
    showCompany?: boolean;
}

/**
 * Bookings Screen Template (used by both the 'My Bookings' and admin 'Manage Bookings' screens).
 */
const BookingsScreenTemplate = ({
    bookings,
    viewMode,
    handleSwitchViewMode,
    titlePrefix,
    showCompany,
    showUser
}: Props): JSX.Element => {
    const styles = useStyles();

    // memoize bookings to be displayed in the list
    const listBookings = useListBookings(bookings, viewMode);

    // memoize title (with optional prefix)
    const title = useMemo(() => (
        `${titlePrefix ? titlePrefix + ' ' : ''}Bookings`
    ), [titlePrefix]);

    // render
    return (
        <MasterTemplate>
            <View style={styles.container}>
                <SafeAreaView style={styles.innerContainer}>
                    <ScreenHeader title={title}/>

                    <ViewSwitcher viewMode={viewMode}
                                  handler={handleSwitchViewMode}
                                  titles={['Upcoming', 'Recurring', 'Past']}/>

                    <BookingsList
                        showUser={showUser}
                        showCompany={showCompany}
                        bookings={listBookings}
                        expandedBookingProps={{
                            showUser: true,
                            showCompany,
                        }}
                        overrideSectionsTitle={viewMode === 1 ? 'Recurring' : undefined}
                    />
                </SafeAreaView>
            </View>
        </MasterTemplate>
    )
};

const useStyles = () => {

    return StyleSheet.create({

        container: {

            flex: 1
        },

        innerContainer: {

            flex: 1
        }
    });
}

export default BookingsScreenTemplate;
