import { StyleSheet } from 'react-native';
import { transparentize } from 'polished';


const stylesheet = ({ spacing, colors }) => StyleSheet.create({

    cardLabel: {

        marginHorizontal: spacing.medium,
        marginBottom: spacing.xSmall,
    },

    card: {
        marginHorizontal: spacing.medium,
        marginBottom: spacing.medium
    },

    invoiceCard: {

        padding: spacing.small,
        marginBottom: spacing.medium,
    },

    lastInvoiceCard: {

        marginBottom: 0,
    },

    label: {

        marginBottom: spacing.xSmall,
    },

    invoicesListItemTitle: {

        marginBottom: spacing.xSmall,
    },

    customersNoQboLabel: {

        marginTop: spacing.medium,
        marginBottom: spacing.xSmall,
        color: colors.error
    },

    invoicesErr: {

        color: colors.error
    },

    submitBtnWrapper: {
        padding: spacing.medium,
        paddingTop: 0,
        marginBottom: spacing.medium,
    },

    modalContentContainer: {
        alignItems: 'center',

        marginHorizontal: spacing.medium
    },

    modalIconWrapper: {
        padding: spacing.medium,
        marginBottom: spacing.large,

        backgroundColor: transparentize(0.8, colors.primary),
        borderRadius: 100
    },

    modalTextWrapper: {
        marginBottom: spacing.large
    },

    modalText: {
        marginBottom: spacing.small,

        textAlign: 'center',
        color: colors.text.light
    },

    modalErrText: {
        textAlign: 'center',
        color: colors.error
    }
});


export default stylesheet;
