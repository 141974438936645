import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from "styled-components";
import { useNavigation } from "@react-navigation/native";
// constants
import * as ROUTES from '../../../../../constants/routes';
// atoms
import AuthTextInput from '../../../../atoms/AuthTextInput';
import H2Text from '../../../../atoms/text/H2Text';
import H3Text from '../../../../atoms/text/H3Text';
import AuthSubmitBtn from '../../../../atoms/AuthSubmitBtn';
// templates
import AuthScreenTemplate from '../AuthScreenTemplate';

/**
 * RegisterScreen Template
 *
 * @returns {JSX.Element}
 */
const RegisterScreenTemplate = ({
                                    handleSubmit,
                                    formData: { email, name, company },
                                    handleChangeTextInput,
                                    error,
                                    user
}) => {
    const theme = useTheme();
    const navigation = useNavigation();

    // input refs
    const nameInputRef = useRef();
    const companyInputRef = useRef();

    // render
    return (
        <AuthScreenTemplate
            mode='register'
            handleSubmit={handleSubmit}
            error={error}
            showButtons={!user}
        >

            {
                user ? (
                    <>
                        <H2Text
                            style={{
                                marginBottom: theme.margin.small,

                                textAlign: 'center',
                                color: theme.colors.light
                            }}
                        >
                            Welcome { user.name }!
                        </H2Text>

                        <H3Text style={{ color: theme.colors.light, textAlign: 'center' }}>
                            You have registered your company '{ user.company }' with CREATE Bookings,
                            and a welcome email has been sent to you at '{ user.email }', which
                            contains your temporary password.
                        </H3Text>

                        <AuthSubmitBtn
                            onPress={() => navigation.navigate(ROUTES.SIGN_IN)}
                            title='Sign In'
                            style={{ marginTop: theme.margin.large }}
                        />
                    </>
                ) : (
                    <>
                        <AuthTextInput
                            value={email}
                            onChangeText={email => handleChangeTextInput('email', email)}
                            onSubmitEditing={() => nameInputRef.current.focus()}
                            blurOnSubmit={false}
                            autoCompleteType='email'
                            keyboardType='email-address'
                            returnKeyType='next'
                            textContentType='emailAddress'
                            placeholder='Email Address'
                        />

                        <AuthTextInput
                            ref={nameInputRef}
                            value={name}
                            onChangeText={name => handleChangeTextInput('name', name)}
                            blurOnSubmit={false}
                            onSubmitEditing={() => companyInputRef.current.focus()}
                            returnKeyType='next'
                            placeholder='Full Name'
                        />

                        <AuthTextInput
                            ref={companyInputRef}
                            value={company}
                            onChangeText={company => handleChangeTextInput('company', company)}
                            blurOnSubmit={false}
                            onSubmitEditing={() => handleSubmit()}
                            returnKeyType='go'
                            placeholder='Company'
                            // style={{ marginBottom: 0 }}
                        />
                    </>
                )
            }

        </AuthScreenTemplate>
    );
};

RegisterScreenTemplate.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    formData: PropTypes.exact({
        email: PropTypes.string,
        name: PropTypes.string,
        company: PropTypes.string
    }).isRequired,
    error: PropTypes.instanceOf(Error),
    handleChangeTextInput: PropTypes.func.isRequired,
    user: PropTypes.object
};

export default RegisterScreenTemplate;
