import React, { useMemo, useRef, useState, useCallback } from 'react';
import { Platform, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { useTheme } from 'styled-components';
import { useFocusEffect } from '@react-navigation/native';
// atoms
import Card from '../atoms/Card';
import StyledTextInput from '../atoms/StyledTextInput';
import StyledButton from '../atoms/StyledButton';
// atoms - text
import H4Text from '../atoms/text/H4Text';
// firebase
import User from '../../firebase/documents/User/User';
import {userAlert} from '../../utilities/alert';
import {useAuthUser} from '../../Session/context';
import OverlayLoadingSpinner from '../molecules/OverlayLoadingSpinner';

interface Props {
    user: User;
    onSuccess: () => void;
}

const INITIAL_STATE = {
    email: '',
    name: '',
    isSubmitting: false,
    error: null,
}

/**
 * Edit User Form
 */
const EditUserForm = ({ user, onSuccess }: Props): JSX.Element => {
    const styles = useStyles();
    const authUser = useAuthUser();

    // form values state
    const [ email, setEmail ] = useState(INITIAL_STATE.email);
    const [ name, setName ] = useState(INITIAL_STATE.name);
    // form state
    const [ isSubmitting, setIsSubmitting ] = useState(INITIAL_STATE.isSubmitting);
    const [ error, setError ] = useState(INITIAL_STATE.error);

    // reset state on focus
    useFocusEffect(useCallback(() => {

        setEmail(user.email);
        setName(user.name);
    }, [user]));

    // email input ref
    const emailInputRef = useRef(null);

    const editEmailDisabled = useMemo(() => user.isCompanyOwner && !authUser.isAdmin, [user]);

    const handlePressEmailControl = () => {

        if (editEmailDisabled) {

            userAlert('Cannot edit email', 'Since you are a company admin, you must request a CREATE Bookings admin to change your email address.  Please contact reception for further assistance.');
        }
    }

    // on press save changes handler
    const handlePressSaveChanges = async () => {
        setIsSubmitting(true);

        try {
            await user.update({ name, email });

        } catch (err) {
            setError(err);
            setIsSubmitting(false);
            return;
        }

        setIsSubmitting(false);
        onSuccess();
    };

    // memoize whether changes have been made
    const changesMade = useMemo(() => (
        (email !== user.email) ||
        (name !== user.name)
    ), [email, name, user]);

    // render
    const emailInput = (
        <>
            <H4Text>Email</H4Text>
            <StyledTextInput
                style={ styles.textInput }
                placeholder={user.email}
                value={email}
                keyboardType='email-address'
                onChangeText={val => setEmail(val)}
                ref={emailInputRef}
                autoCapitalize='none'
                editable={!editEmailDisabled}
            />
        </>
    );

    return (
        <>
            {isSubmitting && (
                <OverlayLoadingSpinner/>
            )}

            <Card style={styles.tenantDetailsCard}>

                <H4Text>Name</H4Text>
                <StyledTextInput
                    style={styles.textInput}
                    placeholder={user.name}
                    value={name}
                    onChangeText={val => setName(val)}
                    blurOnSubmit={false}
                    returnKeyType='next'
                    onSubmitEditing={() => emailInputRef.current.focus()}
                />

                {editEmailDisabled ? (
                    <TouchableOpacity
                        activeOpacity={0.7}
                        style={[ styles.editEmailDisabled, styles.editEmailDisabledCursor ]}
                        onPress={handlePressEmailControl}
                    >
                        {emailInput}
                    </TouchableOpacity>
                ) : (
                    emailInput
                )}

                {changesMade && (
                    <StyledButton
                        style={styles.saveBtn}
                        title='Save Changes'
                        onPress={handlePressSaveChanges}
                    />
                )}

                { error && <Text style={styles.formError}>{ error?.message }</Text> }

            </Card>
        </>
    )
}

const useStyles = () => {
    const { spacing, borderRadius, colors } = useTheme();

    return StyleSheet.create({

        tenantDetailsCard: {
            marginHorizontal: spacing.medium
        },

        textInput: {
            marginTop: spacing.xSmall,
            marginBottom: spacing.small,

            borderWidth: StyleSheet.hairlineWidth,
            borderRadius
        },

        editEmailDisabledCursor: Platform.OS === 'web' ? {
            // @ts-ignore - web only, will throw error on mobile
            cursor: 'help'
        } : {},

        editEmailDisabled: {

            opacity: 0.6,
        },

        formError: {
            marginTop: spacing.small,

            color: colors.error
        },

        saveBtn: {

            marginTop: spacing.medium,
        }
    });
}

export default EditUserForm;
