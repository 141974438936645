import React from 'react';
import { StyleProp, StyleSheet, TextStyle } from 'react-native';
import { useTheme } from 'styled-components';
// atoms
import PText from './PText';

interface Props {
    children: string;
    style?: StyleProp<TextStyle>;
}

/**
 * Strong Text component.
 */
const StrongText = ({ style, children, ...props }: Props): JSX.Element => {
    const styles = useStyles();

    // render
    return (
        <PText style={[styles.text, style]} {...props}>
            {children}
        </PText>
    );
}

const useStyles = () => {
    const { fontWeights } = useTheme();

    return StyleSheet.create({

        text: {

            // fontFamily: fonts.compact.medium,
            fontWeight: fontWeights.bold,
        }
    })
}

export default StrongText;
