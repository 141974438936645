

// constants
import { MONTHS } from '../../constants/dates';


// utilities
import getDateNth from '../../utilities/get-date-nth';


/** Class representing a firestore document */
export default class FirestoreDoc {
    /**
     * Construct a FirestoreDoc
     * @param {firebase.firestore.DocumentReference} ref - document reference
     * @param {string} id - document id
     * @param {{ seconds: number }} createdAt - document creation time
     * @param {{ seconds: number }} updatedAt - document last updated time
     */
    constructor(ref, id, createdAt, updatedAt) {
        this._ref = ref;
        this._id = id;
        this._createdAt = createdAt && new Date(createdAt.seconds*1000);
        this._updatedAt = updatedAt && new Date(updatedAt.seconds*1000);

    }




    // accessor methods (getters)
    get id () { return this._id; }
    get ref () { return this._ref; }

    get createdAtStr () {
        return this._createdAt ? (
            `${this._createdAt.getDate()}${getDateNth(this._createdAt.getDate())} ${MONTHS[this._createdAt.getMonth()]} ${this._createdAt.getFullYear()}`
        ) : null;
    }

}