import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useTheme } from 'styled-components';
// atoms
import TimesIcon from '../atoms/icons/TimesIcon';

interface Props {
    handlePressClose: () => void;
    fill: string;
}

/**
 * Modal Close button
 */
const ModalCloseBtn = ({ handlePressClose, fill }: Props): JSX.Element => {
    const theme = useTheme();

    // render
    return (
        <TouchableOpacity
            style={styles.button}

            onPress={handlePressClose}
        >
            <TimesIcon fill={fill || theme.colors.dark}/>
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({

    button: {

        position: 'absolute',
        top: 3,
        right: 3
    }
});

export default ModalCloseBtn;
