import React from 'react';
import { StyleProp, StyleSheet, Text, TextStyle } from 'react-native';
import { DefaultTheme, useTheme } from 'styled-components';

type Props = {
    children: string;
    style?: StyleProp<TextStyle>;
};

/**
 * Paragraph Text
 */
const PText = ({ style, children }: Props) => {
    const theme = useTheme();
    const styles = stylesheet(theme);

    // render
    return (
        <Text style={[styles.text, style]}>
            {children}
        </Text>
    );
}

const stylesheet = ({ fontSizes }: DefaultTheme) => StyleSheet.create({

    text: {

        fontSize: fontSizes.p,
    }
});

export default PText;
