import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { NavigatorScreenParams } from '@react-navigation/native';
// navigation
import MainStack, { MainStackParamList } from './stacks/main/MainStack.web';
// config
import * as ROUTES from '../../../../../constants/routes';
// screens
import IntuitOauthRedirectScreen from '../../../../../components/screens/IntuitOauthRedirectScreen';
// utils
import getWebTabTitle from '../../../../../utilities/get-web-tab-title';

export type AppStackParamList = {
    main: NavigatorScreenParams<MainStackParamList>;
    [ROUTES.INTUIT_OAUTH.REDIRECT]: undefined;
}

/**
 * Child of RootStack, contains modals and MainStack
 */
const Stack = createStackNavigator<AppStackParamList>();

const AppStack = (): JSX.Element => {

    // render
    return (
        <Stack.Navigator screenOptions={{
            headerShown: false
        }}>
            <Stack.Screen name='main' component={MainStack}/>

            <Stack.Screen
                name={ROUTES.INTUIT_OAUTH.REDIRECT}
                component={IntuitOauthRedirectScreen}
                options={{
                    title: getWebTabTitle()
                }}
            />
        </Stack.Navigator>
    );
}

export default AppStack;
