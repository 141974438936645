import React, { useState, useMemo } from 'react';
import {View, SafeAreaView, Text, Platform} from "react-native";
import PropTypes from 'prop-types';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useTheme } from 'styled-components';
import { useNavigation } from '@react-navigation/native';
// firebase
import Company from '../../../../../firebase/documents/Company/Company';
// styles
import stylesheet from './stylesheet';
// atoms
import Card from '../../../../atoms/Card';
import H4Text from '../../../../atoms/text/H4Text';
import FormTextInput from '../../../../atoms/FormTextInput';
import SelectInput from '../../../../atoms/SelectInput';
import StyledButton from '../../../../atoms/StyledButton';
// molecules
import ScreenHeader from '../../../../molecules/ScreenHeader';
import OverlayLoadingSpinner from "../../../../molecules/OverlayLoadingSpinner";
// templates
import MasterTemplate from "../../../MasterTemplate";
// utils
import { userAlert } from "../../../../../utilities/alert";
// constants
import * as ROUTES from "../../../../../constants/routes";

/**
 * AdminEditCompanyScreenTemplate
 *
 * @returns {JSX.Element}
 */
const AdminEditCompanyScreenTemplate = ({ company }) => {
    const navigation = useNavigation();
    const theme = useTheme();
    const styles = stylesheet(theme);

    // input values state
    const [name, setName] = useState(company.name || '');
    const [room, setRoom] = useState(company.room || '');
    const [quotaHours, setQuotaHours] = useState(company.quotaHours.toString() || '');
    const [adminUser, setAdminUser] = useState(company.ownerRef.id);
    // form error state
    const [error, setError] = useState(null);
    // form submitting state
    const [isSubmitting, setIsSubmitting] = useState(false);

    // form submit handler
    const handleSubmit = async () => {
        setIsSubmitting(true);

        const adminUserObj = company.users.users.find(({ id }) => id === adminUser);

        try {
            await company.update({
                name, room, quotaHours,
                adminUser: { id: adminUser, email: adminUserObj.email }
            });

        } catch (err) {

            if (Platform.OS === 'web') {
                setError(err);
            } else {
                userAlert('Failed to Edit Company', err.message);
            }

            setIsSubmitting(false);
            return;
        }

        setIsSubmitting(false);

        userAlert(
            'Company Updated',
            'This companies details have been successfully updated',
            () => navigation.navigate(ROUTES.ADMIN.USERS.HOME)
        );
    };

    // memoize whether save button should be shown
    const saveBtnIsShown = useMemo(() => (
        (name !== company.name) ||
        (room !== company.room) ||
        (quotaHours !== company.quotaHours.toString()) ||
        (adminUser !== company.ownerRef.id)
    ), [name, room, quotaHours, adminUser, company]);

    // render
    return (
        <MasterTemplate>
            <SafeAreaView style={{ flex: 1 }}>
                <ScreenHeader
                    title='Edit Company'
                    subtitle={company.name}
                    backButton={true}
                    style={{ marginBottom: 0 }}
                />

                <KeyboardAwareScrollView>
                    <View style={{ marginTop: theme.margin.medium, marginBottom: theme.margin.large }}>

                        <Card style={styles.formCard}>
                            <H4Text>Company Name*</H4Text>
                            <FormTextInput
                                style={styles.input}
                                value={name}
                                onChangeText={val => setName(val)}
                            />

                            <H4Text>Room</H4Text>
                            <FormTextInput
                                style={styles.input}
                                value={room}
                                onChangeText={val => setRoom(val)}
                                placeholder='Room in CREATE (A-H, 001-036)'
                            />
                            <Text style={{ marginBottom: theme.padding.medium }}>
                                Alphabetic rooms (e.g. A, E) have access to ground floor
                                meeting rooms.  Numeric rooms (e.g. 002, 027) have access
                                to first floor meeting rooms.
                            </Text>

                            <H4Text>Monthly Bookings Quota*</H4Text>
                            <FormTextInput
                                style={styles.input}
                                value={quotaHours}
                                onChangeText={val => setQuotaHours(val)}
                                placeholder='Free monthly booking hours'
                                keyboardType='number-pad'
                            />

                            <H4Text
                                style={{ marginBottom: theme.padding.xSmall }}
                            >Company Admin User*</H4Text>
                            <SelectInput
                                onValueChange={val => setAdminUser(val)}
                                value={adminUser}
                                items={company.users.users.map(user => ({
                                    label: `${user.name} (${user.email})`,
                                    value: user.id
                                }))}
                                placeholder={{ label: 'Choose Admin User' }}
                            />

                        </Card>


                        {saveBtnIsShown && (
                            <View style={styles.submitBtnWrapper}>
                                <StyledButton
                                    title='Save Changes'
                                    onPress={handleSubmit}
                                />

                                {error && (
                                    <Text style={styles.errorTxt}>{error.message}</Text>
                                )}
                            </View>
                        )}
                    </View>
                </KeyboardAwareScrollView>

                {isSubmitting && (
                    <OverlayLoadingSpinner/>
                )}
            </SafeAreaView>
        </MasterTemplate>
    );
};

AdminEditCompanyScreenTemplate.propTypes = {
    company: PropTypes.instanceOf(Company).isRequired
}

export default AdminEditCompanyScreenTemplate;
