// constants
import * as ROUTES from "../constants/routes";

export const INTUIT_OAUTH_REDIRECT_PATH = "intuit-oauth/redirect";

/**
 * Linkings between navigation stacks/screens, and pages on web
 * See: {@link https://reactnavigation.org/docs/configuring-links}
 */
export const linking = {
    prefixes: [],
    config: {
        screens: {
            auth: {
                screens: {
                    [ROUTES.SIGN_IN]: 'auth/login',
                    [ROUTES.REGISTER]: 'auth/register',
                    [ROUTES.ACTIVATION]: 'auth/activate',
                    [ROUTES.FORGOT_PASSWORD]: 'auth/forgot-password',
                }
            },
            app: {
                screens: {
                    main: {
                        screens: {
                            [ROUTES.HOME.ROOT]: {
                                screens: {
                                    [ROUTES.HOME.HOME]: ''
                                }
                            },
                            [ROUTES.BOOKINGS.ROOT]: {
                                screens: {
                                    [ROUTES.BOOKINGS.HOME]: 'bookings',
                                }
                            },
                            [ROUTES.NEW_BOOKING.ROOT]: {
                                screens: {
                                    [ROUTES.NEW_BOOKING.HOME]: 'bookings/new',
                                }
                            },
                            [ROUTES.ADMIN.BOOKINGS.ROOT]: {
                                screens: {
                                    [ROUTES.ADMIN.BOOKINGS.HOME]: 'bookings/manage',
                                }
                            },
                            [ROUTES.ADMIN.USERS.ROOT]: {
                                screens: {
                                    // user screens
                                    [ROUTES.ADMIN.USERS.HOME]: 'tenants',
                                    [ROUTES.ADMIN.USERS.INVOICE]: 'tenants/invoice',
                                    [ROUTES.ADMIN.USERS.EDIT]: 'tenant/:uid',
                                    // company screens
                                    [ROUTES.ADMIN.COMPANY.NEW]: 'companies/new',
                                    [ROUTES.ADMIN.COMPANY.EDIT]: 'company/:companyId/edit',
                                    [ROUTES.ADMIN.COMPANY.ADD_USER]: 'company/:companyId/users/new'
                                }
                            },
                            [ROUTES.SETTINGS.ROOT]: {
                                screens: {
                                    [ROUTES.SETTINGS.HOME]: 'settings',
                                    [ROUTES.SETTINGS.EDIT_PROFILE]: 'settings/edit',
                                }
                            }

                            // TODO CBHBMS-12 add 404 page https://reactnavigation.org/docs/configuring-links#handling-unmatched-routes-or-404
                        }
                    },
                    [ROUTES.INTUIT_OAUTH.REDIRECT]: INTUIT_OAUTH_REDIRECT_PATH
                }
            }
        }
    }
}
