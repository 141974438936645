import React, { lazy, Suspense } from 'react';
import { Platform, View } from 'react-native';
// context
import { MasterTemplateCtxProvider } from './context';

const LazyMasterTemplate = lazy(() =>
    Platform.OS === 'web' ?
        import('./MasterTemplate.web') :
        import('./MasterTemplate.native')
);

/**
 * Master Template, wraps around screens to add core functionality such as scrolling on web.
 */
const MasterTemplate = (props): JSX.Element => {

    // render
    return (
        <Suspense fallback={<View />}>
            <MasterTemplateCtxProvider>
                <LazyMasterTemplate {...props}/>
            </MasterTemplateCtxProvider>
        </Suspense>
    );
}

export default MasterTemplate;
