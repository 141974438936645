import React, { useMemo } from 'react';
import { View, SafeAreaView, ScrollView } from "react-native";
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
// firebase
import Invoice from '../../../../../firebase/documents/Invoice/Invoice';
// styles
import stylesheet from './stylesheet';
// atoms
import Card from '../../../../atoms/Card';
import H3Text from '../../../../atoms/text/H3Text';
import PText from '../../../../atoms/text/PText';
import StyledButton from '../../../../atoms/StyledButton';
import LoadingSpinner from '../../../../atoms/LoadingSpinner';
import FileInvoiceIcon from '../../../../atoms/icons/FileInvoiceIcon';
import H2Text from '../../../../atoms/text/H2Text';
import StrongText from "../../../../atoms/text/StrongText";
// molecules
import ScreenHeader from '../../../../molecules/ScreenHeader';
import MonthYearPicker from '../../../../molecules/MonthYearPicker/MonthYearPicker';
// organisms
import OverlayModal from '../../../../organisms/OverlayModal';
// templates
import MasterTemplate from "../../../MasterTemplate";

/**
 * Admin Invoice Screen Template
 *
 * @returns {JSX.Element}
 * @constructor
 */
const AdminInvoiceScreenTemplate = ({
                                        monthIndex,
                                        year,

                                        handleChangeMonthYear,
                                        handlePressSendInvoices,

                                        isSubmitting,
                                        invoice,

                                        // handlePressGoToManageTenants,
                                        handlePressDone,

                                        invoices,
                                        invoicesErr
}) => {
    const theme = useTheme();
    const styles = stylesheet(theme);

    const modalIsShown = (isSubmitting || invoice);

    const orderedInvoices = useMemo(() => invoices ?
        invoices.sort(({ id: idA }, { id: idB }) => {
            const [monthA, yearA] = idA.split('-');
            const [monthB, yearB] = idB.split('-');

            return (
                new Date(parseInt(yearB, 10), parseInt(monthB, 10), 1).getTime() -
                new Date(parseInt(yearA, 10), parseInt(monthA, 10), 1).getTime()
            );
        }) : invoices, [invoices])

    // render
    return (
        <MasterTemplate>
            <SafeAreaView style={{ flex: 1 }}>
                <ScreenHeader
                    title='Send Invoices'
                    subtitle='via QuickBooks'
                    backButton={true}
                />

                <ScrollView>
                    <H3Text style={styles.cardLabel}>Send invoices to tenants</H3Text>
                    <Card style={styles.card}>
                        <H3Text style={styles.label}>Choose Month & Year to invoice for...</H3Text>

                        <MonthYearPicker
                            value={{ monthIndex, year }}
                            onChange={handleChangeMonthYear}
                            minDate={new Date(2010, 0)}
                        />
                    </Card>

                    <View style={styles.submitBtnWrapper}>
                        <StyledButton
                            title='Send Invoices'
                            onPress={handlePressSendInvoices}
                        />
                    </View>

                    <H3Text style={styles.cardLabel}>Previously sent invoices</H3Text>
                    <Card style={styles.card}>
                        {orderedInvoices ? (
                            orderedInvoices.length ? (
                                orderedInvoices.map(({ id, dateStr, createdBy, companiesNoQbo, companiesNoQboCount, sentInvoices }, index) => (
                                    <View key={`invoice_${id}`}>
                                        <Card padding={false} style={[
                                            styles.invoiceCard,
                                            index === invoices.length - 1 && styles.lastInvoiceCard
                                        ]}>
                                            <PText style={styles.invoicesListItemTitle}>
                                                Invoices for <StrongText>{dateStr}</StrongText>, sent by <StrongText>{createdBy.name}</StrongText>...
                                            </PText>

                                            {sentInvoices.length ? (
                                                sentInvoices.map(({ company, email, qbInvoiceId }) => (
                                                    <View key={`sentInvoice-${qbInvoiceId}`}>
                                                        <PText> - Invoice No. <StrongText>#{qbInvoiceId}</StrongText>, {company.name} ( {email} )</PText>
                                                    </View>
                                                ))
                                            ) : (
                                                <H3Text>No invoices were sent.</H3Text>
                                            )}

                                            {companiesNoQboCount > 0 && (
                                                <>
                                                    <H3Text style={styles.customersNoQboLabel}>
                                                        Failed to find a matching customer in QuickBooks for...
                                                    </H3Text>

                                                    {companiesNoQbo.map(({ company, email }) => (
                                                        <View key={`companyNoQbo-${company.id}`}>
                                                            <PText> - {company.name} ( {email} )</PText>
                                                        </View>
                                                    ))}
                                                </>
                                            )}
                                        </Card>
                                    </View>
                                ))
                            ) : (
                                <PText>No invoices have been sent via CREATE Bookings.</PText>
                            )
                        ) : invoicesErr ? (
                            <PText style={styles.invoicesErr}>
                                Failed to load previously sent invoices: {invoicesErr}.  Please refresh to try again.
                            </PText>
                        ) : (
                            <PText>
                                Fetching previously sent invoices...
                            </PText>
                        )}
                    </Card>

                    {modalIsShown && (
                        <OverlayModal
                            contentContainerCard={!isSubmitting}
                            contentContainerStyle={styles.modalContentContainer}
                        >
                            {
                                isSubmitting ? (
                                    <LoadingSpinner/>
                                ) : (
                                    <>
                                        <View style={styles.modalIconWrapper}>
                                            <FileInvoiceIcon
                                                fill={theme.colors.primary}
                                                size='large'
                                            />
                                        </View>

                                        <H2Text style={{ marginBottom: theme.margin.small }}>
                                            Invoices Sent!
                                        </H2Text>

                                        <View style={styles.modalTextWrapper}>
                                            <H3Text style={styles.modalText}>
                                                { invoice.sentInvoicesCount.length } Meeting room invoices
                                                for { invoice.dateStr } have been created in QuickBooks and
                                                emailed to tenants.
                                            </H3Text>

                                            {(invoice.companiesNoQbo.length > 0) && (
                                                <PText style={styles.modalErrText}>
                                                    A QuickBooks Customer for the following tenants could
                                                    not be found with an email matching the company admins
                                                    CREATE Bookings registration email, so they could not
                                                    be invoiced: {' '}
                                                    {invoice.companiesNoQbo.map(({ company }) => (
                                                        company.name
                                                    )).join('; ')}
                                                </PText>
                                            )}
                                        </View>

                                        <StyledButton title='Done' onPress={handlePressDone}/>
                                    </>
                                )
                            }
                        </OverlayModal>
                    )}
                </ScrollView>
            </SafeAreaView>
        </MasterTemplate>
    )
};

AdminInvoiceScreenTemplate.propTypes = {
    monthIndex: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,

    handleChangeMonthYear: PropTypes.func.isRequired,
    handlePressSendInvoices: PropTypes.func.isRequired,

    isSubmitting: PropTypes.bool,
    invoice: PropTypes.instanceOf(Invoice),

    // handlePressGoToManageTenants: PropTypes.func.isRequired,
    handlePressDone: PropTypes.func.isRequired,

    invoices: PropTypes.arrayOf(PropTypes.instanceOf(Invoice)),
    invoicesErr: PropTypes.string,
};


// exports
export default AdminInvoiceScreenTemplate;
