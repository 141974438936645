import * as React from "react"
import Svg, { Path } from "react-native-svg"
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';


// components
function ChalkboardIcon({ theme: { iconSizes }, size, ...props }) {
    return (
        <Svg
            viewBox="0 0 640 512"
            width={iconSizes[size]}
            height={iconSizes[size]}

            {...props}
        >
            <Path d="M96 64h448v352h64V40c0-22.06-17.94-40-40-40H72C49.94 0 32 17.94 32 40v376h64V64zm528 384H480v-64H288v64H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h608c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" />
        </Svg>
    )
}

ChalkboardIcon.propTypes = {
    size: PropTypes.string
};

ChalkboardIcon.defaultProps = {
    size: 'medium'
};


// exports
export default withTheme(ChalkboardIcon);
