import React, { forwardRef } from 'react';
import { StyleSheet, TextInput, TextInputProps } from 'react-native';
import { useTheme } from 'styled-components';

/**
 * Styled text input
 */
const StyledTextInput = forwardRef<TextInput, TextInputProps>(({
    style,
    ...props
}, ref) => {
    const styles = useStyles();
    const { colors } = useTheme();

    // render
    return (
        <TextInput style={[styles.input, style]}
                   placeholderTextColor={colors.text.light}
                   ref={ref}
                   {...props}/>
    );
});

const useStyles = () => {
    const { padding, colors, fontSizes } = useTheme();

    return StyleSheet.create({

        input: {

            padding: padding.xSmall,

            backgroundColor: colors.light,
            fontSize: fontSizes.h3,
        }
    });
}

export default StyledTextInput;
