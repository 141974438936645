import React from 'react';
import Svg, { Path } from "react-native-svg";
import { DefaultTheme, useTheme } from 'styled-components';

interface Props {
    size?: keyof DefaultTheme['iconSizes'];
    width?: number;
    height?: number;
    fill?: string;
}

/**
 * User Plus icon
 */
const UserPlusIcon = ({ width, height, size = 'medium', ...props }: Props): JSX.Element => {
    const { iconSizes } = useTheme();

    // render
    return (
        <Svg viewBox="0 0 640 512"
             width={width || iconSizes[size]}
             height={height || iconSizes[size]}
             {...props}
        >
            <Path d="M624 208h-64v-64c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v64h-64c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h64v64c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-64h64c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm-400 48c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z" />
        </Svg>
    );
}

export default UserPlusIcon;
