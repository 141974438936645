import * as React from "react"
import Svg, { Path } from "react-native-svg";
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';


// components
const CalendarDayIconBase = ({ theme: { iconSizes }, width, height, size, ...props }) => (
    <Svg
        viewBox="0 0 448 512"
        width={width || iconSizes[size]}
        height={height || iconSizes[size]}

        {...props}
    >
        <Path d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm64-192c0-8.8 7.2-16 16-16h96c8.8 0 16 7.2 16 16v96c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16v-96zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z" />
    </Svg>
);

const CalendarDayIcon = withTheme(CalendarDayIconBase);

CalendarDayIcon.propTypes = {
    size: PropTypes.string
};

CalendarDayIcon.defaultProps = {
    size: 'medium'
};


// exports
export default CalendarDayIcon;