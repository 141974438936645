import { DateTime } from 'luxon';

/**
 * Get amount of seconds since day of given Luxon DateTime instance started
 */
export default function dtSecsSinceDayStart(dt: DateTime): number {

    return secsSinceDayStart(dt.hour, dt.minute);
}

export function secsSinceDayStart(hours: number, mins: number): number {

    return (hours*60*60) + (mins*60);
}
