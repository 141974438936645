import React from "react";
import { StyleSheet } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";
import { useTheme } from "styled-components";
// constants
import * as ROUTES from "../../../../../../../../../constants/routes";
// screens
import AdminBookingsScreen from "../../../../../../../../../components/screens/admin/AdminBookingsScreen";
import getWebTabTitle from "../../../../../../../../../utilities/get-web-tab-title";

/**
 * Child of MainStack, contains admin bookings screen
 */
const Stack = createStackNavigator();

/**
 * Admin Bookings Stack
 *
 * @return {JSX.Element}
 */
const AdminBookingsStack = () => {
    const styles = useStyles();

    // render
    return (
        <Stack.Navigator screenOptions={{
            headerShown: false,
            cardStyle: styles.cardStyle,
            title: getWebTabTitle('Manage Bookings')
        }}>
            <Stack.Screen name={ROUTES.ADMIN.BOOKINGS.HOME} component={AdminBookingsScreen} />
        </Stack.Navigator>
    );
}

const useStyles = () => {
    const { colors } = useTheme();

    return StyleSheet.create({

       cardStyle: {

           backgroundColor: colors.background,
       }
    });
}

export default AdminBookingsStack;
