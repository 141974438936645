import * as React from "react"
import Svg, { Path } from "react-native-svg";
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';


// components
const CaretRightIconBase = ({ theme: { iconSizes }, width, height, size, ...props }) => (
    <Svg
        viewBox="0 0 192 512"
        width={width || iconSizes[size]}
        height={height || iconSizes[size]}

        {...props}
    >
        <Path d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" />
    </Svg>
);

const CaretRightIcon = withTheme(CaretRightIconBase);

CaretRightIcon.propTypes = {
    size: PropTypes.string
};

CaretRightIcon.defaultProps = {
    size: 'medium'
};


// exports
export default CaretRightIcon;