import React, { useState, useEffect, useCallback } from 'react';
import { View } from 'react-native';
import { useFocusEffect } from "@react-navigation/native";
// hooks
import { useMountedState } from "../../../hooks/react-use";
// firebase
import Company from '../../../firebase/documents/Company/Company';
// atoms
import LoadingSpinner from '../../atoms/LoadingSpinner';
// templates
import AdminEditCompanyScreenTemplate
    from '../../templates/screens/admin/AdminEditCompanyScreen/AdminEditCompanyScreenTemplate';
// utils
import { userAlert } from "../../../utilities/alert";

/**
 * Admin Edit Company Screen
 *
 * @returns {JSX.Element}
 */
const AdminEditCompanyScreen = ({ route: { params: { companyId } } }) => {
    const isMounted = useMountedState();

    // company state
    const [ company, setCompany ] = useState(null);

    // get company
    useFocusEffect(useCallback(() => {

        (async () => {
            let c;
            try {

                c = await Company.build(companyId, { populateUsers: true, populateOwner: true });
            } catch (err) {

                userAlert('Failed To Load', 'Failed to load Company data, please try again.');
                return;
            }

            if (!c.users) {

                userAlert('Failed To Load', 'Failed to load companies users, please try again.');
                return;
            }

            if (isMounted()) {

                setCompany(c);
            }
        })();
    }, [ companyId ]));

    // render
    return company ? (
        <AdminEditCompanyScreenTemplate company={company}/>
    ) : (
        <View style={{ flex: 1 }}>
            <LoadingSpinner/>
        </View>
    );
}

export default AdminEditCompanyScreen;
