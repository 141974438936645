import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { withTheme } from "styled-components";
// constants
import * as ROUTES from "../../../../../../../../../constants/routes";
// screens
import BookingsScreen from "../../../../../../../../../components/screens/BookingsScreen";
import getWebTabTitle from "../../../../../../../../../utilities/get-web-tab-title";

/**
 * Child of MainStack, contains bookings screen.
 */
const Stack = createStackNavigator();

const BookingsStackBase = ({ theme: { colors } }) => (

    <Stack.Navigator
        screenOptions={{
            headerShown: false,
            cardStyle: {
                backgroundColor: colors.background
            },
            title: getWebTabTitle('My Bookings'),
        }}
    >

        <Stack.Screen name={ROUTES.BOOKINGS.HOME} component={BookingsScreen}/>
    </Stack.Navigator>
);

const BookingsStack = withTheme(BookingsStackBase);

export default BookingsStack;
