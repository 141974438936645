import React, { useState } from 'react';
import { View, SafeAreaView, TouchableOpacity, StyleSheet } from 'react-native';
import Modal from 'react-native-modal';
import { useTheme } from 'styled-components';
import { useTheme as useThemeWeb } from "styled-components";
import PropTypes from 'prop-types';
import * as RootNavigation from '../../../navigation/RootNavigation';
// styles
import stylesheet from './stylesheet';
// atoms
import PText from '../../atoms/text/PText';
import H2Text from '../../atoms/text/H2Text';


// molecules/AdminNavModal - Option
const Option = ({ label, handler, style, txtStyle }) => (
    <TouchableOpacity
        onPress={handler}
        style={style}
    >
        <PText style={txtStyle}>{ label }</PText>
    </TouchableOpacity>
);


const AdminNavModal = ({ isVisible, handleHide, title, options }) => {

    /* hooks */
    // styled-components
    const theme = useTheme() || useThemeWeb();


    /* hooks:state */
    // chosen path
    const [chosenPath, setChosenPath] = useState(null);


    /* render */
    const styles = stylesheet(theme);

    return (
        <Modal
            isVisible={isVisible}
            onBackdropPress={handleHide}
            backdropOpacity={0.2}
            style={styles.modal}
            onModalHide={() => {
                // navigate to chosen path on modal hidden
                chosenPath && RootNavigation.navigate(chosenPath.path, chosenPath.params);

                // clear chosen path
                setChosenPath(null);
            }}
        >
            <SafeAreaView>
                <View style={styles.contentContainer}>
                    <View style={styles.innerContentContainer}>
                        {title && (
                            <View style={ styles.titleWrapper }>
                                <H2Text style={ styles.title }>{ title }</H2Text>
                            </View>
                        )}

                        {
                            options.map(({ label, path, params }, i) => {
                                const handler = () => {
                                    // hide modal
                                    handleHide();

                                    // store chosen path
                                    setChosenPath({ path, params });
                                };

                                return (
                                    <Option
                                        key={`option-${i}`}
                                        label={label}
                                        handler={handler}
                                        style={Object.assign({},
                                            styles.menuOption,

                                            // border radius on first option (if no title)
                                            (!title && i === 0) && {
                                                borderTopLeftRadius: theme.borderRadius,
                                                borderTopRightRadius: theme.borderRadius,
                                            },

                                            // border bottom on all but last option
                                            (i !== options.length-1) && {
                                                borderBottomWidth: StyleSheet.hairlineWidth
                                            },

                                            // border radius on last option
                                            (i === options.length-1) && {
                                                borderBottomLeftRadius: theme.borderRadius,
                                                borderBottomRightRadius: theme.borderRadius,
                                            }
                                        )}
                                        txtStyle={styles.optionTxt}
                                    />
                                )
                            })
                        }
                    </View>
                </View>
            </SafeAreaView>
        </Modal>
    )
};


AdminNavModal.propTypes = {
    isVisible: PropTypes.bool,
    handleHide: PropTypes.func.isRequired,
    title: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.exact({
        label: PropTypes.string,
        path: PropTypes.string,
        params: PropTypes.object
    })).isRequired
};

AdminNavModal.defaultProps = {
    isVisible: false
};


// exports
export default AdminNavModal;
