// firebase
import { firestore } from '../../firebase';
import { collection, onSnapshot, where, query, getDocs } from 'firebase/firestore';
// firebase doc classes
import User from '../../documents/User/User';

/**
 * Users
 */
export default class Users {

    /**
     * Users collection listener builder
     * @param {function} callback - on snapshot callback
     * @param  [options] - user options
     */
    static buildListener (callback, options) {
        // options = {
        //     populateCompany: (options?.populateCompany === true),
        //     companyOptions: (options?.companyOptions || null)
        // };

        // noinspection JSCheckFunctionSignatures
        let collRef = collection(firestore, 'users').withConverter(User.firestoreConverter);

        return onSnapshot(collRef, {
            next: async (snapshot) => {
                let users = snapshot.docs.map(doc => doc.data());

                // init each user with options
                // await Promise.all(users.map(user => user.init(options)));

                callback(new Users(users));
            }
        });
    }


    // /**
    //  * Users collection listener builder (with custom query)
    //  * @param {firebase.firestore.CollectionReference} query
    //  * @param onSnapshot
    //  */
    // static buildCustomListener = (query, onSnapshot) => {
    //     // noinspection JSCheckFunctionSignatures
    //     query = query.withConverter(User.firestoreConverter);
    //
    //     return query.onSnapshot(async (snapshot) => {
    //         const users = snapshot.docs.map(doc => doc.data());
    //
    //         onSnapshot(new Users(users));
    //     })
    // };


    static async build (options) {
        options = {
            includeDisabled: (options?.includeDisabled === true)
        };

        // get user docs from firestore
        let usersQuery = collection(firestore, 'users');

        if (!options.includeDisabled) {
            usersQuery = query(usersQuery, where('disabled', '==', false));
        }

        const snapshot = await getDocs(usersQuery.withConverter(User.firestoreConverter));

        if (snapshot.empty)
            throw new Error('no users found');

        // construct instances of User with converter
        const users = snapshot.docs.map(doc => doc.data());

        // // initialize user instances
        // users.forEach(user => user.init());

        // return instance of Users
        return new Users(users);
    }


    static async buildCustom (usersQuery) {
        // add firestore converter to query
        usersQuery = usersQuery.withConverter(User.firestoreConverter);

        const snapshot = await getDocs(usersQuery);

        if (snapshot.empty)
            throw new Error('no users found');

        // construct instances of User with converter
        const users = snapshot.docs.map(doc => doc.data());

        // return instance of Users
        return new Users(users);
    }


    constructor(users) {
        this._users = users;
    }


    // accessor methods (getters)
    // getUser (id) {
    //     return this._users.find(user => (user.id === id));
    // }

    get users () { return this._users; }

    get usersByCompany () {
        const usersByCompany = this._users.reduce((users, user) => {
            if (user.company !== null) {
                if (users.hasOwnProperty(user.company.id)) {
                    return {
                        ...users,
                        [user.company.id]: [
                            ...users[user.company.id],
                            user
                        ]
                    };
                } else {
                    return {
                        ...users,
                        [user.company.id]: [
                            user
                        ]
                    }
                }
            } else {
                return users;
            }
        }, {});

        return Object.keys(usersByCompany).reduce((companyUsers, companyId) => ({
            ...companyUsers,
            [companyId]: new Users(usersByCompany[companyId])
        }), {});
    }

    get activeUsers () {
        return this._users.filter(user => (user.disabled === false));
    }

    get disabledUsers () {
        return this._users.filter(user => (user.disabled === true));
    }
}
