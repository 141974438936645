/**
 * Recurring booking frequencies enum
 */
enum BookingFreqEnum {
    // e.g. every Monday
    WEEKLY = 'weekly',
    // e.g. the first Monday of every month
    MONTHLY_FIRST = 'monthly-1',
    // e.g. the second Thursday of every month
    MONTHLY_SECOND = 'monthly-2',
    MONTHLY_THIRD = 'monthly-3',
    MONTHLY_FOURTH = 'monthly-4',
    // e.g. the last Tuesday of every month
    MONTHLY_LAST = 'monthly-last'
}

export default BookingFreqEnum;
