import React, { forwardRef } from 'react';
import { useTheme } from 'styled-components';
import { StyleSheet, TextInput, TextInputProps } from 'react-native';
// atoms
import StyledTextInput from './StyledTextInput';

/**
 * Form text input.  Adds additional styles to our standard text input, specifically for when used in forms.
 */
const FormTextInput = forwardRef<TextInput, TextInputProps>(({
    style,
    ...props
}, ref) => {
    const styles = useStyles();

    // render
    return (
        <StyledTextInput style={[styles.input, style]}
                         ref={ref}
                         {...props}/>
    );
});

const useStyles = () => {
    const { colors, borderRadius } = useTheme();

    return StyleSheet.create({

        input: {

            borderColor: colors.text.light,
            borderWidth: StyleSheet.hairlineWidth,
            borderRadius: borderRadius,
        }
    });
}

export default FormTextInput;
