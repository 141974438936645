import * as React from "react"
import Svg, { Path } from "react-native-svg"
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';


// components
const CalendarPlusIconBase = ({ theme: { iconSizes }, width, height, size, ...props }) => (
    <Svg
        viewBox="0 0 448 512"
        width={width || iconSizes[size]}
        height={height || iconSizes[size]}

        {...props}
    >
        <Path d="M436 160H12c-6.6 0-12-5.4-12-12v-36c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48v36c0 6.6-5.4 12-12 12zM12 192h424c6.6 0 12 5.4 12 12v260c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V204c0-6.6 5.4-12 12-12zm316 140c0-6.6-5.4-12-12-12h-60v-60c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v60h-60c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h60v60c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12v-60h60c6.6 0 12-5.4 12-12v-40z" />
    </Svg>
);

const CalendarPlusIcon = withTheme(CalendarPlusIconBase);

CalendarPlusIcon.propTypes = {
    size: PropTypes.string
};

CalendarPlusIcon.defaultProps = {
    size: 'medium'
};


// exports
export default CalendarPlusIcon;