import React from 'react';
import { StyleSheet, Text, TextProps } from 'react-native';
import { useTheme } from 'styled-components';

interface Props extends TextProps {
    children: string;
}

/**
 * Header1 Text
 */
const H1Text = ({ style, children, ...props }: Props): JSX.Element => {
    const styles = useStyles();

    // render
    return (
        <Text style={[styles.text, style]} {...props}>
            {children}
        </Text>
    );
}

const useStyles = () => {
    const { fontSizes } = useTheme();

    return StyleSheet.create({

        text: {

            fontSize: fontSizes.h1,
        }
    });
}

export default H1Text;
