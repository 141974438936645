import React, { useState } from 'react';
import { Platform, SafeAreaView, StyleSheet } from 'react-native';
// hooks
import { useMountedState } from '../../../hooks/react-use';
// molecules
import ScreenHeader from '../../molecules/ScreenHeader';
// organisms
import NewBookingFlow from '../../organisms/NewBookingFlow';
// templates
import MasterTemplate from '../MasterTemplate';
// utils
import getSubheader, { DEFAULT_SUBHEADER } from '../../organisms/NewBookingFlow/get-subheader';

/**
 * New Booking Screen Template
 */
const NewBookingScreenTemplate = () => {
    const styles = useStyles();
    const isMounted = useMountedState();

    // screen sub header state
    const [ subHeader, setSubHeader ] = useState(DEFAULT_SUBHEADER);

    // handle change slide form data
    const handleBookingFlowChange = ({ slideIndex, data }) => {

        if (isMounted()) {

            setSubHeader(getSubheader(slideIndex, data, (Platform.OS === 'web')));
        }
    }

    // render
    return (
        <MasterTemplate>
            <SafeAreaView style={styles.container}>
                <ScreenHeader
                    title='New Booking'
                    subtitle={subHeader}
                    style={styles.header}
                />

                <NewBookingFlow onChange={handleBookingFlowChange}/>
            </SafeAreaView>
        </MasterTemplate>
    );
}

const useStyles = () => {

    return StyleSheet.create({

        container: {

            flex: 1,
        },

        header: {

            marginBottom: Platform.OS !== 'web' && 0,
        }
    });
}

export default NewBookingScreenTemplate;
