import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { NavigatorScreenParams } from '@react-navigation/native';
// navigation
import AppStack, {AppStackParamList} from './stacks/app/AppStack.web';
import AuthStack from "./stacks/auth/AuthStack";
// firebase
import AuthUser from "../../../firebase/documents/User/AuthUser";
// auth
import { withAuthUser } from "../../../Session/context";

interface Props {
    authUser: AuthUser;
}

export type RootStackParamList = {
    app: NavigatorScreenParams<AppStackParamList>;
    auth: undefined;
}

/**
 * Root navigation stack, contains everything
 */
const Stack = createStackNavigator<RootStackParamList>();

const RootStack = ({ authUser }: Props) => (

    <Stack.Navigator>
        {
            authUser ? (
                <Stack.Screen
                    name='app'
                    options={{
                        headerShown: false
                    }}
                    component={AppStack}
                />
            ) : (
                <Stack.Screen
                    name='auth'
                    component={AuthStack}
                    options={{
                        headerShown: false
                    }}
                />
            )
        }
    </Stack.Navigator>
);

export default withAuthUser(RootStack);
