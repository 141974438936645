import React, { PropsWithChildren } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { DefaultTheme, useTheme } from 'styled-components';

type Props = PropsWithChildren<{
    padding?: boolean;
    style?: StyleProp<ViewStyle>;
}>;

/**
 * Card
 */
const Card = ({
    padding = true,
    style,
    ...props
}: Props): JSX.Element => {
    const theme = useTheme();
    const styles = stylesheet(theme, padding);

    // render
    return (
        <View
            style={[styles.card, style]}

            {...props}
        />
    );
}

const stylesheet = (
    { spacing, shadow, colors, borderRadius }: DefaultTheme,
    cardHasPadding: boolean
) => StyleSheet.create({

    card: {

        padding: cardHasPadding ? spacing.medium : 0,

        ...shadow.medium,
        backgroundColor: colors.light,
        borderRadius: borderRadius,
    }
});

export default Card;
