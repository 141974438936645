import React, { useMemo } from 'react';
import { SafeAreaView, ScrollView, StyleSheet } from 'react-native';
import { useTheme } from "styled-components";
import { useNavigation } from '@react-navigation/native';
// firebase
import AuthUser from '../../../../firebase/documents/User/AuthUser';
// atoms
import Card from '../../../atoms/Card';
import StyledButton from '../../../atoms/StyledButton';
import C2QBBtn from '../../../atoms/C2QBBtn';
import UserEditIcon from '../../../atoms/icons/UserEditIcon';
// atoms - text
import H4Text from '../../../atoms/text/H4Text';
import H3Text from '../../../atoms/text/H3Text';
import StrongText from '../../../atoms/text/StrongText';
// molecules
import ScreenHeader, {HeaderAction} from '../../../molecules/ScreenHeader';
// templates
import MasterTemplate from '../../MasterTemplate';
// constants
import * as ROUTES from '../../../../constants/routes';
// types
import type { IntuitConnectionInfo } from '../../../../types/intuit';
// firebase
import User from '../../../../firebase/documents/User/User';

interface Props {
    user: AuthUser;
    intuitConnectionInfo: null | false | { info: IntuitConnectionInfo, connectedBy: User };
}

/**
 * Settings Screen Template
 *
 * @param user
 * @param userIsIntuitAuthed - null when undetermined, boolean once known
 */
const SettingsScreenTemplate = ({ user, intuitConnectionInfo }: Props): JSX.Element => {
    const styles = useStyles();
    const navigation = useNavigation();

    const handlePressEditProfile = () => {

        // @ts-ignore
        navigation.navigate(ROUTES.SETTINGS.EDIT_PROFILE);
    }

    const headerActions = useMemo<HeaderAction[]>(() => [
        { label: 'Edit Profile', icon: UserEditIcon, onPress: handlePressEditProfile }
    ], []);

    // only show intuit connection info if it's being fetched, or it is connected
    const showIntuitConnectionStatus = intuitConnectionInfo === null || (intuitConnectionInfo !== false && intuitConnectionInfo.info.isConnected);

    // render
    return (
        <MasterTemplate>
            <SafeAreaView style={styles.container}>
                <ScreenHeader title={"Settings"} actions={headerActions}/>

                <ScrollView style={styles.scrollView}>

                    <H3Text style={styles.cardLabel}>Tenant Profile</H3Text>
                    <Card style={styles.card}>
                        <H4Text style={styles.userAttr}>
                            <StrongText>Company: </StrongText>{ user.company.name }
                        </H4Text>

                        <H4Text style={styles.userAttr}>
                            <StrongText>Name: </StrongText>{ user.name }
                        </H4Text>

                        <H4Text style={styles.userAttr}>
                            <StrongText>Email: </StrongText>{ user.email }
                        </H4Text>

                        {/*<H4Text>Password</H4Text>*/}
                        {/*<StyledTextInput*/}
                        {/*    type='password'*/}
                        {/*    value='          '*/}
                        {/*    style={styles.textInput}*/}
                        {/*    autoCompleteType='password'*/}
                        {/*    secureTextEntry={true}*/}
                        {/*    textContentType='newPassword'*/}
                        {/*/>*/}

                        {/*<H4Text style={ styles.inputLabel }>Room</H4Text>*/}
                        {/*<PText>{ user.room }</PText>*/}
                    </Card>

                    {user.isAdmin && (
                        <>
                            <H3Text style={styles.cardLabel}>
                                QuickBooks Integration
                            </H3Text>
                            <Card style={styles.card}>
                                {intuitConnectionInfo !== null && (
                                    // show connect to QB button once status is known
                                    <C2QBBtn style={styles.quickbooksBtn} />
                                )}

                                {intuitConnectionInfo === null ? (
                                    <H4Text>Checking connection status...</H4Text>
                                ) : (intuitConnectionInfo !== false && intuitConnectionInfo.info.isConnected) ? (
                                    <H4Text style={styles.connectionStatus}>
                                        <StrongText>Connected successfully </StrongText>
                                        by {intuitConnectionInfo.connectedBy.name}{' '}
                                        on {intuitConnectionInfo.info.createdAt.toDate().toLocaleDateString()}.
                                        Connection last refreshed: {intuitConnectionInfo.info.updatedAt.toDate().toLocaleString()}
                                    </H4Text>
                                ) : null}
                            </Card>
                        </>
                    )}

                    <StyledButton
                        style={styles.signOutBtn}
                        title='Sign Out'
                        outline={true}
                        onPress={user.signOut}
                    />
                </ScrollView>
            </SafeAreaView>
        </MasterTemplate>
    );
};

const useStyles = () => {
    const { spacing } = useTheme();

    return StyleSheet.create({
        container: {

            flex: 1
        },

        scrollView: {

            paddingHorizontal: spacing.medium
        },

        cardLabel: {

            marginBottom: spacing.xSmall,
        },

        card: {

            marginBottom: spacing.medium,
        },

        userAttr: {

            marginBottom: spacing.small,
        },

        quickbooksBtn: {},

        connectionStatus: {

            marginTop: spacing.medium,
        },

        signOutBtn: {

            marginBottom: spacing.medium,
        }
    });
}

export default SettingsScreenTemplate;
