import isEmail from 'validator/es/lib/isEmail';
// utilities
import { toTitleCase } from '../../../utilities/to-title-case';
// firebase
import { firestore } from '../../firebase';
import { doc, onSnapshot, getDoc, updateDoc, getDocs, query, collection, where } from "firebase/firestore";
import Bookings from '../../collections/Bookings/Bookings';
import Company from '../Company/Company';
import callableFunctions from "../../functions/callable-functions";
// superclass
import FirestoreDoc from '../FirestoreDoc';
// constants
import * as ROLES from '../../../constants/roles';
import {userAlert} from "../../../utilities/alert";

/**
 * User Firestore doc class
 */
export default class User extends FirestoreDoc {

    static buildListener = (id, callback) => {
        let userDocRef = doc(firestore, 'users', id).withConverter(User.firestoreConverter);

        return onSnapshot(userDocRef, async (snapshot) => {
            if (snapshot.exists()) {
                const user = snapshot.data();

                return callback(user);
            }

            return callback(null);
        })
    };

    static build = async (id, options) => {
        options = {
            populateCompany: (options?.populateCompany === true)
        };

        const snapshot = await getDoc(doc(firestore, 'users', id).withConverter(User.firestoreConverter));

        if (!snapshot.exists()) {

            throw new Error('user not found');
        }

        // construct User instance with converter
        const user = snapshot.data();

        // initialize user instance with options
        await user.init(options);

        // return instance of User
        return user;
    };


    static create = ({ email, ...extraData }) => {
        // if (!email)
        //     throw new Error('Tenants email is required');
        //
        // if (!extraData.name)
        //     throw new Error('Tenants name is required');
        //
        // if (!extraData.company)
        //     throw new Error('Tenants company name is required');

        return callableFunctions.user.newUser({ email, extraData });
    };


    static firestoreConverter =  {
        fromFirestore: (snapshot, options) => {
            const { createdAt, updatedAt, ...data } = snapshot.data(options);

            return new User(
                snapshot.ref,
                snapshot.id,

                createdAt,
                updatedAt,

                data
            )
        }
    };

    constructor(ref, id, createdAt, updatedAt, {
        company, email, name, roles, disabled, activated, activationCode
    }) {
        super(ref, id, createdAt, updatedAt);

        this._companyRef = company;
        this._email = email;
        this._name = name;
        this._roles = roles;
        this._disabled = (disabled === true);
        this._activated = (activated === true);
        this._activationCode = activationCode;

        this._company = null;
    }


    init = async ({ populateCompany }) => {

        if (populateCompany) {
            await this.populateCompany();
        }
    };

    populateCompany = async () => {
        if (this._companyRef) {
            // get company doc instance
            const company = await Company.build(this._companyRef.id);

            if (company)
                this._company = company;
        }

        return this._company;
    };


    /**
     * Disable user in db (firebase func triggers to disable auth account too)
     */
    async disable() {
        try {
            await updateDoc(this.ref, {
                disabled: true,
            })

        } catch (err) {
            // console.error('failed to disable user...', err);

            // throw new Error(`Failed to disable user [${err.message}]`);
            throw err;
        }
    };

    /**
     * Enable user in db (firebase func triggers to enable auth account too)
     */
    enable = async () => {
        try {
            await updateDoc(this.ref, {
                disabled: false,
            })

        } catch (err) {
            // console.error('failed to enable user...', err);

            throw err;
        }
    };


    update = async ({ name, email }) => {

        let data = {};

        // validation
        if (email && email !== this.email) {
            // is valid email
            if (!isEmail(email)) {

                throw new Error('Invalid email address!');
            }

            // ensure email not already in use by another account
            let userIdWithEmail;
            try {

                const response = await callableFunctions.user.checkEmailInUse({ email });
                userIdWithEmail = response.data.userIdWithEmail;
            } catch (err) {

                throw new Error('Failed to validate email, please try again!');
            }

            if (userIdWithEmail && userIdWithEmail !== this.id) {

                throw new Error('Email already in use!');
            }

            if (this.isCompanyOwner) {
                // company owner, need to ensure email can be matched to customer in quickbooks

                /**
                 * @type {boolean}
                 */
                let qboCustomerFound;
                try {
                    const response = await callableFunctions.company.validateOwnerEmail({ email });
                    qboCustomerFound = response.data;
                } catch (err) {

                    if (err.code === 'functions/permission-denied') {

                        throw new Error('You must first connect to QuickBooks from the settings screen before you can edit a company admin email');
                    }

                    throw new Error(err.message || 'Failed to validate email with QuickBooks, please try again!');
                }

                if (!qboCustomerFound) {
                    throw new Error('A matching QuickBooks customer could not be found for this email address.  Please update the tenants email in QuickBooks before setting it here.');
                }
            }

            data.email = email;
        }

        if (name) {

            data.name = name;
        }


        // update user in firestore
        try {
            await updateDoc(this.ref, data);

        } catch (err) {

            throw err;
        }
    };



    initBookingsListener = (callback) => {

        const bookingsQuery = query(collection(firestore, 'bookings'),
            where('user', '==', this.ref)
        );

        return Bookings.buildCustomListener(bookingsQuery, callback, { populateRooms: true });
    };

    // loadCompanyData = async () => {
    //     // const company = await Company.build(this._companyRef.id);
    // };

    // /**
    //  *
    //  * @return {Promise<boolean>}
    //  */
    // async isIntuitAuthed() {
    //
    //     let intuitConnectionInfo;
    //     try {
    //         const snapshot = await getDoc(doc(collection(firestore, 'intuit'), 'connection'));
    //         if (snapshot.exists()) {
    //             intuitConnectionInfo = snapshot.data();
    //         }
    //     } catch (err) {
    //         return false;
    //     }
    //
    //     return intuitConnectionInfo?.isConnected || false;
    // }


    // *** Accessor Methods ***
    get name () { return this._name; }
    get email () { return this._email; }
    get companyRef () { return this._companyRef; }
    get company () { return this._company; }
    // get room () { return this._room; }
    // get quotaHours () { return this._quotaHours; }
    get disabled () { return this._disabled; }
    get isActivated () { return this._activated; }
    get activationCode () { return this._activationCode; }

    get isCompanyOwner() {

        if (!this._company) {

            throw new Error('user company not populated');
        }

        return this._company.ownerRef.id === this.id
    }

    get roleNames () {
        return this._roles ? Object.keys(this._roles).map(role => toTitleCase(role)) : []
    }

    get roleNamesStr () {
        return this.roleNames.join(', ');
    }

    get isAdmin () {
        return this._roles && !!this._roles[ROLES.ADMIN];
    }


    // *** Mutator Methods ***
    set company (company) {
        this._company = company;
    }

    // getBookings = async () => {
    //     let bookings;
    //     try {
    //         const query = firestore
    //             .collection('bookings')
    //             .where('user', '==', this.ref);
    //
    //         bookings = await Bookings.buildCustom(query, { populateRooms: true });
    //
    //     } catch (err) {
    //         console.log('failed to get user bookings...', err);
    //
    //         throw err;
    //     }
    //
    //     return bookings;
    // };

}
