import * as firebase from "firebase/app";
// superclass
import FirestoreDoc from '../FirestoreDoc';
// firebase
import { firestore, functions } from '../../firebase';
import {collection, doc, getDoc, onSnapshot} from "firebase/firestore";
import callableFunctions from "../../functions/callable-functions";
// firebase documents
import User from '../User/User';
// constants
import { MONTHS } from '../../../constants/dates';

/**
 * Invoice entity
 */
export default class Invoice extends FirestoreDoc {


    static async build (month, year, options) {

        options = {
            populateCreatedBy: (options?.populateCreatedBy === true),
            populateCompaniesNoQbo: (options?.populateCompaniesNoQbo === true)
        };

        // get invoice doc from firestore
        const snapshot = await getDoc(
            doc(firestore, 'invoices', `${month}-${year}`).withConverter(this.firestoreConverter)
        );

        if (!snapshot.exists()) {

            throw new Error('invoice not found');
        }

        // construct Invoice instance with converter
        const invoice = snapshot.data();

        // initialize Invoice instance
        await invoice.init(options);

        // return initialized instance of Invoice
        return invoice;
    }

    static startCollListener(options, onNext, onError) {

        options = {
            populateCreatedBy: (options?.populateCreatedBy === true),
            populateCompaniesNoQbo: (options?.populateCompaniesNoQbo === true),
            populateSentInvoicesCompanies: (options?.populateSentInvoicesCompanies === true)
        };

        const collRef = collection(firestore, 'invoices').withConverter(this.firestoreConverter);
        return onSnapshot(collRef, async (snapshot) => {

            const invoices = await Promise.all(snapshot.docs.map(snapshot => snapshot.data().init(options)));

            onNext(invoices);
        }, (err) => onError(err));
    }

    static firestoreConverter = {
        fromFirestore: (snapshot, options) => {
            const { createdAt, updatedAt, ...data } = snapshot.data(options);

            return new Invoice(
                snapshot.ref,
                snapshot.id,

                createdAt,
                updatedAt,

                data
            );
        }
    };

    static create = async (month, year) => {
        // attempt to send invoices
        await callableFunctions.invoice.sendInvoices({ month, year });

        return await Invoice.build(month, year, {
            populateCompaniesNoQbo: true
        });
    };


    constructor(ref, id, createdAt, updatedAt, { createdBy, sentInvoices, companiesNoQbo }) {
        super(ref, id, createdAt, updatedAt);

        this._createdByRef = createdBy;
        this._rawSentInvoices = sentInvoices;
        this._companiesNoQboRefs = companiesNoQbo;

        this._createdBy = null;
        this._companiesNoQbo = null;

        this._isInitialized = false;
    }

    init = async ({ populateCreatedBy, populateCompaniesNoQbo, populateSentInvoicesCompanies }) => {
        if (!this._isInitialized) {
            if (populateCreatedBy)
                this._createdBy = await this.getCreatedBy();

            if (populateCompaniesNoQbo)
                await this.getCompaniesNoQbo();

            if (populateSentInvoicesCompanies) {
                await this.populateSentInvoicesCompanies();
            }

            this._isInitialized = true;
        }

        return this;
    };


    // accessor methods (getters)
    get dateStr() {
        const idSplit = this.id.split('-');

        return `${MONTHS[idSplit[0]]} ${idSplit[1]}`;
    }

    get createdBy () {
        return this._createdBy;
    }

    get sentInvoicesCount() {
        return this._rawSentInvoices.length || 0;
    }

    get sentInvoices () {
        return this._sentInvoices;
    }

    get companiesNoQbo () {
        return this._companiesNoQbo;
    }

    get companiesNoQboCount () {
        return this._companiesNoQbo.length || 0;
    }


    getCreatedBy = async () => {
        if (this._createdBy) {
            // createdBy user already stored
            return this._createdBy;

        } else {
            // createdBy user not stored, get user
            const snapshot = await getDoc(this._createdByRef.withConverter(User.firestoreConverter));

            if (!snapshot.exists()) {

                throw new Error('createdBy user not found');
            }

            return snapshot.data();
        }
    };

    getCompaniesNoQbo = async () => {
        if (this._companiesNoQbo) {
            return this._companiesNoQbo;

        } else {
            const companyGetReqs = this._companiesNoQboRefs.map(({ company: companyRef }) =>
                getDoc(companyRef.withConverter(User.firestoreConverter)));

            const snapshots = await Promise.all(companyGetReqs);

            const companiesNoQbo = snapshots.map((snapshot, index) => ({
                ...this._companiesNoQboRefs[index],
                company: snapshot.data()
            }));

            this._companiesNoQbo = companiesNoQbo;
            return companiesNoQbo;
        }
    }

    async populateSentInvoicesCompanies() {

        if (!this._sentInvoices) {

            const companyGetReqs = this._rawSentInvoices.map(({ company: companyRef }) =>
                getDoc(companyRef.withConverter(User.firestoreConverter)));

            const snapshots = await Promise.all(companyGetReqs);

            this._sentInvoices = snapshots.map((snapshot, index) => ({
                ...this._rawSentInvoices[index],
                company: snapshot.data()
            }));
        }
    }
}
