
// *** auth stack ***
// auth stack screens
export const SIGN_IN = 'SignInScreen';
export const REGISTER = 'Register';
export const ACTIVATION = 'ActivationScreen';
export const FORGOT_PASSWORD = 'ForgotPassword';



// *** main stack ***
// HomeStack screens
export const HOME = {
    ROOT: 'Home',
    HOME: 'HomeScreen'
} as const;

export const BOOKINGS = {
    ROOT: 'Bookings',
    HOME: 'BookingsScreen'
} as const;

// NewBookingStack screens
export const NEW_BOOKING = {
    ROOT: 'NewBooking',
    HOME: 'NewBookingScreen',
} as const;
export const NEW_BOOKING_OLD = 'NewBookingScreenOld';
// export const NEW_BOOKING = {
//     HOME: 'NewBookingScreen'
// };

// SettingsStack screens
export const SETTINGS = {
    ROOT: 'Settings',
    HOME: 'SettingsScreen',
    EDIT_PROFILE: 'EditProfileScreen',
} as const;

export const ADMIN = {
    BOOKINGS: {
        ROOT: 'AdminBookings',
        HOME: 'AdminBookingsScreen',
    },
    USERS: {
        ROOT: 'AdminUsers',
        HOME: 'AdminUsersScreen',
        INVOICE_USER: 'AdminInvoiceUserScreen',
        EDIT: 'AdminEditUserScreen',
        NEW: 'AdminNewUserScreen',
        INVOICE: 'AdminInvoiceScreen'
    },
    COMPANY: {
        NEW: 'AdminNewCompanyScreen',
        EDIT: 'AdminEditCompanyScreen',
        ADD_USER: 'AdminAddUserScreen'
    }
} as const;

export const INTUIT_OAUTH = {
    REDIRECT: 'IntuitOauthRedirectScreen'
} as const;
