import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { useMountedState } from "../../../hooks/react-use";
// firebase
import Company from '../../../firebase/documents/Company/Company';
// atoms
import LoadingSpinner from '../../atoms/LoadingSpinner';
// templates
import AdminAddUserScreenTemplate from '../../templates/screens/admin/AdminAddUserScreenTemplate';
// utils
import { userAlert } from "../../../utilities/alert";

/**
 * Admin Add User Screen
 *
 * @returns {JSX.Element}
 */
const AdminAddUserScreen = ({ route: { params: { companyId } } }) => {
    const isMounted = useMountedState();

    // company state
    const [ company, setCompany ] = useState(null);

    // get company
    useEffect(() => {
        (async () => {
            let c;
            try {
                c = await Company.build(companyId);

            } catch (err) {

                userAlert(
                    'Failed to Load',
                    `Failed to load Company data, please try again [${err.message}].`
                );
                return;
            }

            if (isMounted()) {

                setCompany(c);
            }

        })();
    }, [ companyId ]);

    // render
    return company ? (
        <AdminAddUserScreenTemplate company={company} />
    ) : (
        <View style={{ flex: 1 }}>
            <LoadingSpinner/>
        </View>
    );
};

export default AdminAddUserScreen;
