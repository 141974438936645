import { DateTime } from "luxon";
// superclass
import FirestoreDoc from '../FirestoreDoc';
// firebase
import { firestore } from '../../firebase';
import { collection, doc, getDoc, addDoc, getDocs, query, where, updateDoc, serverTimestamp } from "firebase/firestore";
import Rooms from '../../collections/Rooms/Rooms';
// utils
import getStartEndTimes from '../../../utilities/get-start-end-times';
import { userAlert } from "../../../utilities/alert";
import dateMatchesRecurringBooking from "../../../utilities/date-matches-recurring-booking";
import dtSecsSinceDayStart from "../../../utilities/dt-secs-since-day-start";
// constants
import { SHORT_DAYS, SHORT_MONTHS, DAYS } from '../../../constants/dates';
// enums
import BookingFreqEnum from "../../../enums/BookingFreqEnum";
import bookingFreqLabel from "../../../utilities/booking-freq-label";
import firebase from "firebase/compat";

/**
 * Booking entity class
 */
export default class Booking extends FirestoreDoc {


    /**
     * single instance builder
     * @param {string} id - booking id
     * @param {object} [options] - build options
     * @param {boolean} [options.populateUser=false] - populate user
     * @param {boolean} [options.populateRooms=false] - populate rooms
     * @return {Promise<Booking>}
     */
    static async build (id, options) {
        // options default values
        options = {
            populateUser: (options?.populateUser === true),
            populateRooms: (options?.populateRooms === true)
        };


        const bookingDocPath = `bookings/${id}`;

        // get booking doc from firestore
        // noinspection JSCheckFunctionSignatures
        const snapshot = await getDoc(doc(firestore, bookingDocPath).withConverter(this.firestoreConverter));

        if (!snapshot.exists())
            throw new Error('booking not found');

        // construct BookingDoc instance with converter
        const bookingDoc = snapshot.data();

        // initialize booking doc
        await bookingDoc.init(options);

        // return initialized instance of BookingDoc
        return bookingDoc;
    }


    // firestore doc converter
    static firestoreConverter = {
        fromFirestore: (snapshot, options) => {
            const { createdAt, updatedAt, ...data } = snapshot.data(options);

            return new Booking(
                snapshot.ref,
                snapshot.id,

                createdAt,
                updatedAt,

                data
            );
        }
    };

    static async create (userId, companyId, rooms, date, times, needSmartBoard, notes, recurringFreq) {
        // validate required values
        if (!userId)
            throw new Error('An error has occurred, please try again');

        if (!companyId)
            throw new Error('Please choose the company that you are making this booking for');

        if (rooms.length < 1)
            throw new Error('Please choose a room');

        if (!date.toISOString())
            throw new Error('Please choose a date');

        if (times.length < 1)
            throw new Error('Please choose at least 1 time');


        // convert date and times to startAt and endAt
        const { startAt: startAtTime, endAt: endAtTime } = getStartEndTimes(date, times);
        const startAt = new Date(
            date.getFullYear(), date.getMonth(), date.getDate(),
            startAtTime.hours, startAtTime.mins,
            0, 0
        );
        const endAt = new Date(
            date.getFullYear(), date.getMonth(), date.getDate(),
            endAtTime.hours, endAtTime.mins,
            0, 0
        );

        if (startAt < new Date()) {
            throw new Error('Selected date/time occurs in the past.');
        }

        // convert room names to their firestore refs
        const roomRefs = rooms.map(room => doc(firestore, 'rooms', room));

        // check date & time are still available
        let isAvailable;
        try {
            const bookingsBetweenTimes = await Booking._getRoomBookingsBetween(roomRefs, startAt, endAt);

            isAvailable = (bookingsBetweenTimes.length < 1);

        } catch (error) {
            console.log('failed to get room bookings between times...', error);

            throw new Error('Failed to check time availability, please try again');
        }

        if (!isAvailable) {
            throw new Error('Time no longer available');
        }

        // time is still available, create booking
        const userRef = doc(firestore, 'users', userId);
        const companyRef = doc(firestore, 'companies', companyId);
        const bookingData = Object.assign({},
            { // required
                user: userRef,
                company: companyRef,

                rooms: roomRefs,
                startAt,
                endAt,

                cancelled: false,
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp()
            },
            // optional
            needSmartBoard && { needSmartBoard },
            notes && { notes },
            recurringFreq && { recurring: { freq: recurringFreq, active: true } }
        );


        let bookingRef;
        try {
            bookingRef = await addDoc(collection(firestore, 'bookings'), bookingData);

        } catch (err) {
            // console.error(err);

            throw new Error(`Failed to create new booking, please try again or contact reception.  [${err.message}]`);
        }


        // booking created successfully, build Booking instance
        return Booking.build(bookingRef.id, { populateRooms: true });
    }

    /**
     * Get bookings for given room between given times
     *
     * @param {firebase.firestore.DocumentReference[]} roomRefs - rooms to check
     * @param {Date} startAt - earliest time to check
     * @param {Date} endAt - latest time to check
     * @return {Promise<Booking[]>}
     * @private
     */
    static async _getRoomBookingsBetween(
        roomRefs,
        startAt,
        endAt
    ) {

        const dtStartAt = DateTime.fromJSDate(startAt);
        const dtEndAt = DateTime.fromJSDate(endAt);

        const bookingsCollRef = collection(firestore, 'bookings');

        const snapshot = await getDocs(query(bookingsCollRef,
            where('startAt', '>=', dtStartAt.startOf('day').toJSDate()),
            where('startAt', '<=', dtStartAt.endOf('day').toJSDate()),
            where('rooms', 'array-contains-any', roomRefs),
            where('cancelled', '==', false)
        ).withConverter(Booking.firestoreConverter));

        // https://stackoverflow.com/questions/13513932/algorithm-to-detect-overlapping-periods
        const bookingsBetween = snapshot.docs.map(doc => doc.data()).filter(booking =>
            booking.dtEndAt > dtStartAt &&
            booking.dtStartAt < dtEndAt
        );

        const recurringSnapshot = await getDocs(query(bookingsCollRef,
            where('recurring.active', '==', true),
            where('startAt', '<', dtStartAt.startOf('day').toJSDate()),
            where('rooms', 'array-contains-any', roomRefs)
        ).withConverter(Booking.firestoreConverter));

        const recurringBookings = recurringSnapshot.docs.map(doc => doc.data());

        recurringBookings.forEach((booking) => {

            if (
                // must match same weekday
                dtStartAt.weekday === booking.dtStartAt.weekday &&
                // must match same time
                dtSecsSinceDayStart(dtStartAt) < dtSecsSinceDayStart(booking.dtEndAt) &&
                dtSecsSinceDayStart(booking.dtStartAt) < dtSecsSinceDayStart(dtEndAt)
            ) {

                if (
                    // weekly recurring, so we know it'll clash
                    (booking.recurringFreq === BookingFreqEnum.WEEKLY) ||
                    // check if given date collides with a recurring booking occurrence date
                    dateMatchesRecurringBooking(dtStartAt, booking)
                ) {

                    bookingsBetween.push(booking);
                }
            }
        });

        return bookingsBetween;
    };

    /**
     * Get time string in format like "10:00 - 10:30" for given start and end dates
     *
     * @param {Date} startAt
     * @param {Date} endAt
     * @return {string} - str representing the time period between given dates
     */
    static getTimeStr(startAt, endAt) {

        return (
            startAt.getHours() + ':' + ('0'+startAt.getMinutes()).slice(-2) +
            ' - ' +
            endAt.getHours() + ':' + ('0'+endAt.getMinutes()).slice(-2)
        );
    }

    constructor(ref, id, createdAt, updatedAt, { company, user, rooms, startAt, endAt, notes, needSmartBoard, recurring }) {
        super(ref, id, createdAt, updatedAt);

        // data
        this._companyRef = company;
        this._userRef = user;
        this._roomRefs = rooms;
        this._startAt = startAt.toDate();
        this._endAt = endAt.toDate();
        // this._time = new Date(time.seconds*1000);
        this._notes = notes;
        this._needSmartBoard = needSmartBoard;
        this._recurring = recurring;

        this._company = null;
        this._user = null;
        this._rooms = null;

        this._isInitialized = false;
    }

    async init({ populateCompany, populateUser, populateRooms }) {
        if (!this._isInitialized) {
            if (populateCompany) {

                await this.getCompany();
            }

            if (populateUser) {

                this._user = await this.getUser();
            }

            if (populateRooms) {

                this._rooms = await this.getRooms();
            }

            this._isInitialized = true;
        }
    };


    getCompany = async () => {
        if (this._company) {
            // company already stored
            return this._company;

        } else {
            // company not stored, get company from db
            const snapshot = await getDoc(this._companyRef);

            if (!snapshot.exists()) {

                throw new Error('booking company not found');
            }

            this._company = snapshot.data();
            return snapshot.data();
        }
    };


    getUser = async () => {
        if (this._user) {
            // user already stored
            return this._user;

        } else {
            // user not stored, get user from db
            const snapshot = await getDoc(this._userRef);

            if (!snapshot.exists()) {

                throw new Error('booking user not found');
            }

            return snapshot.data();
        }
    };

    getRooms = async () => {
        if (this._rooms) {
            // rooms already stored

            return this._rooms;
        } else {
            // rooms not stored, get rooms from db

            let rooms;
            try {
                rooms = await Rooms.buildRefs(this._roomRefs, { populate: false });
            } catch (err) {

                return null;
            }

            return rooms;
        }
    };

    async cancel(isAdmin) {
        // only admins can cancel bookings in < 1 hour/in the past
        if (!isAdmin) {
            // validate booking time

            const msTillBooking = this._startAt - new Date();
            if (msTillBooking < 0) {
                // booking is in the past

                return userAlert(
                    'Failed to Cancel Booking',
                    'This booking occurs in the past.  If you feel this booking was a mistake, please contact reception.'
                );
            } else if (msTillBooking < 1000*60*60) { // 1 hour = 3,600,000 ms
                // booking is in less than an hour

                return userAlert(
                    'Failed to Cancel Booking',
                    'Sorry, we were unable to cancel booking as it is within the next hour'
                );
            }
        }

        // cancel booking
        try {
            await updateDoc(this.ref, {
                cancelled: true
            });

        } catch (err) {
            console.error('failed to cancel booking: ', err);

            throw new Error(`Failed to cancel booking!  Please try again or contact reception [${err.message}]`);
        }
    }

    async cancelNextReoccurrence(isAdmin) {
        // only admins can cancel bookings in < 1 hour/in the past
        if (!isAdmin) {
            // validate booking time

            if (this._dtNextReoccurrence < DateTime.now()) {
                // booking is in the past

                return userAlert(
                    'Failed to Cancel Booking Reoccurrence',
                    'This booking occurs in the past.  If you feel this booking was a mistake, please contact reception.'
                );
            } else if (this._dtNextReoccurrence.diffNow('hours') < 1) {
                // booking is in less than an hour

                return userAlert(
                    'Failed to Cancel Booking Reoccurrence',
                    'Sorry, we were unable to cancel booking as it is within the next hour'
                );
            }
        }

        // cancel next booking reoccurrence
        try {
            await updateDoc(this.ref, {
                'recurring.cancelledReoccurrences': firebase.firestore.FieldValue.arrayUnion(
                    this._dtNextReoccurrence.toJSDate()
                )
            });
        } catch (err) {
            console.error('failed to cancel next booking reoccurrence: ', err);

            throw new Error(`Failed to cancel next booking reoccurrence!  Please try again or contact reception [${err.message}]`);
        }
    }


    // accessor methods (getters)
    // get _timeAsUnix () {
    //     return Math.floor(this._time.getTime() / 1000);
    // }
    get _startAtUnix () {
        return Math.floor(this._startAt.getTime() / 1000);
    }
    get _endAtUnix () {
        return Math.floor(this._endAt.getTime() / 1000);
    }
    get _nowUnix () {
        return Math.floor(new Date().getTime() / 1000);
    }
    get _startAtToday () {
        const now = new Date();
        return new Date(
            now.getFullYear(), now.getMonth(), now.getDate(),
            this._startAt.getHours(), this._startAt.getMinutes(),
            0, 0
        );
    }


    get isInPast () {
        return (!this.isOngoing && !this.isUpcoming);
    }

    get isOngoing () {
        // const currentSlotTimes = getCurrentSlot();
        // const currentSlotStart = Math.floor(currentSlotTimes.start.getTime() / 1000);
        // return (this._timeAsUnix === currentSlotStart);

        return (this._startAtUnix <= this._nowUnix && this._endAtUnix >= this._nowUnix);
    }

    get isUpcoming () {
        // const currentSlotTimes = getCurrentSlot();
        // const currentSlotEnd = Math.floor(currentSlotTimes.end.getTime() / 1000);
        // return (this._timeAsUnix >= currentSlotEnd);

        return (this._startAtUnix > this._nowUnix);
    }

    get isToday () {
        // const now = new Date();
        // const bookingTimeToday = new Date(
        //     now.getFullYear(), now.getMonth(), now.getDate(),
        //     this._time.getHours(), this._time.getMinutes(),
        //     0, 0
        // );
        //
        // return (bookingTimeToday.getTime() === this._timeAsUnix*1000);


        return (this._startAtToday.getTime() === this._startAt.getTime());
    }

    get isTomorrow () {
        // const now = new Date();
        // const tomorrow = new Date(
        //     now.getFullYear(), now.getMonth(), now.getDate()+1,
        //     this._time.getHours(), this._time.getMinutes(),
        //     0, 0
        // );
        //
        // return (tomorrow.getTime() === this._timeAsUnix*1000);


        const now = new Date();
        const bookingStartAtTomorrow = new Date(
            now.getFullYear(), now.getMonth(), now.getDate()+1,
            this._startAt.getHours(), this._startAt.getMinutes(),
            0, 0
        );

        return (bookingStartAtTomorrow.getTime() === this._startAt.getTime());
    };

    get daysUntil () {
        if (this.isUpcoming) {
            // const now = new Date();
            // const bookingTimeToday = new Date(
            //     now.getFullYear(), now.getMonth(), now.getDate(),
            //     this._time.getHours(), this._time.getMinutes(),
            //     0, 0
            // );
            //
            // const diffSecs = this._timeAsUnix - Math.floor(bookingTimeToday.getTime() / 1000);
            // return Math.floor(diffSecs / (60*60*24));

            const now = new Date();
            const midnightToday = new Date(
                now.getFullYear(), now.getMonth(), now.getDate(),
                0, 0, 0, 0
            );
            const midnightStartAt = new Date(
                this._startAt.getFullYear(), this._startAt.getMonth(), this._startAt.getDate(),
                0, 0, 0, 0
            );

            return Math.floor((midnightStartAt.getTime() - midnightToday.getTime()) / (1000*60*60*24));

        } else {
            return 0;
        }
    }

    // get userCompany () {
    //     return this._user ? this._user.company : null;
    // }

    // get rooms () {
    //     // return this._roomRefs.map(roomRef => roomRef.id);
    //     return this._rooms;
    // }


    get dateStr () {

        return SHORT_DAYS[(this._startAt.getDay() + 6) % 7] + ' ' +
            this._startAt.getDate() + ' ' +
            SHORT_MONTHS[this._startAt.getMonth()] + ' ' +
            this._startAt.getFullYear();
    }

    get dayStr () {
        const nth = function(d) {
            if (d > 3 && d < 21) return 'th';
            switch (d % 10) {
                case 1:  return "st";
                case 2:  return "nd";
                case 3:  return "rd";
                default: return "th";
            }
        };

        return DAYS[(this._startAt.getDay() + 6) % 7] + ' ' +
            this._startAt.getDate() + nth(this._startAt.getDate());
    }

    get timeStr () {

        return Booking.getTimeStr(this._startAt, this._endAt);
    }

    get dateTimeStr () {
        return `${this.dateStr} at ${this.timeStr}`;
    }

    get dayTimeStr () {
        return `${this.dayStr} at ${this.timeStr}`;
    }

    get bookingStr () {

        if (this._rooms) {
            return `${this._rooms.shortRoomsStr}${!this.isRecurring ? ' on' : ','} ${this.recurringLabel}`;
        } else {
            return this.recurringLabel;
        }
    }

    get nextReoccurrenceBookingStr() {

        if (this._rooms) {
            return `${this._rooms.shortRoomsStr} on ${this.nextReoccurrenceDateStr}`;
        } else {
            return this.nextReoccurrenceDateStr;
        }
    }


    // getNotesSubstring = (length) => {
    //     return this._notes ? (
    //         this._notes.length <= length ? (
    //             this._notes
    //         ) : (
    //             this._notes.substring(0, length) + '...'
    //         )
    //     ) : null;
    // };

    // get notesSummary () {
    //     return this._notes ? (
    //         this._notes.length < 50 ? this._notes : (this._notes.substring(0, 50) + '...')
    //     ) : null;
    // }

    get company () { return this._company; }
    get userRef () { return this._userRef; }
    get user () { return this._user; }

    get startAt () { return this._startAt; }
    get endAt () { return this._endAt; }

    get dtStartAt () {
        return DateTime.fromJSDate(this._startAt);
    }

    get dtEndAt() {
        return DateTime.fromJSDate(this._endAt);
    }

    get notes () { return this._notes; }
    get rooms () { return this._rooms; }
    get needSmartBoard () { return this._needSmartBoard; }
    get recurringFreq (){ return this._recurring.freq; }

    get isRecurring() {
        return !!this._recurring?.active;
    }

    get recurringLabel() {
        return this.isRecurring ? `${bookingFreqLabel(this._recurring.freq, this._startAt)}, ${this.timeStr}` : this.dateTimeStr;
    }

    get nextReoccurrenceIsToday() {

        return this._dtNextReoccurrence.startOf('day').equals(DateTime.now().startOf('day'));
    }

    get nextReoccurrenceDateStr() {

        return this._dtNextReoccurrence.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
    }

    /**
     *
     * @return {DateTime[]}
     * @private
     */
    get dtCancelledReoccurrences() {

        return this._recurring.cancelledReoccurrences ?
            this._recurring.cancelledReoccurrences.map(d => DateTime.fromJSDate(d.toDate())) :
            [];
    }

    /**
     * Get Luxon DateTime object for next reoccurrence of booking, taking into account future cancelled occurrences
     *
     * @return {DateTime}
     * @private
     */
    get _dtNextReoccurrence() {

        let dtStartingFrom;
        if (this.dtCancelledReoccurrences.length) {
            dtStartingFrom = this.dtCancelledReoccurrences[this.dtCancelledReoccurrences.length - 1].plus({
                day: 1
            });
        }

        if (!dtStartingFrom || dtStartingFrom < DateTime.now()) {
            dtStartingFrom = DateTime.now();
        }

        let dtNext;

        if (this.recurringFreq === BookingFreqEnum.WEEKLY) {
            if (dtStartingFrom.weekday < this.dtStartAt.weekday || (
                dtStartingFrom.weekday === this.dtStartAt.weekday &&
                dtSecsSinceDayStart(dtStartingFrom) <= dtSecsSinceDayStart(this.dtEndAt)
            )) {
                dtNext = dtStartingFrom.set({ weekday: this.dtStartAt.weekday });
            } else {
                dtNext = dtStartingFrom.plus({ week: 1 }).set({ weekday: this.dtStartAt.weekday });
            }
        } else {

            // get all occurrences of weekday in current month
            const dtMonthStart = dtStartingFrom.startOf('month');
            let dt = dtMonthStart.set({
                day: 1 + ((7 + this.dtStartAt.weekday) - dtMonthStart.weekday) % 7,
            });
            const month = dt.month;
            const days = [];
            while (dt.month === month) {
                days.push(dt.day);
                dt = dt.plus({ weeks: 1 });
            }

            let dayIndex = this.recurringFreq === BookingFreqEnum.MONTHLY_FIRST ? 0 :
                this.recurringFreq === BookingFreqEnum.MONTHLY_SECOND ? 1 :
                    this.recurringFreq === BookingFreqEnum.MONTHLY_THIRD ? 2 :
                        this.recurringFreq === BookingFreqEnum.MONTHLY_FOURTH ? 3 :
                            this.recurringFreq === BookingFreqEnum.MONTHLY_LAST ? (days.length-1) :
                                null;

            if ((dtStartingFrom.day < days[dayIndex]) || (
                dtStartingFrom.day === days[dayIndex] &&
                dtSecsSinceDayStart(dtStartingFrom) < dtSecsSinceDayStart(this.dtEndAt)
            )) {
                dtNext = dtStartingFrom.set({ day: days[dayIndex] });
            } else {

                const dtNextMonthStart = dtStartingFrom.plus({ month: 1 }).startOf('month');
                let dt = dtNextMonthStart.set({
                    day: 1 + ((7 + this.dtStartAt.weekday) - dtNextMonthStart.weekday) % 7,
                });
                const month = dt.month;
                const nextMonthDays = [];
                while (dt.month === month) {
                    nextMonthDays.push(dt.day);
                    dt = dt.plus({ weeks: 1 });
                }

                if (this.recurringFreq === BookingFreqEnum.MONTHLY_LAST) {
                    dayIndex = nextMonthDays.length - 1;
                }

                dtNext = dtNextMonthStart.set({ day: nextMonthDays[dayIndex] });
            }
        }

        return dtNext.set({
            hour: this.dtStartAt.hour,
            minute: this.dtStartAt.minute,
            second: 0,
            millisecond: 0
        });
    }
}
