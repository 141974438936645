import { useMemo } from 'react';
// firebase
import Bookings from '../firebase/collections/Bookings/Bookings';

/**
 * Re-usable logic that memoizes bookings to be displayed in a bookings list, based on given view mode.
 */
const useListBookings = (bookings: Bookings, viewMode: number): Bookings => {

    return useMemo(() => (
        bookings ? (
            (viewMode === 0) ?
                new Bookings([...bookings.ongoingBookings, ...bookings.upcomingBookings]) :
                (viewMode === 1) ?
                    new Bookings(bookings.recurringBookings) :
                    new Bookings(bookings.pastBookings)
        ) : null
    ), [bookings, viewMode]);
}

export default useListBookings;
