import React from "react";
import { NavigationContainer as RNNavigationContainer } from "@react-navigation/native";
import PropTypes from "prop-types";
// style
import { AppThemes } from "../theme";
// navigation
import { navigationRef } from "./RootNavigation";
import RootStack from "./stacks/root/RootStack.web";
// config
import { linking } from "../config/navigation";

const NavigationContainer = ({ colorScheme }) => (

    <RNNavigationContainer
        ref={navigationRef}
        theme={colorScheme === 'dark' ? AppThemes.Dark : AppThemes.Default}
        linking={linking}
    >

        <RootStack/>
    </RNNavigationContainer>
);

NavigationContainer.propTypes = {
    colorScheme: PropTypes.oneOf(['dark', 'light']).isRequired,
}

export default NavigationContainer;
