import React, { useState, useCallback } from 'react';
import { StackScreenProps } from '@react-navigation/stack';
import { useFocusEffect } from '@react-navigation/native';
// hooks
import { useMountedState } from '../../hooks/react-use';
// session
import { useAuthUser } from '../../Session/context';
// atoms
import LoadingSpinner from '../atoms/LoadingSpinner';
// templates
import SettingsScreenTemplate from '../templates/screens/SettingsScreen/SettingsScreenTemplate';
// navigation
import { MainStackParamList } from '../../navigation/stacks/root/stacks/app/stacks/main/MainStack.web';
// firebase
import { doc, onSnapshot } from 'firebase/firestore';
import { firestore } from '../../firebase/firebase';
import {IntuitConnectionInfo} from '../../types/intuit';
import User from '../../firebase/documents/User/User';

type Props = StackScreenProps<MainStackParamList>;

const INITIAL_STATE = {
    intuitConnectionInfo: null
}

/**
 * Settings Screen
 */
const SettingsScreen = ({ navigation }: Props): JSX.Element => {
    const authUser = useAuthUser();
    const isMounted = useMountedState();

    // null while fetching, false if doc not exists, info if set (info being set does not guarantee a connection, so
    // the `isConnected` attr should be checked.)
    const [intuitConnectionInfo, setIntuitConnectionInfo] = useState<null | false | { info: IntuitConnectionInfo, connectedBy: User }>(
        INITIAL_STATE.intuitConnectionInfo
    );

    // listen for changes to intuit oauth connection info
    useFocusEffect(useCallback(() => {

        if (!authUser || !authUser.isAdmin) {

            if (isMounted()) {
                setIntuitConnectionInfo(INITIAL_STATE.intuitConnectionInfo);
            }
            return;
        }

        const intuitConnectionInfoDocRef = doc(firestore, 'intuit', 'connection');
        return onSnapshot(intuitConnectionInfoDocRef, async (snapshot) => {

            if (snapshot.exists()) {

                const connectionInfo = snapshot.data() as IntuitConnectionInfo;

                // get user that connected QB
                let connectedBy: User;
                try {
                    connectedBy = await User.build(connectionInfo.createdBy.id) as User;
                } catch (err) {
                    setIntuitConnectionInfo(false);
                    return;
                }

                setIntuitConnectionInfo({ info: connectionInfo, connectedBy });
                return;
            }

            setIntuitConnectionInfo(false);
        }, (err) => {

            setIntuitConnectionInfo(false);
        });
    }, [authUser]));

    // render
    return authUser ? (
        <SettingsScreenTemplate user={authUser} intuitConnectionInfo={intuitConnectionInfo}/>
    ) : (
        <LoadingSpinner/>
    );
}

// exports
export default SettingsScreen;
