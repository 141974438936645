import React from 'react';
import { DefaultTheme, useTheme } from 'styled-components';
import { StyleSheet, View } from 'react-native';
// atoms
import PText from '../atoms/text/PText';
import { IconSizes } from '../../theme/theme';

interface Props {
    icon: React.ComponentClass<{ fill: string; size: keyof IconSizes; }>;
    value: string;
}

/**
 * ExpandedBooking
 */
const ExpandedBookingInfoItem = ({ icon: Icon, value }: Props): JSX.Element => {
    const theme = useTheme();
    const styles = stylesheet(theme);

    // render
    return (
        <View style={styles.infoItem}>
            <Icon
                fill={theme.colors.light}
                size='small'
            />

            <PText style={styles.infoValue}>
                { value }
            </PText>
        </View>
    );
};

const stylesheet = ({ margin, colors, padding }: DefaultTheme) => StyleSheet.create({

    infoItem: {

        flexDirection: 'row',
        marginBottom: margin.small
    },

    infoValue: {

        color: colors.light,
        paddingLeft: padding.small
    }
});

export default ExpandedBookingInfoItem;
