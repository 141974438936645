import React, { useCallback, useState, useRef } from 'react';
import { useFocusEffect } from '@react-navigation/native';
// firebase
import AuthUser from '../../../../../firebase/documents/User/AuthUser';
// atoms
import AuthTextInput from '../../../../atoms/AuthTextInput';
// templates
import AuthScreenTemplate from '../AuthScreenTemplate';
// hooks
import { useMountedState } from "../../../../../hooks/react-use";


const INITIAL_STATE = {
    // form field
    activationCode: '',
    password: '',
    password2: '',
    // form state
    isSubmitting: false,
    error: null
};

/**
 * Activation Screen Template
 *
 * @returns {JSX.Element}
 */
const ActivationScreenTemplate = ({ code }) => {
    const isMounted = useMountedState();

    // form fields state
    const [ activationCode, setActivationCode ] = useState(code || INITIAL_STATE.activationCode);
    const [ password, setPassword ] = useState(INITIAL_STATE.password);
    const [ password2, setPassword2 ] = useState(INITIAL_STATE.password2);
    // form state
    const [ isSubmitting, setIsSubmitting ] = useState(INITIAL_STATE.isSubmitting);
    const [ error, setError ] = useState(INITIAL_STATE.error);

    // form input refs
    const passwordInputRef = useRef();
    const password2InputRef = useRef();

    // reset state on screen blur
    useFocusEffect(useCallback(() => {

        return () => {

            setActivationCode(INITIAL_STATE.activationCode);
            setPassword(INITIAL_STATE.password);
            setPassword2(INITIAL_STATE.password2);
            setError(INITIAL_STATE.error);
        }
    }, []));

    // on submit
    const handleSubmit = async () => {
        setError(INITIAL_STATE.error);
        setIsSubmitting(true);

        // activate account
        let responseData;
        try {
            let response = await AuthUser.activate(activationCode, password, password2);
            responseData = response.data;

        } catch (err) {

            if (isMounted()) {
                setError(err);
                setIsSubmitting(false);
            }
            return;
        }

        // account activated, sign in user (automatically redirected on sign in)
        try {
            await AuthUser.signInWithEmailAndPassword(responseData.user.email, password);
        } catch (err) {

            if (isMounted()) {
                setError(err);
            }
        }

        if (isMounted()) {
            setIsSubmitting(false);
        }
    };

    // render
    return (
        <AuthScreenTemplate
            mode='activation'
            handleSubmit={handleSubmit}
            error={error}
            isSubmitting={isSubmitting}
        >
            <AuthTextInput
                value={activationCode}
                onChangeText={setActivationCode}
                onSubmitEditing={() => passwordInputRef.current.focus()}
                blurOnSubmit={false}
                returnKeyType='next'
                placeholder='Enter Activation Code Here'
            />

            {activationCode ? (
                <>
                    <AuthTextInput
                        ref={passwordInputRef}
                        value={password}
                        onChangeText={setPassword}
                        onSubmitEditing={() => password2InputRef.current.focus()}
                        // blurOnSubmit={false}
                        returnKeyType='next'
                        placeholder='Enter new password'
                        autoCompleteType='password'
                        secureTextEntry={true}
                        textContentType='password'
                    />

                    <AuthTextInput
                        ref={password2InputRef}
                        value={password2}
                        onChangeText={setPassword2}
                        onSubmitEditing={() => handleSubmit()}
                        returnKeyType='go'
                        placeholder='Repeat new password'
                        autoCompleteType='password'
                        secureTextEntry={true}
                        textContentType='password'
                    />
                </>
            ) : null}
        </AuthScreenTemplate>
    )
}

export default ActivationScreenTemplate;
