import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useTheme } from 'styled-components';
import { transparentize } from 'polished';
// molecules
import ViewSwitchBtn from './ViewSwitchBtn';

interface Props {
    viewMode: number;
    handler: (viewMode: number) => void;
    titles: string[];
}

/**
 * View Switcher component
 */
const ViewSwitcher = ({ viewMode, handler, titles }: Props): JSX.Element => {
    const styles = useStyles();

    return (
        <View style={styles.viewSwitcher}>
            {titles.map((title, index) => (
                <ViewSwitchBtn key={`viewSwitch-${index}`}
                               activeViewMode={viewMode}
                               viewMode={index}
                               handler={handler}
                               title={title} />
            ))}
        </View>
    )
};

const useStyles = () => {
    const { spacing, colors, borderRadius } = useTheme();

    return StyleSheet.create({

        viewSwitcher: {

            flexDirection: 'row',

            margin: spacing.medium,
            marginTop: 0,

            backgroundColor: transparentize(0.9, colors.secondary),
            borderRadius
        }
    })
}

export default ViewSwitcher;
