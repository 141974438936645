import { signInWithEmailAndPassword, sendPasswordResetEmail, signOut } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
// firebase
import { auth } from '../../firebase';
import callableFunctions from "../../functions/callable-functions";
// firebase doc classes
import User from './User';

/**
 * AuthUser
 */
class AuthUser extends User {

    // noinspection JSValidateTypes
    /**
     * Firestore data converter
     * @type {firebase.firestore.FirestoreDataConverter}
     */
    static firestoreConverter = {
        fromFirestore: (snapshot, options) => {
            const { createdAt, updatedAt, ...data } = snapshot.data(options);

            return new AuthUser(
                snapshot.ref,
                snapshot.id,

                createdAt,
                updatedAt,

                data
            )
        }
    };


    static activate = async (activationCode, password, password2) => {
        // input validation
        if (!activationCode)
            throw new Error('Please enter your activation code');

        if (!password)
            throw new Error('Please enter a password');

        if (!password2)
            throw new Error('Please repeat your password');

        if (password !== password2)
            throw new Error("Passwords don't match!");

        // call firebase function
        return await callableFunctions.user.activateUser({
            activationCode, password
        });
    }


    static signInWithEmailAndPassword = async (email, password) => {
        // validation
        if (!email)
            throw new Error('Email is required');

        if (!password)
            throw new Error('Password is required');

        // sign in with email and password
        try {
            await signInWithEmailAndPassword(auth, email, password);

        } catch (err) {
            let errMsg = "Failed to sign in, please try again";

            if (err instanceof Error) {

                switch (err.code) {
                    case 'auth/wrong-password':
                        errMsg = "Incorrect password.";
                        break
                    case 'auth/user-not-found':
                        errMsg = "Account not found.";
                        break;
                    default:
                        errMsg = err.code;
                }
            }

            throw new Error(errMsg);
        }
    };


    static sendPasswordResetEmail = async (email) => {
        // validation
        if (!email)
            throw new Error('Please enter your email');


        // send password reset email
        return await sendPasswordResetEmail(auth, email);
    }



    constructor(ref, id, createdAt, updatedAt, userData) {
        super(ref, id, createdAt, updatedAt, userData);

        this._authUser = null;

        // this._isInitialized = false;

        // (async () => await auth.signOut())();
    }


    // _registerForPushNotifications = async () => {
    //     if (Constants.isDevice) {
    //         // console.log('isDevice');
    //
    //         const { status: existingStatus } =
    //             await Permissions.getAsync(Permissions.NOTIFICATIONS);
    //
    //         // console.log({ existingStatus });
    //
    //         let finalStatus = existingStatus;
    //         if (existingStatus !== 'granted') {
    //             const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
    //             finalStatus = status;
    //         }
    //
    //         // console.log({ finalStatus });
    //
    //         if (finalStatus !== 'granted') {
    //             alert('Failed to get push token for push notifications!');
    //             return;
    //         }
    //
    //         const token = await Notifications.getExpoPushTokenAsync();
    //         console.log(token);
    //     }
    //
    //
    //     // if (Platform.OS === 'android') {
    //     //     Notifications.createChannelAndroidAsync('default', {
    //     //         name: 'default',
    //     //
    //     //     })
    //     // }
    // };


    signOut = async () => {
        try {
            await signOut(auth);
        } catch (err) {
            console.log('failed to sign out: ', err);

            throw new Error(err.message);
        }
    };


    /* mutator methods */
    set authUser (authUser) {
        this._authUser = authUser;
    }

}


// exports
export default AuthUser;
