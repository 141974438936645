import React, { Component } from 'react';
import { Keyboard } from 'react-native';
import PropTypes from 'prop-types';
// firebase
import AuthUser from '../../firebase/documents/User/AuthUser';
// templates
import SignInScreenTemplate from '../templates/screens/auth/SignInScreen/SignInScreenTemplate';

/**
 * Sign in Screen
 */
class SignInScreen extends Component {
    static propTypes = {
        authUser: PropTypes.instanceOf(AuthUser)
    };

    constructor(props) {
        super(props);

        this.state = {
            formData: {
                email: '',
                password: ''
            },

            isSubmitting: false,
            error: null
        }
    }


    handleChangeTextInput = (field, value) =>
        this.setState(({ formData }) => ({
            formData: {
                ...formData,
                [field]: value
            }
        }));


    handleSubmit = async () => {
        Keyboard.dismiss();
        this.setState({ isSubmitting: true });

        const { formData: { email, password } } = this.state;

        try {
            await AuthUser.signInWithEmailAndPassword(email, password);
        } catch (error) {
            this.setState({ error });
        }

        this.setState({ isSubmitting: false });
    };


    render() {
        const { formData, isSubmitting, error } = this.state;

        return (
            <SignInScreenTemplate
                // form
                formData={formData}
                error={error}
                isSubmitting={isSubmitting}

                // handlers
                handleChangeTextInput={this.handleChangeTextInput}
                handleSubmit={this.handleSubmit}
            />
        );
    }
}


// exports
export default SignInScreen;
