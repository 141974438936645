/**
 * This context was originally setup to disable scrolling while modal is open, however that was no longer necessary
 * since we now use the React native 'Modal' component.  Haven't removed it though as this context could still be useful
 * in the future.
 */

import React, { createContext, useContext, useState, PropsWithChildren } from 'react';

interface MasterTemplateCtx {
    isScrollable: boolean,
    setIsScrollable: (scrollable: boolean) => void;
}

export const masterTemplateCtx = createContext<MasterTemplateCtx | null>(null);

const INITIAL_STATE = {
    isScrollable: true,
}

/**
 * Master Template context provider, to be used in the platform common index file for the MasterTemplate component.
 */
export const MasterTemplateCtxProvider = ({ children }: PropsWithChildren<{}>): JSX.Element => {

    const [isScrollable, setIsScrollable] = useState<boolean>(INITIAL_STATE.isScrollable);

    // render
    return (
        <masterTemplateCtx.Provider value={{ isScrollable, setIsScrollable }}>
            {children}
        </masterTemplateCtx.Provider>
    );
}

/**
 * Hook to consume master template context.  Platform specific implementations of MasterTemplate should consume this,
 * however it is their individual choice whether to respect the values (e.g. isScrollable is currently ignored on
 * native).
 */
export const useMasterTemplateCtx = () => {
    const value = useContext(masterTemplateCtx);

    if (value === null) {
        throw new Error('Master Template context must be consumed within MasterTemplate.');
    }

    return value;
}
