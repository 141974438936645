import React from 'react';
import Svg, { Path } from "react-native-svg";
import { DefaultTheme, useTheme } from 'styled-components';

interface Props {
    size?: keyof DefaultTheme['iconSizes'];
    width?: number;
    height?: number;
    fill?: string;
}

/**
 * Calendar Edit icon
 */
const CalendarEditIcon = ({ width, height, size = 'medium', ...props }: Props): JSX.Element => {
    const { iconSizes } = useTheme();

    // render
    return (
        <Svg
            viewBox="0 0 448 512"
            width={width || iconSizes[size]}
            height={height || iconSizes[size]}

            {...props}
        >
            <Path d="M436 160H12c-6.6 0-12-5.4-12-12v-36c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48v36c0 6.6-5.4 12-12 12zM12 192h424c6.6 0 12 5.4 12 12v260c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V204c0-6.6 5.4-12 12-12zm208.4 109.6l-95 95-5.4 48.2c-.7 6.4 4.7 11.9 11.2 11.2l48.2-5.4 95-95c2-2 2-5.2 0-7.2l-46.8-46.8c-2-2-5.2-2-7.2 0zm109.7-30.3l-25.4-25.4c-7.9-7.9-20.7-7.9-28.6 0l-26 26c-2 2-2 5.2 0 7.2l46.8 46.8c2 2 5.2 2 7.2 0l26-26c7.9-7.9 7.9-20.7 0-28.6z" />
        </Svg>
    );
}


// exports
export default CalendarEditIcon;
