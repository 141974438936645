import React, { Component } from 'react';
import { Dimensions, Platform, Appearance } from "react-native";
import { compose } from 'recompose';
import { ThemeProvider } from 'styled-components';
import AppLoading from 'expo-app-loading/build/AppLoadingNativeWrapper';
// session
import { withAuthUser } from './Session/context';
import withAuthentication from './Session/withAuthentication';
// style
import theme from './theme';
// navigation - platform specific extensions mean the .web/.native version will be imported automatically
// https://reactnative.dev/docs/platform-specific-code#platform-specific-extensions
import NavigationContainer from "./navigation/NavigationContainer";

// import { StatusBar } from 'expo-status-bar';
// import { AppLoading } from 'expo';
// import * as Font from 'expo-font';
// import * as Analytics from 'expo-firebase-analytics';
// import Constants from 'expo-constants';
// import * as Notifications from 'expo-notifications';
// import * as Permissions from 'expo-permissions';
//
// Notifications.setNotificationHandler({
//     handleNotification: async () => ({
//         shouldShowAlert: true,
//         shouldPlaySound: true,
//         shouldSetBadge: true
//     })
// });
// // ignore timer warnings on Android Emulator (to improve dev experience, doesn't affect prod)
// YellowBox.ignoreWarnings(['Setting a timer']);
// const customFonts = {
//     'SFProDisplay-Medium': require('./assets/fonts/SFProDisplay-Medium.otf'),
//     'SFProDisplay-Bold': require('./assets/fonts/SFProDisplay-Bold.otf'),
//
//     // 'SFCompactDisplay-Regular': require('./assets/fonts/SFCompactDisplay-Regular.otf'),
//     'SFCompactDisplay-Medium': require('./assets/fonts/SFCompactDisplay-Medium.otf')
// };
// async function registerForPushNotificationsAsync() {
//     let token;
//     if (Constants.isDevice) {
//         const { status: existingStatus } = await Permissions.getAsync(Permissions.NOTIFICATIONS);
//         let finalStatus = existingStatus;
//         if (existingStatus !== 'granted') {
//             const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
//             finalStatus = status;
//         }
//
//         if (finalStatus !== 'granted') {
//             alert('Failed to get push token for push notifications!');
//             return;
//         }
//         token = (await Notifications.getExpoPushTokenAsync()).data;
//         console.log({ token });
//     }
//
//     if (Platform.OS === 'android') {
//         await Notifications.setNotificationChannelAsync('default', {
//             name: 'default',
//             importance: Notifications.AndroidImportance.MAX,
//             vibrationPattern: [0, 250, 250, 250],
//             lightColor: '#FF231F7C'
//         });
//     }
//
//     return token;
// }

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // isReady
            authStateEstablished: false,
            fontsLoaded: false,

            colorScheme: Platform.OS === 'web' ? 'light' : Appearance.getColorScheme(),

            expoPushToken: '',
            notification: false
        };

        this._appearanceChangeListener = null;

        this._notificationListener = React.createRef();
        this._responseListener = React.createRef();
    }


    componentDidMount() {
        // listen for change between dark/light mode
        this._appearanceChangeListener =
            // TODO can probably replace this logic with useColorScheme react-native hook??
            Appearance.addChangeListener(({ colorScheme }) =>
                this.setState({
                    colorScheme: (colorScheme === 'dark' && Platform.OS !== 'web') ? 'dark' : 'light'
                })
            );

        // load custom fonts
        // noinspection JSIgnoredPromiseFromCall
        // this._loadFontsAsync();


        // registerForPushNotificationsAsync()
        //     .then(token => this.setState({ expoPushToken: token }));
        //
        // // This listener is fired whenever a notification is received while the app is foregrounded
        // this._notificationListener.current =
        //     Notifications.addNotificationReceivedListener(notification => {
        //         this.setState({ notification });
        //     });
        //
        // // This listener is fired whenever a user taps on or interacts with a notification
        // // (works when app is foregrounded, backgrounded, or killed)
        // this._responseListener.current =
        //     Notifications.addNotificationResponseReceivedListener(response => {
        //         console.log({ response });
        //     });
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        // even if not authed, authUser will change from 'undefined' to 'null'
        if (prevProps.authUser === undefined && this.props.authUser !== undefined)
            this.setState({ authStateEstablished: true });
    }


    componentWillUnmount() {
        // remove appearance change listener
        this._appearanceChangeListener?.remove();


        // Notifications.removeNotificationSubscription(this._notificationListener);
        // Notifications.removeNotificationSubscription(this._responseListener);
    }


    // _loadFontsAsync = async () => {
    //     await Font.loadAsync(customFonts);
    //     this.setState({ fontsLoaded: true });
    // };


    render() {
        const { authStateEstablished, colorScheme } = this.state;

        // const isReady = (authStateEstablished && fontsLoaded);
        const isReady = (authStateEstablished);

        const themeCtxValue = colorScheme === 'dark' ? theme.dark : theme.default;

        return (
            !isReady ? (
                <AppLoading />
            ) : (
                <ThemeProvider theme={themeCtxValue}>

                    <NavigationContainer colorScheme={colorScheme}/>
                </ThemeProvider>
            )
        );
    }

}


// exports
export default compose(
    withAuthentication,
    withAuthUser
)(App);
