import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { useTheme } from "styled-components";
import { StyleSheet } from 'react-native';
// constants
import * as ROUTES from "../../../../../../../../../constants/routes";
// screens
import SettingsScreen from "../../../../../../../../../components/screens/SettingsScreen";
import EditProfileScreen from '../../../../../../../../../components/screens/EditProfileScreen';
// utils
import getWebTabTitle from '../../../../../../../../../utilities/get-web-tab-title';

export type SettingsStackParamList = {
    [ROUTES.SETTINGS.HOME]: undefined;
    [ROUTES.SETTINGS.EDIT_PROFILE]; undefined;
}

/**
 * Child of MainStack, contains settings screens
 */
const Stack = createStackNavigator<SettingsStackParamList>();

const SettingsStack = (): JSX.Element => {
    const styles = useStyles();

    // render
    return (
        <Stack.Navigator screenOptions={{
            headerShown: false,
            cardStyle: styles.cardStyle,
            title: getWebTabTitle('Settings')
        }}>
            <Stack.Screen name={ROUTES.SETTINGS.HOME} component={SettingsScreen} />
            <Stack.Screen name={ROUTES.SETTINGS.EDIT_PROFILE} component={EditProfileScreen}/>
        </Stack.Navigator>
    );
}

const useStyles = () => {
    const { colors } = useTheme();

    return StyleSheet.create({

        cardStyle: {

            backgroundColor: colors.background,
        }
    });
}

export default SettingsStack;
