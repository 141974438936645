import React from 'react';
import { SafeAreaView, View, Text } from 'react-native';
import { useTheme } from 'styled-components';
import PropTypes from 'prop-types';
// firestore collections
import Bookings from '../../../../firebase/collections/Bookings/Bookings';
// styles
import stylesheet from './stylesheet';
// atoms
import Card from '../../../atoms/Card';
import H2Text from '../../../atoms/text/H2Text';
import LoadingSpinner from '../../../atoms/LoadingSpinner';
import CalendarDayIcon from '../../../atoms/icons/CalendarDayIcon';
import PText from '../../../atoms/text/PText';
// molecules
import ScreenHeader from '../../../molecules/ScreenHeader';
// organisms
import BookingsList from '../../../organisms/BookingsList';
// templates
import MasterTemplate from "../../MasterTemplate";

// components
const DayStat = ({ icon: Icon, value }) => {
    const theme = useTheme();

    // render
    return (
        <View style={{ flexDirection: 'row' }}>
            <Icon fill={theme.colors.light} size='small' />
            <PText style={{ marginLeft: theme.margin.small, color: theme.colors.light }}>{ value }</PText>
        </View>
    );
}


const HomeScreenTemplate = ({ bookingsToday, quote }) => {
    const theme = useTheme();
    const styles = stylesheet(theme);

    // render
    return (
        <MasterTemplate>
            <View style={{ flex: 1 }}>
                {
                    bookingsToday ? (
                        <View style={{ flex: 1 }}>
                            <SafeAreaView>
                                <ScreenHeader title='Dashboard' subtitle='Today Overview' />
                            </SafeAreaView>

                            <View style={styles.overviewCardWrapper}>
                                <Card style={{ backgroundColor: theme.colors.primary }}>
                                    <Text style={styles.quote}>
                                        "{ quote.quoteText }" { quote.quoteAuthor && `- ${quote.quoteAuthor}` }
                                    </Text>

                                    <DayStat
                                        icon={CalendarDayIcon}
                                        value={`${bookingsToday.bookings.length} Bookings today`}
                                    />

                                    {/*<DayStat*/}
                                    {/*    icon={CalendarWeekIcon}*/}
                                    {/*    value={}*/}
                                    {/*/>*/}
                                </Card>
                            </View>

                            <BookingsList
                                style={{ paddingTop: theme.padding.medium }}
                                bookings={bookingsToday}
                                showCompany={true}
                                expandedBookingProps={{
                                    showCompany: true,
                                    showUser: true
                                }}
                                overrideSectionsTitle={'Bookings Today'}
                            />
                        </View>
                    ) : (
                        <LoadingSpinner/>
                    )
                }

            </View>
        </MasterTemplate>
    );
};

HomeScreenTemplate.propTypes = {
    bookingsToday: PropTypes.instanceOf(Bookings),
    quote: PropTypes.object
};

export default HomeScreenTemplate;
