import React, { useState, useCallback } from 'react';
import {useFocusEffect, useNavigation} from "@react-navigation/native";
import { useTheme } from 'styled-components';
// firebase
import AuthUser from '../../../../../firebase/documents/User/AuthUser';
// atoms
import AuthTextInput from '../../../../atoms/AuthTextInput';
import H3Text from '../../../../atoms/text/H3Text';
import AuthSubmitBtn from '../../../../atoms/AuthSubmitBtn';
// templates
import AuthScreenTemplate from '../AuthScreenTemplate';
// constants
import * as ROUTES from '../../../../../constants/routes';
// hooks
import { useMountedState } from "../../../../../hooks/react-use";

const INITIAL_STATE = {
    // form field values
    email: '',
    // form state
    isSubmitting: false,
    error: null,
    emailSent: false
}

/**
 * ForgotPasswordScreenTemplate
 *
 * @returns {JSX.Element}
 */
const ForgotPasswordScreenTemplate = () => {
    const theme = useTheme();
    const navigation = useNavigation();
    const isMounted = useMountedState();

    // form field values state
    const [ email, setEmail ] = useState(INITIAL_STATE.email);
    // form state
    const [ isSubmitting, setIsSubmitting ] = useState(INITIAL_STATE.isSubmitting);
    const [ error, setError ] = useState(INITIAL_STATE.error);
    const [ emailSent, setEmailSent ] = useState(INITIAL_STATE.emailSent);

    // reset form on blur (move to different auth screen)
    useFocusEffect(useCallback(() => {

        return () => {

            // reset form field values
            setEmail(INITIAL_STATE.email);
            // reset form state
            setError(INITIAL_STATE.error);
            setEmailSent(INITIAL_STATE.emailSent);
        }
    }, []));

    // on submit
    const handleSubmit = async () => {
        setError(INITIAL_STATE.error);
        setIsSubmitting(true);

        // send password reset email
        try {
            await AuthUser.sendPasswordResetEmail(email);

            if (isMounted()) {
                setEmailSent(true);
                setError(INITIAL_STATE.error);
            }
        } catch (err) {

            if (isMounted()) {
                setError(err);
                setEmailSent(false);
            }
        }

        if (isMounted()) {
            setIsSubmitting(false);
        }
    }

    // render
    return (
        <AuthScreenTemplate
            mode='forgot_password'
            handleSubmit={handleSubmit}
            error={error}
            showButtons={!emailSent}
            isSubmitting={isSubmitting}
        >
            {emailSent ? (
                <>
                    <H3Text style={{ color: theme.colors.light, textAlign: 'center' }}>
                        An email has been sent to you at '{ email }' containing a link to
                        reset the password for your CREATE Bookings account.
                    </H3Text>

                    <AuthSubmitBtn
                        onPress={() => navigation.navigate(ROUTES.SIGN_IN)}
                        title='Sign In'
                        style={{ marginTop: theme.margin.large }}
                    />
                </>
            ) : (
                <AuthTextInput
                    value={email}
                    onChangeText={setEmail}
                    onSubmitEditing={() => handleSubmit()}
                    returnKeyType='go'
                    textContentType='emailAddress'
                    placeholder='Email Address'
                    autoCompleteType='email'
                    keyboardType='email-address'
                />
            )}
        </AuthScreenTemplate>
    );
}

export default ForgotPasswordScreenTemplate;
