import React from 'react';
// templates
import AdminNewCompanyScreenTemplate from '../../templates/screens/admin/AdminNewCompanyScreen/AdminNewCompanyScreenTemplate';

/**
 * Admin new company screen, for creating new companies.  The actual submission and creation of a new company is
 * handled by an HTTPS callable cloud function.
 *
 * @return {JSX.Element}
 * @constructor
 */
const AdminNewCompanyScreen = () => {


    /* render */
    return (
        <AdminNewCompanyScreenTemplate/>
    );
};

export default AdminNewCompanyScreen;
